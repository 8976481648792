/* eslint-disable */
/**
 * DEPRECATED: Please consult with the FE Performance team before using this file.
 */

import moment from 'moment';
import Cookies from 'js-cookie';
import { MomentTimezone, MomentZone } from 'moment-timezone';
import { isNumber } from 'lodash-es';

const host = process.env.REMOTE_HOST; //window.location.host;
// const parts = host.split('.');
const helperMomentZone = MomentZone;
const helperMomentTimezone = MomentTimezone;

const Helpers = {
  HOST: host,
  // CUSTOMER: parts[0],
  API_URL: process.env.REACT_APP_REMOTE_HOST, //window.location.protocol + '//' + host,
  REDIRECT_URL: process.env.REACT_APP_REDIRECT_URL,
  GOOGLE_ICON_BASE: 'https://maps.google.com/mapfiles/ms/icons/',
  GOOGLE_DEFAULT_ZOOM: 10,
  GOOGLE_MAP_CENTER: { lat: 37.563, lng: -122.3255 },
  DEFAULT_DATE_FORMAT: 'YYYY-MM-DD h:mm:ss A',
  DEFAULT_TIME_ZONE: Cookies.get('tenant-timezone'),
  DEFAULT_DRIVER_FILTER_STAT: 2, // 1: all drivers, 2: active drivers
  DEFAULT_CUSTOMER_FILTER_STAT: 2, // 1: all customers, 2: today customer
  DISPATCH_NUMBER: window.dispatchNumber,
  CURRENT_URL: window.location.href,
  CURRENT_USER_PHONE: Cookies.get('phone'),
  CURRENT_TENANT: Cookies.get('tenant_slug'),
  CURRENT_USER_ID: Cookies.get('current_user_id'),
  CURRENT_CUSTOMER_ID: Cookies.get('current_customer_id') || Cookies.get('current_user_id'),
  ACCESS_TOKEN: 'Bearer ' + Cookies.get('fp_access_token'),
  API_TIMEOUT: 150000,
  MODAL_CLASS: {
    overlay: 'modal-overlay',
    modal: 'note-modal',
    closeButton: 'close-button',
  },

  numberFormat: (value) => {
    if (isNumber(value)) {
      return Number(value).toLocaleString();
    } else {
      return value;
    }
  },

  ACTIVITY_PAGES: {
    list: 'list',
    create: 'create',
    defaultLimit: 10,
    defaultOffset: 0,
  },

  QR_CODE_PAGES: {
    defaultLimit: 10,
    defaultOffset: 0,
    defaultType: 'all',
    types: { assigned: 'assigned', unassigned: 'unassigned', all: 'all' },
  },

  GOOGLE_MAP_STYLES: {
    nightLook: [
      { elementType: 'geometry', stylers: [{ color: '#242f3e' }] },
      { elementType: 'labels.text.stroke', stylers: [{ color: '#000000' }] },
      { elementType: 'labels.text.fill', stylers: [{ color: '#cccccc' }] },
      {
        featureType: 'administrative.locality',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#aaaaaa' }],
      },
      {
        featureType: 'poi',
        stylers: [{ visibility: 'off' }],
      },
      {
        featureType: 'road',
        elementType: 'geometry',
        stylers: [{ color: '#38414e' }],
      },
      {
        featureType: 'road',
        elementType: 'geometry.stroke',
        stylers: [{ color: '#212a37' }],
      },
      {
        featureType: 'road',
        elementType: 'labels',
        stylers: [{ visibility: 'off' }],
      },
      {
        featureType: 'road',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#9ca5b3' }],
      },
      {
        featureType: 'road.highway',
        elementType: 'geometry',
        stylers: [{ color: '#746855' }],
      },
      {
        featureType: 'road.highway',
        elementType: 'geometry.stroke',
        stylers: [{ color: '#1f2835' }],
      },
      {
        featureType: 'road.highway',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#f3d19c' }],
      },
      {
        featureType: 'transit',
        elementType: 'geometry',
        stylers: [{ color: '#2f3948' }],
      },
      {
        featureType: 'transit.station',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#d59563' }],
      },
      {
        featureType: 'water',
        elementType: 'geometry',
        stylers: [{ color: '#000000' }],
      },
      {
        featureType: 'water',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#555555' }],
      },
      {
        featureType: 'water',
        elementType: 'labels.text.stroke',
        stylers: [{ color: '#212121' }],
      },
    ],
  },

  humanize: function (str, capitalizeInitials = false) {
    if (str && typeof str === 'string') {
      if (capitalizeInitials) {
        const frags = str.split('_');
        for (let i = 0; i < frags.length; i++) {
          frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
        }
        return frags.join(' ');
      }
      return str
        .replace(/^[\s_]+|[\s_]+$/g, '')
        .replace(/[_\s]+/g, ' ')
        .replace(/^[a-z]/, function (m) {
          return m.toUpperCase();
        });
    }
    return str;
  },

  today: function (format = null, timeZone = this.DEFAULT_TIME_ZONE) {
    if (timeZone) {
      if (format) {
        return moment().tz(timeZone).format(format);
      } else {
        return moment().tz(timeZone);
      }
    } else {
      if (format) {
        return moment().format(format);
      } else {
        return moment();
      }
    }
  },

  momentTz: function (timeZone = this.DEFAULT_TIME_ZONE) {
    if (timeZone) {
      return moment().tz(timeZone);
    } else {
      return moment();
    }
  },

  getStartEndofGivenDate: function (date, startEnd = 'start', timeZone = this.DEFAULT_TIME_ZONE) {
    if (!date) {
      return '';
    }

    let time = moment().startOf('day');
    if (startEnd === 'end') {
      time = moment().endOf('day');
    }

    return this.concatDateAndTime(
      date,
      time.format('HH:mm:ss'),
      this.DEFAULT_DATE_FORMAT,
      timeZone
    );
  },

  additionalDate: function (date = null, additionalDate = null, format = 'YYYY-MM-DD') {
    const newDate = moment(date, format).add(additionalDate, 'days');
    return moment(newDate).format(format);
  },

  addDateTime: function (value, key, date = null, format = 'YYYY-MM-DD') {
    return moment(date, 'YYYY-MM-DD HH:mm:ss').add(value, key).format(format);
  },

  formatDate: function (dateTime, format, timeZone = this.DEFAULT_TIME_ZONE) {
    if (!dateTime) {
      return '';
    }
    if (timeZone) {
      return moment(dateTime).tz(timeZone).format(format);
    } else {
      return moment(dateTime).format(format);
    }
  },

  formatTimeStamp: function (timeStamp, format = null, timeZone = this.DEFAULT_TIME_ZONE) {
    if (!timeStamp) {
      return '';
    }
    if (timeZone) {
      return moment.unix(timeStamp).tz(timeZone).format(format);
    } else {
      return moment.unix(timeStamp).format(format);
    }
  },

  datetimeToUnix: function (datetime, timeZone = this.DEFAULT_TIME_ZONE) {
    return moment.tz(datetime, timeZone).unix();
  },

  formatDateJs: function (date) {
    const d = new Date(date);
    // YYYY-MM-DD HH:mm
    return `${d.getFullYear()}-${d.getMonth()}-${d.getDate()} ${d.getHours()}:${d.getMinutes()}`;
  },

  concatDateAndTime: function (date, time, format = null, timeZone = this.DEFAULT_TIME_ZONE) {
    if (!date || !time) {
      return '';
    }

    const dateTime = moment(date + ' ' + time);
    if (timeZone) {
      return moment(dateTime).tz(timeZone).format(format);
    } else {
      return moment(dateTime).format(format);
    }
  },

  getDifferenceBetweenDates: function (sDate, eDate, diffIn) {
    const startDate = moment(sDate);
    const endDate = moment(eDate);
    return endDate.diff(startDate, diffIn, true);
  },

  getTimestampsDiff: function (sTimestamp = 0, eTimestamp = 0) {
    const startTime = moment.unix(sTimestamp);
    const endTime = moment.unix(eTimestamp);
    const diffInMinutes = endTime.minutes() - startTime.minutes();
    const duration = moment.duration(endTime.diff(startTime));
    const hours = parseInt(duration.asHours());
    const minutes = parseInt(duration.asMinutes()) % 60;

    if (hours > 0) {
      const hourOnly = `${hours} h`;
      return minutes == 0 ? hourOnly : `${hourOnly} ${minutes} m`;
    } else {
      return minutes == 0 ? `${diffInMinutes} min` : `${minutes} min`;
    }
  },

  getCurrentWeekDays: function (format) {
    const startOfWeek = moment().startOf('isoWeek');
    const endOfWeek = moment().endOf('isoWeek');
    const days = [];
    let day = startOfWeek;

    while (day <= endOfWeek) {
      days.push(this.formatDate(day.toDate(), format));
      day = startOfWeek.add(1, 'd');
    }
    return days;
  },

  compareDateStrings: function (format = 'MMM DD, YYYY') {
    return function (a, b) {
      if (a === 'N/A') {
        a = '';
      }

      if (b === 'N/A') {
        b = '';
      }

      return (a ? moment(a, format).unix() : 0) - (b ? moment(b, format).unix() : 0);
    };
  },

  compareNumericStrings: function (a, b) {
    return `${a}`.localeCompare(`${b}`, undefined, { numeric: true, sensitivity: 'base' });
  },

  enumerateDaysBetweenDates: function (sDate, eDate, format) {
    const days = [];
    const startDate = moment(sDate);
    const endDate = moment(eDate);

    while (startDate <= endDate) {
      days.push(startDate.format(format));
      startDate.add(1, 'd');
    }

    return days;
  },

  axiosApiRequestData: function (url, method = 'GET', body = null, timeout = this.API_TIMEOUT) {
    return {
      method,
      url,
      timeout,
      data: body,
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': window.csrfToken,
        'X-Request-From': 'Browser',
        'X-Timestamp': moment().unix(),
        Source: 'legacyapp',
      },
    };
  },

  getApiRequestData: function (url, method = 'GET', body = null, headers = {}) {
    const token = Cookies.get('fp_access_token');
    return new Request(url, {
      method,
      body,
      headers: new Headers({
        'Content-Type': 'application/json',
        'X-CSRF-Token': token,
        'X-Request-From': 'Browser',
        'X-Timestamp': moment().unix(),
        'X-Requested-With': 'XMLHttpRequest',
        Authorization: `Bearer ${token}`,
        Source: 'legacyapp',
        ...headers,
      }),
    });
  },

  // date 1 is initial date
  // date 2 is final date
  getHumanizedTimeDifference: function (date1, date2) {
    const years = date1.diff(date2, 'year');
    date2.add(years, 'years');

    const months = date1.diff(date2, 'months');
    date2.add(months, 'months');

    const days = date1.diff(date2, 'days');
    date2.add(days, 'days');

    const hours = date1.diff(date2, 'hours');
    date2.add(hours, 'hours');

    const minutes = date1.diff(date2, 'minutes');
    date2.add(minutes, 'minutes');

    const seconds = date1.diff(date2, 'seconds');

    let finalString = '';
    if (years > 0) finalString += `${years} years`;
    if (months > 0) finalString += ` ${months} months`;
    if (days > 0) finalString += ` ${days} days`;
    if (hours > 0) finalString += ` ${hours} hours`;
    if (minutes > 0) finalString += ` ${minutes} minutes`;

    return finalString;
  },

  getCurrentTimeStamp: function (timeZone = this.DEFAULT_TIME_ZONE) {
    return moment().unix(timeZone);
  },

  getBeginningOfDayTimestamp: function (datetime = new Date(), timeZone = this.DEFAULT_TIME_ZONE) {
    return moment.tz(datetime, timeZone).startOf('day').unix();
  },

  getEndOfDayTimestamp: function (datetime = new Date(), timeZone = this.DEFAULT_TIME_ZONE) {
    return moment.tz(datetime, timeZone).endOf('day').unix();
  },

  getFirstName: function (name) {
    const split = name.split(/[ ,-]+/);
    return split[0];
  },

  setBodyClassName: function (props) {
    const currentUrl = props.location.pathname;
    const parts = currentUrl.split('/');
    const lastSegment = parts.pop();
    if (lastSegment == 'summary' || lastSegment == 'user_summary') {
      document.body.classList.remove('notes');
      document.body.classList.remove('map');
      document.body.classList.add('summary');
      document.body.classList.remove('dark-theme');
      document.body.classList.add('light-theme');
    } else if (lastSegment == 'notes') {
      document.body.classList.remove('map');
      document.body.classList.remove('summary');
      document.body.classList.add('notes');
      document.body.classList.remove('light-theme');
      document.body.classList.add('dark-theme');
    } else {
      document.body.classList.remove('notes');
      document.body.classList.remove('summary');
      document.body.classList.add('map');
      document.body.classList.remove('light-theme');
      document.body.classList.add('dark-theme');
    }
    return lastSegment;
  },

  getInitials: (string) => {
    const names = string.split(' ');
    let initials = names[0].substring(0, 1).toUpperCase();

    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
  },

  /**
   * Group given array by given key
   *
   * @param {string} key
   * @param {array} array
   */
  groupBy: (key, array) =>
    array.reduce((objectsByKeyValue, obj) => {
      const value = obj[key];
      objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
      return objectsByKeyValue;
    }, {}),

  // [{ id: 12, foo: [ { id: 23, bar: 45 }, { id: 4, bar: 55 } ] }]
  // => { 12_key: { id: 12, foo: { 23_off: { id: 23, bar: 45 }, 4_foo: { id: 4, bar: 55 } } } }
  convertArrayToHash: (records, dataName) => convertArrayToHash(records, dataName),

  convertArrayToCustomHash: (records, dataName) => {
    if (Array.isArray(records)) {
      const rA = [];
      records.forEach((r) => {
        const temp = {};
        temp[`${r.id}_${dataName}`] = r;
        rA.push(temp);
      });

      return rA;
    }

    return records;
  },

  createGuid: () => {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
      (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
    );
  },

  capitalizeFirstLetter: (string) => {
    if (!string.trim().length) return;
    const words = string.split(' ');
    if (!words.length) return;
    for (let i = 0; i < words.length; i++) {
      words[i] = words[i][0].toUpperCase() + words[i].substr(1);
    }
    return words.join(' ');
  },

  getTimeFromNow: (value) => {
    moment.relativeTimeThreshold('m', 59);
    moment.updateLocale('en', {
      relativeTime: {
        future: 'in %s',
        past: '%s ago',
        s: 'a few seconds',
        ss: '%d seconds',
        m: 'a minute',
        mm: '%d minutes',
        h: 'an hour',
        hh: '%d hours',
        d: 'a day',
        dd: '%d days',
        M: 'a month',
        MM: '%d months',
        y: 'a year',
        yy: '%d years',
      },
    });

    return moment.unix(value).fromNow();
  },
};

export default Helpers;

// Convert Array into Hash
// ex
//    records = [{ id: 12, foo: [ { id: 23, bar: 45 }, { id: 4, bar: 55 } ] }]
//    dataName = 'orderGroups'
// // => { 12_orderGroups: { id: 12, foo: { 23_off: { id: 23, bar: 45 }, 4_foo: { id: 4, bar: 55 } } } }
const convertArrayToHash = (records, dataName) => {
  if (Array.isArray(records)) {
    const newRecords = records.map(function (object) {
      if (typeof object !== 'object') {
        return object;
      }
      return traverseAndRecurse(object);
    });
    return setHashKey(newRecords, dataName);
  } else {
    return traverseAndRecurse(records);
  }
};

// Depth first traversal to search for array and call converter recursively
const traverseAndRecurse = (object) => {
  return Object.entries(object)
    .map(function (keyValArray) {
      const key = keyValArray[0];
      const value = keyValArray[1];
      if (Array.isArray(value)) {
        keyValArray[1] = convertArrayToHash(value, key);
      }
      return keyValArray;
    })
    .reduce(function (map, obj) {
      map[obj[0]] = obj[1];
      return map;
    }, {});
};

// In after conversion, assign a key to the array element
const setHashKey = (array, arrayName) => {
  let index = 0;
  return Object.assign(
    {},
    ...array.map(function (ele) {
      let key = ele.id;
      if (key === undefined) {
        return { [index++]: ele };
      }
      if (arrayName) {
        key = `${key}_${arrayName}`;
      }
      return { [key]: ele };
    })
  );
};
