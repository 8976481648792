/**
 * DEPRECATED: Please consult with the FE Performance team before using this file.
 */
import { FETCH_SETTINGS } from '../actions/types';

const initialState = {};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case FETCH_SETTINGS:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
}
