export const DELIVERY_ORDER_STATUSES = Object.freeze({
  unassigned: {
    label: 'Unassigned',
    color: 'var(--fp-colors-orange-950)',
    allowedStates: ['canceled'],
  },
  scheduled: {
    label: 'Scheduled',
    color: 'var(--fp-colors-gray-440)',
    allowedStates: ['started', 'completed', 'departed', 'canceled'],
  },
  departed: {
    label: 'Departed',
    color: 'var(--fp-colors-brown-50)',
    allowedStates: ['started', 'completed', 'scheduled'],
  },
  started: {
    label: 'Started',
    color: 'var(--fp-colors-yellow-50)',
    allowedStates: ['completed', 'departed', 'scheduled'],
  },
  completed: {
    label: 'Completed',
    color: 'var(--fp-colors-green-650)',
    allowedStates: ['verified'],
  },
  verified: {
    label: 'Verified',
    color: 'var(--fp-colors-purple-350)',
    allowedStates: ['completed'],
  },
  accounting_synced: {
    label: 'Synced with Accounting',
    color: 'var(--fp-colors-purple-350)',
    allowedStates: ['verified'],
  },
  blocked: {
    label: 'Blocked',
    color: 'red',
    allowedStates: ['scheduled', 'unassigned'],
  },
  canceled: {
    label: 'Canceled',
    color: 'var(--fp-colors-orange-100)',
    allowedStates: ['unassigned'],
  },
});

export const LOADING_ORDER_STATUSES = Object.freeze({
  unassigned: {
    label: 'Unassigned',
    color: 'var(--fp-colors-orange-950)',
    allowedStates: ['canceled'],
  },
  blocked: {
    label: 'Blocked',
    color: 'red',
    allowedStates: ['scheduled', 'unassigned'],
  },
  scheduled: {
    label: 'Scheduled',
    color: 'var(--fp-colors-gray-440)',
    allowedStates: ['started', 'completed', 'departed', 'canceled'],
  },
  departed: {
    label: 'Departed',
    color: 'var(--fp-colors-brown-50)',
    allowedStates: ['started', 'completed', 'scheduled'],
  },
  started: {
    label: 'Started',
    color: 'var(--fp-colors-yellow-50)',
    allowedStates: ['completed', 'departed', 'scheduled'],
  },
  completed: {
    label: 'Completed',
    color: 'var(--fp-colors-green-650)',
    allowedStates: ['verified'],
  },
  verified: {
    label: 'Verified',
    color: 'var(--fp-colors-purple-350)',
    allowedStates: ['completed'],
  },
  accounting_synced: {
    label: 'Synced with Accounting',
    color: 'var(--fp-colors-purple-350)',
    allowedStates: ['verified'],
  },
  canceled: {
    label: 'Canceled',
    color: 'var(--fp-colors-orange-100)',
    allowedStates: ['unassigned'],
  },
});

export const TRANSFER_ORDER_STATUSES = Object.freeze({
  unassigned: {
    label: 'Unassigned',
    color: 'var(--fp-colors-orange-950)',
    allowedStates: ['canceled'],
  },
  scheduled: {
    label: 'Scheduled',
    color: 'var(--fp-colors-gray-440)',
    allowedStates: ['started', 'completed', 'departed', 'scheduled', 'canceled'],
  },
  completed: {
    label: 'Completed',
    color: 'var(--fp-colors-green-650)',
    allowedStates: ['verified'],
  },
  verified: {
    label: 'Verified',
    color: 'var(--fp-colors-purple-350)',
    allowedStates: ['completed'],
  },
  accounting_synced: {
    label: 'Synced with Accounting',
    color: 'var(--fp-colors-purple-350)',
    allowedStates: ['verified'],
  },
  canceled: {
    label: 'Canceled',
    color: 'var(--fp-colors-orange-100)',
    allowedStates: ['unassigned'],
  },
});

export const ORDER_GROUP_STATUSES = Object.freeze({
  unassigned: { label: 'Unassigned', color: 'var(--fp-colors-orange-950)' },
  scheduled: { label: 'Scheduled', color: 'var(--fp-colors-gray-440)' },
  started: { label: 'Started', color: 'var(--fp-colors-yellow-50)' },
  completed: { label: 'Completed', color: 'var(--fp-colors-green-650)' },
});

export const SHIFT_STATUSES = Object.freeze({
  active: { label: 'Active', color: 'var(--fp-colors-gray-255)', allowedStates: ['completed'] },
  completed: { label: 'Completed', color: 'var(--fp-colors-green-650)' },
  review1: { label: 'Review 1', color: 'var(--fp-colors-blue-930)' },
  review2: { label: 'Review 2', color: 'var(--fp-colors-purple-150)' },
  review3: { label: 'Review 3', color: 'var(--fp-colors-yellow-100)' },
  sync_to_accounting: { label: 'Sync to Accounting', color: 'var(--fp-colors-orange-990)' },
  archived: { label: 'Archived', color: 'var(--fp-colors-red-475)' },
});

export default Object.freeze({
  delivery_order: DELIVERY_ORDER_STATUSES,
  loading_order: LOADING_ORDER_STATUSES,
  transfer_order: TRANSFER_ORDER_STATUSES,
  order_group: ORDER_GROUP_STATUSES,
  shift_status: SHIFT_STATUSES,
});
