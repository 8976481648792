/**
 * DEPRECATED: Please consult with the FE Performance team before using this file.
 */
export const pusherDriverUpdate = (payload, drivers) => {
  const { id: payloadId, activeShift: payloadActiveShift } = payload;
  const tempDrivers = drivers.map((indvDriver) => {
    const { id, activeShift } = indvDriver;

    // Comparing driverId's and activeShiftId's
    if (
      Number(id) === Number(payloadId) &&
      activeShift &&
      payloadActiveShift &&
      Number(activeShift.id) === Number(payloadActiveShift.id)
    ) {
      const tempShiftCompartmentInstances = activeShift?.shiftCompartmentInstances?.map(
        (indvShiftComprt) => {
          // Find the shift compartment to update the volume
          const reqPyldShiftComprt = payloadActiveShift?.shiftCompartmentInstances.find(
            (indvPyldShiftComprt) => Number(indvPyldShiftComprt.id) === Number(indvShiftComprt.id)
          );

          if (reqPyldShiftComprt) {
            if (!indvShiftComprt?.retainInventory) {
              return {
                ...indvShiftComprt,
                retainInventory: reqPyldShiftComprt?.retainInventory,
              };
            } else {
              const tempRetainInvt = indvShiftComprt?.retainInventory?.map((indvRetainInvt) => {
                // Find the inventory to update the volume
                const reqPyldRetainInvt = reqPyldShiftComprt?.retainInventory?.find(
                  (indvPyldRetainInvt) => {
                    return (
                      Number(indvPyldRetainInvt?.product?.id) ===
                      Number(indvRetainInvt?.product?.id)
                    );
                  }
                );

                return reqPyldRetainInvt
                  ? {
                      ...reqPyldRetainInvt,
                      product: {
                        ...indvRetainInvt.product,
                      },
                    }
                  : indvRetainInvt;
              });

              return {
                ...indvShiftComprt,
                retainInventory: tempRetainInvt,
              };
            }
          }

          return indvShiftComprt;
        }
      );

      return {
        ...indvDriver,
        activeShift: {
          ...indvDriver.activeShift,
          shiftCompartmentInstances: tempShiftCompartmentInstances,
        },
      };
    } else {
      return indvDriver;
    }
  });

  return tempDrivers;
};
