import { captureException, setUser } from '@sentry/react';
import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';
import { useCallback, useMemo } from 'react';

import { DASHBOARD_LAYOUT_CONFIG_VERSION_KEY } from 'globalConstants';
import { apolloClient } from 'lib/apollo';
import { mixpanelReset } from 'utils/mixpanel/mixpanel';

import { removeCookies } from '../utils/removeCookies';

import { useCurrent } from './useCurrent';

export type AuthData = {
  auth_logout_feature: boolean;
  customer_id: number;
  exp: number;
  tenant_id: number;
  tenant_slug: string;
  user_class: string;
  user_id: number;
};

const DOMAIN = process.env.REACT_APP_DOMAIN;
const MAIN_APP_URL = process.env.REACT_APP_REDIRECT_URL;

const DASHBOARD_LOCAL_COLUMN_STATE = 'myColumnState-dashboard-orderWell';

let TOKEN: string | null = null;

export function setAuthToken(token: string | null, expires?: number | Date) {
  if (token) {
    Cookies.set('fp_access_token', token, {
      domain: DOMAIN,
      expires,
    });
  } else {
    Cookies.remove('fp_access_token', {
      domain: DOMAIN,
    });
  }

  TOKEN = token;
}

export function logout(redirectTo?: string) {
  removeCookies();

  const url = new URL(window.location.href);
  url.pathname = '/auth/login';

  if (redirectTo) {
    url.searchParams.set('redirect', redirectTo);
  }

  apolloClient.resetStore();
}

export function getAuthToken() {
  const cookieToken = Cookies.get('fp_access_token') ?? null;
  if (cookieToken !== TOKEN) {
    apolloClient.resetStore().catch((error) => {
      if (error.message.includes('Invariant')) {
        return;
      }
      captureException(error);
    });
    TOKEN = cookieToken;
  }

  return TOKEN;
}

export function getIsAuthenticated() {
  return !!getAuthToken();
}

export function getAuthData() {
  const token = getAuthToken();

  return token ? jwtDecode<AuthData>(token) : null;
}

export function useAuth() {
  const token = getAuthToken();
  const { data: currentData } = useCurrent();
  const currentUserId = currentData?.current?.user?.id || '';
  const isAuthenticated = useMemo(() => !!token, [token]);

  const authData = useMemo(() => {
    if (token) {
      try {
        return jwtDecode<AuthData>(token);
      } catch (err) {
        captureException(err);
      }
    }
    return null;
  }, [token]);

  const logout = useCallback(() => {
    removeCookies();
    setUser(null);
    const tempLocalStorageKeys = Object.keys(localStorage);

    if (tempLocalStorageKeys?.length) {
      tempLocalStorageKeys?.map((indvStorageKey) => {
        if (
          ![
            DASHBOARD_LAYOUT_CONFIG_VERSION_KEY,
            `${DASHBOARD_LOCAL_COLUMN_STATE}-${currentUserId}`,
          ]?.includes(indvStorageKey)
        ) {
          localStorage.removeItem(indvStorageKey);
        }
      });
    }
    mixpanelReset();

    apolloClient.resetStore().finally(() => {
      Cookies.remove('fp_access_token', {
        domain: DOMAIN,
      });
    });

    window.location.href = `${MAIN_APP_URL}/users/standalone_logout`;
  }, [currentUserId]);

  return {
    isAuthenticated,
    authData,
    logout,
  };
}
