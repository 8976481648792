import { useFetchCurrentQuery } from 'generated/graphql';

import { getAuthToken } from './useAuth';

setInterval(() => {
  // Checks for external changes in the token
  // TODO: remove set interval for getAuthToken
  getAuthToken();
}, 100);

export function useCurrent() {
  const token = getAuthToken();
  const { data, loading, error, refetch } = useFetchCurrentQuery({
    fetchPolicy: 'cache-first',
    skip: !token,
  });

  return { data, loading, error, reload: refetch };
}
