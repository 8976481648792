import {
  Box,
  forwardRef,
  Menu,
  MenuButton,
  MenuList,
  useControllableProp,
  useMultiStyleConfig,
} from '@chakra-ui/react';
import { useMemo, useState } from 'react';

import { FAIcon } from 'components/FAIcon';

import { TopNavbarItemProps } from './TopNavbarItem';
import { TopNavbarItemButton } from './TopNavbarItemButton';

export type TopNavbarDropdownProps = Omit<TopNavbarItemProps<typeof Menu>, 'variant' | 'css'> & {
  isActive?: boolean;
  trigger: React.ReactNode;
};

const TopNavbarDropdownInner: React.ForwardRefRenderFunction<
  HTMLButtonElement,
  TopNavbarDropdownProps
> = ({ isActive, isOpen: isOpenProp, onOpen, onClose, trigger, children, ...props }, ref) => {
  const [isOpenState, setIsOpen] = useState(false);
  const [isControlled, isOpen] = useControllableProp(isOpenProp, isOpenState);

  const handleOnOpen = () => {
    isControlled ? onOpen?.() : setIsOpen(true);
  };

  const handleOnClose = () => {
    isControlled ? onClose?.() : setIsOpen(false);
  };

  const variant = useMemo(
    () => (isOpen ? 'open' : isActive ? 'active' : 'default'),
    [isOpen, isActive]
  );

  const styles = useMultiStyleConfig('TopNavbar', { variant });

  return (
    <Menu isOpen={isOpen} onOpen={handleOnOpen} onClose={handleOnClose} offset={[0, 0]}>
      <MenuButton
        ref={ref}
        as={TopNavbarItemButton}
        {...props}
        variant={variant}
        sx={styles['dropdown-button']}
      >
        <Box sx={styles['dropdown-button-label']}>
          {trigger}
          <FAIcon icon="chevron-down" variant="light" sx={styles['dropdown-button-icon']} />
        </Box>
      </MenuButton>
      <MenuList sx={styles['dropdown-list']} onClick={handleOnClose}>
        {children as React.ReactNode}
      </MenuList>
    </Menu>
  );
};

TopNavbarDropdownInner.displayName = 'TopNavbarDropdown';

export const TopNavbarDropdown = forwardRef(TopNavbarDropdownInner);

export type TopNavbarDropdown = typeof TopNavbarDropdown;
