import { createStandaloneToast } from '@chakra-ui/react';

import { ALERT_STATUS } from 'hooks/usePandaToast';
import { theme } from 'theme';

const { toast, ToastContainer } = createStandaloneToast({
  defaultOptions: {
    status: ALERT_STATUS.ERROR,
    duration: 5000,
    isClosable: true,
    position: 'top-right',
  },
  theme,
});

export { toast, ToastContainer };
