import { Box, Spinner } from '@chakra-ui/react';

export const FullContainerSpinner: React.FC = () => {
  return (
    <Box
      bgColor="gray.700"
      zIndex="9999"
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="100%"
      height="100%"
    >
      <Spinner
        thickness="4px"
        speed="0.65s"
        emptyColor="transparent"
        color="orange.100"
        size="xl"
      />
    </Box>
  );
};

export type FullContainerSpinner = typeof FullContainerSpinner;
