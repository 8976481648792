import { useMemo } from 'react';

import { useCheckSettings } from 'features/auth';

import { TopNavbarDropdown } from './TopNavbarDropdown';
import { TopNavbarDropdownItemLink } from './TopNavbarDropdownItemLink';

const REDIRECT_URL = process.env.REACT_APP_REDIRECT_URL;

export const TopNavbarDispatch = () => {
  const [hasSettings] = useCheckSettings();

  const isMigratedLoadingTimeEnabled = hasSettings('migrated_loading_time_feature');
  const isMigratedDeliveryTimeEnabled = hasSettings('migrated_delivery_time_feature');
  const isMigratedDriverGroupsEnabled = hasSettings('migrated_driver_groups_feature');
  const isMigratedShiftQrCodesEnabled = hasSettings('migrated_shift_qr_codes_feature');
  const isMigratedDriverAppDetailsEnabled = hasSettings('migrated_driver_app_details_feature');

  const switchBetweenMigratedUrl = useMemo(
    () => ({
      delivery_time: isMigratedDeliveryTimeEnabled
        ? '/drivers/delivery-time-spent'
        : `${REDIRECT_URL}main/drivers/delivery_time_spent`,
      loading_time: isMigratedLoadingTimeEnabled
        ? '/drivers/loading-time-spent'
        : `${REDIRECT_URL}main/drivers/loading_time_spent`,
      qr_codes: isMigratedShiftQrCodesEnabled ? '/drivers/qr-codes' : `${REDIRECT_URL}qr_codes`,
      driver_app_details: isMigratedDriverAppDetailsEnabled
        ? '/drivers/driver-app-details'
        : `${REDIRECT_URL}main/drivers/app_details`,
      driver_groups: isMigratedDriverGroupsEnabled
        ? '/dispatch/driver-groups'
        : `${REDIRECT_URL}groups`,
    }),
    [
      isMigratedDeliveryTimeEnabled,
      isMigratedLoadingTimeEnabled,
      isMigratedDriverGroupsEnabled,
      isMigratedShiftQrCodesEnabled,
      isMigratedDriverAppDetailsEnabled,
    ]
  );

  if (hasSettings('dispatch')) {
    return (
      <TopNavbarDropdown trigger="Dispatch">
        {hasSettings('show_analytics_feature') && (
          <>
            <TopNavbarDropdownItemLink href={switchBetweenMigratedUrl['delivery_time']}>
              Delivery Time
            </TopNavbarDropdownItemLink>
            <TopNavbarDropdownItemLink href={switchBetweenMigratedUrl['loading_time']}>
              Loading Time
            </TopNavbarDropdownItemLink>
          </>
        )}
        {hasSettings('qr_codes') && (
          <TopNavbarDropdownItemLink href={switchBetweenMigratedUrl['qr_codes']}>
            Shift/QR Codes
          </TopNavbarDropdownItemLink>
        )}
        <TopNavbarDropdownItemLink href={switchBetweenMigratedUrl['driver_app_details']}>
          Driver App Details
        </TopNavbarDropdownItemLink>
        <TopNavbarDropdownItemLink href={switchBetweenMigratedUrl['driver_groups']}>
          Driver Groups
        </TopNavbarDropdownItemLink>
      </TopNavbarDropdown>
    );
  }
  return null;
};
