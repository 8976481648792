import {
  Box,
  forwardRef,
  useMultiStyleConfig,
  type As,
  type CSSObject,
  type HTMLChakraProps,
} from '@chakra-ui/react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type TopNavbarItemProps<T extends As<any>> = HTMLChakraProps<T> & {
  variant?: 'default' | 'active' | 'open';
  wrapperSx?: CSSObject;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const TopNavbarItemInner = <T extends As<any>>(
  { variant = 'default', as = 'div', sx, wrapperSx, ...boxProps }: TopNavbarItemProps<T>,
  ref: React.Ref<HTMLDivElement>
) => {
  const { item: styles, 'item-wrapper': wrapperStyles } = useMultiStyleConfig('TopNavbar', {
    variant,
  });

  return (
    <Box ref={ref} sx={{ ...wrapperStyles, ...wrapperSx }}>
      <Box as={as} data-testid="top-navbar-item" sx={{ ...styles, ...sx }} {...boxProps} />
    </Box>
  );
};

TopNavbarItemInner.displayName = 'TopNavbarItem';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const TopNavbarItem = forwardRef(TopNavbarItemInner) as <T extends As<any>>(
  props: React.PropsWithoutRef<TopNavbarItemProps<T>> & React.RefAttributes<HTMLDivElement>
) => JSX.Element;

export type TopNavbarItem = typeof TopNavbarItem;
