import { Button, Container, Flex } from '@chakra-ui/react';
import { GridApi, GridReadyEvent } from 'ag-grid-community';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { TableView } from 'components/TableView';
import { LoadingComponent } from 'components/TableView/LoadingComponent';
import { NoRowOverlayComponent } from 'components/TableView/NoRowsOverlayComponent';
import { columnDefs, columnKeys, defaultColDef } from 'features/drivers/helpers/tableDefs';
import { getDocumentNamesForDateRange } from 'features/drivers/utils/getDocumentNamesForDateRange';
import { TopBar } from 'features/routing/components/TopBar';
import { COMMONS, DATE_FORMAT, ORDER_TYPE } from 'globalConstants';
import { useFirebaseForGeofence } from 'hooks/useFirebaseForGeofence';
import { RootStateType } from 'types/store';

import { FilteredDataType } from '../../types';

const { MMM_DD_YYYY, HH_mm, YYYY_MM_DD } = DATE_FORMAT;
const { START_DATE, END_DATE } = COMMONS;
/*
 This component simply shows the time duration of a drivers at what time they have arrived 
 and depareted from the customer premises.
 */
export const DeliveryTime = () => {
  const location = useLocation();
  const [tenantSlug] = useSelector(
    (state: RootStateType) => [state.current.customer?.tenantSlug],
    shallowEqual
  );
  const query = new URLSearchParams(location.search);
  const initialFromDate = DateTime.now().minus({ days: 7 }).toFormat(YYYY_MM_DD);
  const initialToDate = DateTime.now().toFormat(YYYY_MM_DD);
  const fromDate = query.get(START_DATE) || initialFromDate;
  const toDate = query.get(END_DATE) || initialToDate;
  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const [gridData, setGridData] = useState<FilteredDataType[]>([]);
  const [filteredData, setFilteredData] = useState<FilteredDataType[]>([]);

  //hooks that fetch firebase data
  const fireBase = useFirebaseForGeofence(fromDate, toDate);

  const fireStoreEvents = fireBase.events;

  useEffect(() => {
    if (!fireStoreEvents) {
      return;
    }

    const documentNamesForDateRange = getDocumentNamesForDateRange(fromDate, toDate, tenantSlug);
    const selectedEvents: FilteredDataType[] = [];

    for (const [key1, value1] of Object.entries(fireStoreEvents)) {
      if (documentNamesForDateRange.includes(key1) && value1) {
        for (const value2 of Object.values(value1)) {
          if (!value2 || value2.orderType !== ORDER_TYPE.DELIVERY) {
            continue;
          }

          const { fenceInTimestamp, fenceOutTimestamp } = value2;

          const fenceInTimeStampAsLuxon = DateTime.fromSeconds(parseInt(fenceInTimestamp));
          const fenceOutTimeStampAsLuxon = DateTime.fromSeconds(parseInt(fenceOutTimestamp));

          const dateDiff = fenceOutTimeStampAsLuxon
            .diff(fenceInTimeStampAsLuxon, 'minutes')
            ?.toObject();

          if ((dateDiff?.minutes || 0) <= 10) {
            continue;
          }

          // Difference between fenceOut and fenceIn in minutes
          const driverTimeSpent = Math.round(
            fenceOutTimeStampAsLuxon.diff(fenceInTimeStampAsLuxon).as('seconds') / 60
          );

          const formattedObject = {
            orderType: value2.orderType,
            destinationName: value2.destinationName || '-',
            driverName: value2.driverName || '-',
            fenceInTimestamp: fenceInTimeStampAsLuxon
              ? fenceInTimeStampAsLuxon.toFormat(MMM_DD_YYYY + ' ' + HH_mm)
              : '-',
            fenceOutTimestamp: fenceOutTimeStampAsLuxon
              ? fenceOutTimeStampAsLuxon.toFormat(MMM_DD_YYYY + ' ' + HH_mm)
              : '-',
            timeSpent: `${driverTimeSpent} minutes`,
          };
          selectedEvents.push(formattedObject);
        }
      }
    }
    setGridData([...selectedEvents]);
    setFilteredData(selectedEvents);
  }, [fireStoreEvents, fromDate, tenantSlug, toDate]);

  const onFilterTextBoxChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchKeyWord = e.target.value.toLowerCase();
    if (searchKeyWord.length == 0) {
      setFilteredData(gridData);
    }
    const result = gridData.filter((x) => {
      if (
        x?.destinationName?.toLowerCase().includes(searchKeyWord) ||
        x?.driverName?.toLowerCase().includes(searchKeyWord)
      ) {
        return true;
      }
    });
    setFilteredData(result);
  };

  const handleExportCSVClick = () => {
    gridApi?.exportDataAsCsv({ columnKeys });
  };

  return (
    <Flex height={'full'} flexDir={'column'}>
      <TopBar
        title="Driver' s time spent in customer premises"
        searchFieldProps={{
          placeholder: 'Search',
          title: 'Search',
          onChange: onFilterTextBoxChanged,
        }}
        renderElements={[
          <Button key={'export-to-csv'} onClick={handleExportCSVClick} size="md">
            Export to CSV
          </Button>,
        ]}
      />
      <Container maxW={'full'} height={'full'} pb={'5'}>
        <TableView
          scrollbarWidth={22}
          suppressContextMenu={true}
          rowHeight={30}
          rowSelection="multiple"
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          suppressRowClickSelection={true}
          groupSelectsChildren={true}
          rowData={filteredData}
          rowGroupPanelShow="onlyWhenGrouping"
          pivotPanelShow="always"
          enterMovesDown={true}
          boxProps={{
            h: 'full',
          }}
          multiSortKey="ctrl"
          allowContextMenuWithControlKey={true}
          popupParent={document.querySelector('body')}
          paginationAutoPageSize={true}
          pagination={false}
          animateRows={true}
          onGridReady={(params: GridReadyEvent) => {
            setGridApi(params?.api);
          }}
          enableRangeSelection={true}
          loadingOverlayComponent={LoadingComponent}
          loadingOverlayComponentParams={{ message: 'Please wait while data are loading' }}
          noRowsOverlayComponent={NoRowOverlayComponent}
        />
      </Container>
    </Flex>
  );
};
