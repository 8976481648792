/* eslint-disable */

import * as actions from '../actions';

import { fetchGraphQLData, fetchGraphQLDataMutation } from '../../../graphql';
import Helpers from '../../../helpers';
import { defaultsDeep } from 'lodash';
import { mutation } from 'gql-query-builder';
import { ORDER_GROUP_FRAGMENT } from 'javascript/schemas/fragments';

export const setCurrentTimestamps = (fromTime, toTime) => (dispatch) => {
  dispatch({
    type: actions.SET_TIMESTAMPS,
    payload: { fromTime: fromTime, toTime: toTime },
  });
  dispatch(fetchRecurrenceOrders());
};

export const openTransferOrder = (data) => async (dispatch) => {
  dispatch({
    type: actions.OPEN_TRANSFER_ORDER_DETAIL,
    payload: data,
  });
};

export const refetchTransferOrders = (data) => async (dispatch) => {
  dispatch({
    type: actions.REFETCH_TRANSFER_ORDERS,
    payload: data,
  });
};

export const orderDateChange = (data) => async (dispatch) => {
  dispatch({
    type: actions.ORDER_DATE_CHANGE,
    payload: data,
  });
};

export const refetchOrderData = (data) => async (dispatch) => {
  dispatch({
    type: actions.REFETCH_ORDER_DATA,
    payload: data,
  });
};

/**
 * List minimal information of shifts for listing
 *
 * @returns {function}
 */
export const fetchOrderGroups =
  (filter = {}) =>
  async (dispatch) => {
    dispatch({
      type: actions.FETCHING_ORDER_GROUPS,
      payload: true,
    });

    const QUERY = `
  {
    orderGroups(filter: ${JSON.stringify(filter).replace(/"([^(")"]+)":/g, '$1:')}) {
      ${ORDER_GROUP_FRAGMENT}
    }
  }
  `;
    const response = await fetchGraphQLData(JSON.stringify({ query: QUERY }));
    const fetchedOrderGroups = response.data?.orderGroups || [];

    dispatch({
      type: actions.FETCH_ORDER_GROUPS,
      payload: Helpers.convertArrayToHash(fetchedOrderGroups, 'orderGroups'),
    });

    dispatch({
      type: actions.FETCHING_ORDER_GROUPS,
      payload: false,
    });
  };

export const fetchFilteredRecurrenceOrders =
  (perPage = 30, offSet = 0, sorter = '', searchText = '') =>
  async (dispatch) => {
    const QUERY = `
  {
    filteredOrderGroups (
      limit: ${perPage},
      offset: ${offSet},
      ${sorter}
      filter: {
        recurring: true,
        all:  "${searchText}"
      }
    ){
      collection {
        id
        startOnTimestamp
        ccTenantId
        poNumber
        loadingOrder {
          terminal {
            id
            name
          }
        }
        ccTenant{
          id
          carrierTenant{
            id
            name
          }
        }
        deliveryOrder {
          id
          deliveryOrderLineItems{
            id
            topOff
            requestedVolume
            warehouseId
            product{
              id
              name
            }
            subscription{
              id
              asset{
                id
                name
              }
              product{
                id
                name
              }
            }
          }
        }
        hub{
          id
          name
        }
        worker {
          id
          name
        }
        customerBranch {
          id
          name
          erpId
          address
          customer {
              id
              name
              erpId
            }
        }
        creatable {
          type: __typename
          ...on User{
            name
          }
        }
        recurrenceData {
          lastDelivery
          nextDate
          recurrence {
            recurrenceFromTimestamp
            recurrenceType
            recurrenceDates
            recurrenceDays
            recurrenceInterval
            recurrenceUntil
            recurrenceOccurrences
            recurrenceDaysOfWeek
            recurrenceDaysInAdvance
            recurrenceDayNthWeek {
              sunday
              monday
              tuesday
              wednesday
              thursday
              friday
              saturday
            }
          }
        }
      }
      metadata {
        hasMore
        totalCount
        total_entries
      }
    }
  }
`;
    const response = await fetchGraphQLData(JSON.stringify({ query: QUERY }));
    const fetchedFilteredRecurrenceOrders = response.data?.filteredOrderGroups || [];
    dispatch({
      type: actions.FETCH_FILTERED_RECURRENCE_ORDERS,
      payload: fetchedFilteredRecurrenceOrders,
    });
  };

export const fetchDeliveryOrders =
  (filter = {}) =>
  async (dispatch) => {
    dispatch({
      type: actions.FETCHING_DELIVERY_ORDERS,
      payload: true,
    });
    const QUERY = `
    {
    orderGroups {
      id
      carrierNumber
      poNumber
      startOnTimestamp
      status
      fees {
        id
        code
        price
        description
        name
      }
      deliveryOrders {
        id
        status
        orderNumber
        deliveryInstruction
        customerBranch {
          id
          name
          customer {
            fees {
              id
              name
              price
            }
          }
        }
        tasks {
          id
          status
          driverAcceptance
          startOnTimestamp
          completeOnTimestamp
          startedOnTimestamp
          completedOnTimestamp
          driver {
            name
          }
        }
        lineItems {
          id
          source
          requestedVolume
          volume
          status
          subscription {
            product {
              category
              name
            }
            asset {
              category
              licensePlateNumber
            }
          }
        }
      }
    }
  }
  `;
    const response = await fetchGraphQLData(JSON.stringify({ query: QUERY }));
    const fetchedDeliveryOrders = response.data?.orderGroups || [];

    dispatch({
      type: actions.FETCH_DELIVERY_ORDERS,
      payload: Helpers.convertArrayToHash(fetchedDeliveryOrders, 'orderGroups'),
    });

    dispatch({
      type: actions.FETCHING_DELIVERY_ORDERS,
      payload: false,
    });
  };

export const fetchLoadingOrders =
  (filter = {}) =>
  async (dispatch) => {
    dispatch({
      type: actions.FETCHING_LOADING_ORDERS,
      payload: true,
    });

    const QUERY = `
                        {
                          orderGroups {
                            id
                            carrierNumber
                            poNumber
                            startOnTimestamp
                            status
                            loadingOrders {
                              id
                              status
                              terminal {
                                id
                                name
                                site {
                                  address
                                }
                              }
                              task {
                                id
                                status
                                driverAcceptance
                                startOnTimestamp
                                completeOnTimestamp
                                startedOnTimestamp
                                completedOnTimestamp
                                customerBranch {
                                  id
                                  name
                                }
                                driver {
                                  name
                                }
                              }
                              loadingOrderProducts {
                                id
                                grossQuantity
                                product {
                                  name
                                  code
                                }
                                preferredSuppliers {
                                  priority
                                  loadingAccount {
                                    loadingNumber
                                    supplier {
                                      name
                                      erpId
                                      supplierId

                                    }
                                  }
                                }
                                loadingOrderLineItems {
                                  id
                                  netAmount
                                  compartment {
                                    id
                                    name
                                  }
                                  compartmentId
                                  productId
                                }
                              }
                              loadingReceipts {
                                id
                                loadingReceiptProducts {
                                  id
                                  grossVolume
                                }
                              }
                              demurrageNotes {
                                content
                                commenter
                              }
                            }
                          }
                        }
                    `;
    const response = await fetchGraphQLData(JSON.stringify({ query: QUERY }));
    const fetchedLoadOrders = response.data?.orderGroups || [];

    dispatch({
      type: actions.FETCH_LOADING_ORDERS,
      payload: Helpers.convertArrayToHash(fetchedLoadOrders, 'orderGroups'),
    });

    dispatch({
      type: actions.FETCHING_LOADING_ORDERS,
      payload: false,
    });
  };

export const fetchDrivers =
  (filter = {}) =>
  async (dispatch) => {
    dispatch({
      type: actions.FETCHING_DRIVERS,
      payload: true,
    });

    const query = `
      {
        drivers{
          id
          name
          status
        }
      }
  `;
    const response = await fetchGraphQLData(JSON.stringify({ query }));
    const fetchedDrivers = response.data.drivers;

    dispatch({
      type: actions.FETCH_DRIVERS,
      payload: fetchedDrivers,
    });

    dispatch({
      type: actions.FETCHING_DRIVERS,
      payload: false,
    });
  };

export const fetchCustomers =
  (filter = {}) =>
  async (dispatch) => {
    dispatch({
      type: actions.FETCHING_CUSTOMERS,
      payload: true,
    });

    const query = `
    {
      customers{
        id
        name
        erpId
        customerBranches {
          id
          name
          erpId
          address
        }
      }
    }
  `;
    const response = await fetchGraphQLData(JSON.stringify({ query }));
    const fetchedCustomers = response.data.customers;

    dispatch({
      type: actions.FETCH_CUSTOMERS,
      payload: fetchedCustomers,
    });

    dispatch({
      type: actions.FETCHING_CUSTOMERS,
      payload: false,
    });
  };

export const createNewRecurrenceOrder = (attributes, successCb, errorCb) => async (dispatch) => {
  dispatch({
    type: actions.CREATING_NEW_RECURRENCE,
    payload: true,
  });
  const response = await fetchGraphQLDataMutation(
    JSON.stringify(
      mutation({
        operation: 'createSiteRecurrence',
        variables: {
          input: {
            type: 'CreateSiteRecurrenceInput',
            required: true,
            value: defaultsDeep(attributes),
          },
        },
        fields: [
          {
            orderGroup: ['id'],
          },
        ],
      })
    )
  );

  if (response.error || response.errors?.length > 0) {
    if (errorCb) errorCb();
    toastr.error(response?.error?.message || response?.errors?.[0]?.message);
  } else {
    toastr.success(
      'Recurring order created. The order will be visible in order well one day before the planned date.'
    );
    dispatch({
      type: actions.FETCH_RECURRENCE,
      payload: true,
    });
    if (successCb) successCb();
  }

  dispatch({
    type: actions.CREATING_NEW_RECURRENCE,
    payload: false,
  });
};

export const updateRecurrenceOrder = (attributes, successCb, errorCb) => async (dispatch) => {
  dispatch({
    type: actions.UPDATING_RECURRENCE,
    payload: true,
  });
  const response = await fetchGraphQLDataMutation(
    JSON.stringify(
      mutation({
        operation: 'updateOrderGroup',
        variables: {
          input: {
            type: 'UpdateOrderGroupMutationInput',
            required: true,
            value: defaultsDeep(attributes),
          },
        },
        fields: [
          {
            orderGroup: ['id'],
          },
        ],
      })
    )
  );

  if (response?.error || response?.errors?.length > 0) {
    if (errorCb) errorCb();
    toastr.error(response?.error?.message || response?.errors?.[0]?.message);
  } else {
    toastr.success('Recurring order updated');
    dispatch({
      type: actions.FETCH_RECURRENCE,
      payload: true,
    });
    if (successCb) successCb();
  }

  dispatch({
    type: actions.UPDATING_RECURRENCE,
    payload: false,
  });
};

export const fetchRecurrenceOrders =
  (filter = {}) =>
  async (dispatch) => {
    // const fromTime = getState().ordersReducer.fromTime
    // const toTime = getState().ordersReducer.toTime
    dispatch({
      type: actions.FETCHING_RECURRENCE_ORDERS,
      payload: true,
    });

    const query = `
    {
      recurrencesOrders{
        customer {
          id
          name
        }
        customerBranch {
          id
          name
          erpId
          address
        }
        orderGroup {
          id
          startOnTimestamp
          deliveryOrders {
            id
          }
        }
        lastDelivery
        nextDate
        recurrence {
          recurrenceFromTimestamp
          recurrenceType
          recurrenceDates
          recurrenceDays
          recurrenceInterval
          recurrenceUntil
          recurrenceOccurrences
          recurrenceDaysOfWeek
          recurrenceDaysInAdvance
          recurrenceDayNthWeek {
            sunday
            monday
            tuesday
            wednesday
            thursday
            friday
            saturday
          }
        }
      }
    }
  `;
    const response = await fetchGraphQLData(JSON.stringify({ query }));
    const fetchedRecurrenceOrder = response.data?.recurrencesOrders;
    dispatch({
      type: actions.FETCH_RECURRENCE_ORDERS,
      payload: fetchedRecurrenceOrder,
    });

    dispatch({
      type: actions.FETCHING_RECURRENCE_ORDERS,
      payload: false,
    });
  };

export const deleteRecurringOrders =
  (deliveryOrderIds = [], successCb, errorCb) =>
  async (dispatch) => {
    dispatch({
      type: actions.DELETING_RECURRENCE_ORDERS,
      payload: true,
    });
    const response = await fetchGraphQLDataMutation(
      JSON.stringify(
        mutation({
          operation: 'deleteSiteRecurrence',
          variables: {
            input: {
              type: 'DeleteSiteRecurrenceInput',
              required: true,
              value: defaultsDeep({ ids: deliveryOrderIds }),
            },
          },
          fields: ['success'],
        })
      )
    );
    if (response.error || response.errors) {
      if (errorCb) errorCb();
      toastr.error(response?.error?.message || response?.errors?.[0]?.message);
    } else {
      if (successCb) successCb();
      toastr.success('Recurring orders deleted');
      dispatch({
        type: actions.FETCH_RECURRENCE,
        payload: true,
      });
      // dispatch(fetchRecurrenceOrders())
    }
    dispatch({
      type: actions.DELETING_RECURRENCE_ORDERS,
      payload: false,
    });
  };
