import { Route } from 'react-router-dom';

import { lazyImport } from 'utils/lazyImport';

const { AuthRoutes } = lazyImport(() => import('features/auth'), 'AuthRoutes');
const { DeliveryTypeOrderDetailPage } = lazyImport(
  () => import('features/orderDetails/pages/DeliveryTypeOrderDetailPage'),
  'DeliveryTypeOrderDetailPage'
);
const { CCDeliveryOrderDetailPage } = lazyImport(
  () => import('features/orderDetails/pages/CCOrderDetailPage'),
  'CCDeliveryOrderDetailPage'
);

const ORDER_DETAILS = {
  delivery: <DeliveryTypeOrderDetailPage />,
};

export const publicRoutes = (
  <>
    <Route path="auth/*" element={<AuthRoutes />} />
    {(['delivery'] as const).map((path) => {
      return <Route key={path} path={`${path}/:id`} element={ORDER_DETAILS[path]} />;
    })}
    {/* ccog => Common Carrier Oder Group */}
    <Route key={'ccDelivery'} path={`ccog/:id`} element={<CCDeliveryOrderDetailPage />} />
  </>
);
