/**
 * DEPRECATED: Please consult with the FE Performance team before using this file.
 */
export default `
  id
  startOnTimestamp
  endOnTimestamp
  state
  worker {
    id
    name
    status
    hubs {
      name
    }
  }
  shiftDeliveryUnitsAssets {
    id
    deliveryAsset {
      id
      licensePlateNumber
      name
      category
    }
  }
  orderGroups {
    loadingOrder {
      id
      terminal {
        id
        name
      }
    }
    deliveryOrder {
      id
      customerBranch {
        id
        name
      }
    }
  }
`;
