import { ChakraProvider, ColorModeScript } from '@chakra-ui/react';
import * as React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';

// import { FullPageError } from 'components/FullPageError';
import { FullPageSpinner } from 'components/FullPageSpinner';
import { ErrorBoundary } from 'error/ErrorBoundary';
import { ApolloWrapper } from 'lib/apollo';
import { store } from 'lib/store';
import { theme } from 'theme';

import 'utils/mixpanel/initializeMixpanel';

type AppProviderProps = {
  children: React.ReactNode;
};

export const AppProvider = ({ children }: AppProviderProps) => {
  return (
    <Provider store={store}>
      <React.Suspense fallback={<FullPageSpinner />}>
        <ColorModeScript initialColorMode={theme.config.initialColorMode} />
        <ChakraProvider theme={theme}>
          {/* <Sentry.ErrorBoundary fallback={<FullPageError />}> */}
          <ErrorBoundary>
            <ApolloWrapper>
              <Router>{children}</Router>
            </ApolloWrapper>
          </ErrorBoundary>
          {/* </Sentry.ErrorBoundary> */}
        </ChakraProvider>
      </React.Suspense>
    </Provider>
  );
};
