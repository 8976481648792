import { chakra, forwardRef, HTMLChakraProps } from '@chakra-ui/react';

const TopNavbarDividerInner: React.ForwardRefRenderFunction<
  HTMLHRElement,
  HTMLChakraProps<'hr'>
> = (props, ref) => {
  return (
    <chakra.hr
      ref={ref}
      __css={{
        bgColor: 'rgba(149,159,174)',
        border: 'none',
        height: '7',
        mx: '1.5',
        my: 'auto',
        opacity: '0.5',
        width: 'px',
      }}
      {...props}
    />
  );
};

TopNavbarDividerInner.displayName = 'TopNavbarDivider';

export const TopNavbarDivider = forwardRef(TopNavbarDividerInner);

export type TopNavbarDivider = typeof TopNavbarDivider;
