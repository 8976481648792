/**
 * DEPRECATED: Please consult with the FE Performance team before using this file.
 */
export const SHIFT_BOARD_BOL_ERRORS_FRAGMENT = `
  id
  shiftCompartmentInstances {
    id
    shiftStartInventory {
      product {
        id
        shortName
      }
      volume
    }
  }
  bolErrors {
    orderGroupId
    volume
  }
  bolRetains {
    volume
    product {
      id
      shortName
    }
  }
`;

export const SHIFT_BOARD_FRAGMENT_WITH_BOL_ERROR = `
 id
 startOnTimestamp
 endOnTimestamp
 state
 errors
 enableClearingAccountingData
 shiftCompartmentInstances {
   id
   shiftStartInventory {
     product {
       id
       shortName
     }
     volume
   }
 }
 bolErrors {
   orderGroupId
   volume
 }
 bolRetains {
   volume
   product {
     id
     shortName
   }
 }
 worker {
   id
   name
   status
   hubs {
     name
   }
 }
 shiftDeliveryUnitsAssets {
   id
   deliveryAsset {
     id
     licensePlateNumber
     name
     category
   }
 }
 orderGroups {
   id
   hub {
     id
     name
   }
   carrierNumber
   poNumber
   lineOfService {
     id
     name
     shortName
   }
   loadingOrder {
     id
     terminal {
       id
       name
     }
     loadingReceipts {
       id
       receiptNumber
       loadingAccount {
         carrierCustomerCode
       }
     }
   }
   deliveryOrder {
     id
     customerBranch {
       id
       name
     }
   }
 }
`;
