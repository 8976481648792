import { Button, IconButton, Tooltip } from '@chakra-ui/react';
import { forwardRef, Ref, useMemo } from 'react';

import { FAIcon } from 'components/FAIcon';
import { COMMONS } from 'globalConstants';
import { PandaButtonPropsType } from 'types/pandaButton';

import { pandaBtnStyle, pandaBtnTooltip } from './styles';

const { REGULAR, ICON_ONLY } = COMMONS;

export const PandaButton = forwardRef(function PandaButton(
  btnProps: PandaButtonPropsType,
  ref: Ref<HTMLButtonElement>
) {
  const {
    btnType = REGULAR,
    btnLabel,
    iconProps,
    btnIcon,
    tooltipLabel,
    tooltipProps,
    btnStyleProps,
    sx,
    ...restProps
  } = btnProps;

  const renderButton = useMemo(() => {
    switch (btnType) {
      case REGULAR:
        return (
          <Button
            leftIcon={
              btnIcon ? <FAIcon icon={btnIcon} variant="solid" {...iconProps} /> : undefined
            }
            sx={{ ...pandaBtnStyle, ...btnStyleProps, ...sx }}
            variant="solid"
            borderRadius={'4px'}
            {...restProps}
            ref={ref}
          >
            {btnLabel}
          </Button>
        );
      case ICON_ONLY:
        return (
          <IconButton
            aria-label={btnLabel || 'Default Label'}
            sx={{
              ...pandaBtnStyle,
              ...btnStyleProps,
              ...sx,
            }}
            borderRadius={'4px'}
            icon={<FAIcon icon={btnIcon || 'triangle'} variant="solid" {...iconProps} />}
            ref={ref}
            {...restProps}
          />
        );
      default:
        return 'Invalid Button Type';
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [btnIcon, btnLabel, btnType, restProps]);

  return (
    <Tooltip
      hasArrow
      bg="gray.450"
      label={tooltipLabel}
      isDisabled={!tooltipLabel}
      sx={pandaBtnTooltip}
      {...tooltipProps}
    >
      {renderButton}
    </Tooltip>
  );
});
