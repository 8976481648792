import {
  chakra,
  ComponentWithAs,
  Flex,
  forwardRef,
  Menu,
  MenuButton,
  MenuButtonProps,
  MenuList,
  MenuProps,
  Text,
} from '@chakra-ui/react';
import { runIfFn } from '@chakra-ui/utils';

import { FAIcon } from 'components/FAIcon';
import { UserAvatar } from 'components/UserAvatar';

import { TopNavbarDropdownDivider } from './TopNavbarDropdownDivider';

export type UserDropdownProps = Omit<ComponentWithAs<'button', MenuButtonProps>, 'children'> & {
  children: MenuProps['children'];
  name?: string;
  tenant?: string;
};

const UserDropdownInner: React.ForwardRefRenderFunction<HTMLButtonElement, UserDropdownProps> = (
  { children, name, tenant, ...props },
  ref
) => {
  return (
    <Menu offset={[0, 20]}>
      {({ isOpen, onClose, forceUpdate }) => (
        <>
          <MenuButton as={chakra.button} ref={ref} paddingX="1.5" {...props}>
            <Flex alignItems="center">
              <UserAvatar name={name} />
              <FAIcon icon="chevron-down" variant="light" ml="1.5" fontSize="xxs" />
            </Flex>
          </MenuButton>
          <MenuList
            zIndex={3}
            sx={{
              backgroundColor: 'gray.550',
              border: 'none',
              boxShadow: '6px 6px 6px rgb(0 0 0 / 25%)',
              minWidth: '0',
              p: '3',
              position: 'relative',
              inset: '-16px 0px auto auto',
              '&:before': {
                borderColor: 'transparent',
                borderBottomColor: 'gray.550',
                borderStyle: 'solid',
                borderWidth: '0 12px 10px 12px',
                bottom: '100%',
                content: '""',
                height: '0',
                position: 'absolute',
                right: '24px',
                width: '0',
              },
            }}
          >
            {name && (
              <>
                <Flex alignItems="center" p="3" columnGap="1.5">
                  <UserAvatar name={name} />
                  <Flex direction="column">
                    <Text fontSize="md" lineHeight="1" fontWeight="600">
                      {name}
                    </Text>
                    {tenant && (
                      <Text id={tenant} fontSize="sm" color="gray.150" lineHeight="1.66">
                        {tenant}
                      </Text>
                    )}
                  </Flex>
                </Flex>
                <TopNavbarDropdownDivider />
              </>
            )}
            {runIfFn(children, { isOpen, onClose, forceUpdate })}
          </MenuList>
        </>
      )}
    </Menu>
  );
};

UserDropdownInner.displayName = 'UserDropdown';

export const UserDropdown = forwardRef(UserDropdownInner);

export type UserDropdown = typeof UserDropdown;
