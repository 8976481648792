import { useToast, UseToastOptions } from '@chakra-ui/react';
import { useMemo } from 'react';

export const usePandaToast = () => {
  const toast = useToast();
  const pandaToast = useMemo(() => {
    const pandaToast = (toastProps: UseToastOptions) => {
      const {
        title,
        description,
        status = ALERT_STATUS.INFO,
        duration = 9000,
        isClosable = true,
        position = 'top-right',
        ...restProps
      } = toastProps;
      toast({ title, description, status, duration, isClosable, position, ...restProps });
    };
    return pandaToast;
  }, [toast]);

  return { pandaToast };
};

export enum ALERT_STATUS {
  INFO = 'info',
  WARNING = 'warning',
  SUCCESS = 'success',
  ERROR = 'error',
  LOADING = 'loading',
}
