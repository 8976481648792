/**
 * DEPRECATED: Please consult with the FE Performance team before using this file.
 */
export const CommonCarrierFilterEnum = {
  ALL: 'all',
  COMMON_CARRIER_SHIFTS: 'commonCarrierShifts',
  NORMAL_SHIFTS: 'normalShifts',
};

export const CommonCarrierFilterOptions = [
  {
    label: 'All Shifts',
    value: CommonCarrierFilterEnum.ALL,
  },
  {
    label: 'Common Carrier Shifts',
    value: CommonCarrierFilterEnum.COMMON_CARRIER_SHIFTS,
  },
  {
    label: 'Normal Shifts',
    value: CommonCarrierFilterEnum.NORMAL_SHIFTS,
  },
];

// Value is set lowercase to reduce toLowerCase method calls
export const CommonCarrierDriverName = 'common carrier';
