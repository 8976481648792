import * as actions from '../actions';

export const initialState = {
  shiftDetailParams: null,
};

export default function bulkPlantInventoryDetailsReducers(state = initialState, action) {
  switch (action.type) {
    case actions.SET_BULK_PLANT_SHIFT_MODAL_PARAM:
      return {
        ...state,
        shiftDetailParams: action.payload,
      };
    default:
      return state;
  }
}
