import { ComponentStyleConfig } from '@chakra-ui/react';

export const Input: ComponentStyleConfig = {
  variants: {
    filled: ({ theme }) => ({
      field: {
        backgroundColor: 'gray.750',
        _focus: {
          backgroundColor: 'gray.750',
        },
        _hover: {
          backgroundColor: 'gray.750',
        },
        '&:-webkit-autofill': {
          boxShadow: `0 0 0px 1000px ${theme.colors.gray[476]} inset`,
          borderColor: 'gray.476',
          backgroundClip: 'text',
          textFillColor: 'white',
          _hover: {
            boxShadow: `0 0 0px 1000px ${theme.colors.gray[476]} inset`,
            borderColor: 'gray.476',
          },
          _focus: {
            borderColor: 'blue.300',
          },
        },
      },
      color: 'white',
    }),
  },
  defaultProps: {},
};
