import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/database';
import { reduxFirestore } from 'redux-firestore';

import { applyMiddleware, compose, createStore } from 'redux';
import logger from 'redux-logger';
import thunk from 'redux-thunk';

import rootReducer from 'javascript/reducers/index.js';
import { ENVIRONMENT } from '../types/constants';
import { envConfig } from '../config';
import { firebaseConfig } from '../config/firebaseConfig';
import { routeBuilderApis } from 'features/routeBuilder/store/services/routeBuilderApis';

// Initialize firebase instance
firebase.initializeApp(firebaseConfig);
// Initialize Cloud Firestore through Firebase
firebase.firestore().enablePersistence({
  synchronizeTabs: true,
});

const createStoreWithFirebase = compose(
  reduxFirestore(firebase, {}) // firebase instance as first argument, rfConfig as optional second
)(createStore);

const getMiddlewares = () => {
  // Middlewares for api calls using RTK
  const apiMilddlewares = [routeBuilderApis.middleware];

  if (envConfig.environment === ENVIRONMENT.LOCAL) {
    return [thunk, logger, ...apiMilddlewares];
  }

  return [thunk, ...apiMilddlewares];
};

const middleWares = [...getMiddlewares()];

let composeVar;
if (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
  composeVar = compose(
    applyMiddleware(...middleWares),
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__()
  );
} else {
  composeVar = compose(applyMiddleware(...middleWares));
}

const initialState = {};

const store = createStoreWithFirebase(rootReducer, initialState, composeVar);

export { store };
