import { forwardRef } from '@chakra-ui/react';
import { useMemo } from 'react';

import { ChakraRouterLink } from 'components/ChakraRouterLink';
import { useNavLink } from 'hooks';

import { TopNavbarItem, type TopNavbarItemProps } from './TopNavbarItem';

export type TopNavbarItemRouterLinkProps = Omit<
  TopNavbarItemProps<ChakraRouterLink>,
  'variant' | 'href'
> & {
  isActive?: boolean;
  matchExact?: boolean;
  matchCaseSensitive?: boolean;
};

const TopNavbarItemRouterLinkInner: React.ForwardRefRenderFunction<
  HTMLDivElement,
  TopNavbarItemRouterLinkProps
> = ({ isActive: isActiveProp, matchCaseSensitive = false, matchExact = false, ...props }, ref) => {
  const isRouteMatch = useNavLink({
    to: props.to,
    caseSensitive: matchCaseSensitive,
    end: matchExact,
  });

  const isActive = useMemo(() => isActiveProp ?? isRouteMatch, [isActiveProp, isRouteMatch]);

  return (
    <TopNavbarItem
      ref={ref}
      as={ChakraRouterLink}
      variant={isActive ? 'active' : 'default'}
      {...props}
    />
  );
};

TopNavbarItemRouterLinkInner.displayName = 'TopNavbarItemRouterLink';

export const TopNavbarItemRouterLink = forwardRef(TopNavbarItemRouterLinkInner);

export type TopNavbarItemRouterLink = typeof TopNavbarItemRouterLink;
