/**
 * DEPRECATED: Please consult with the FE Performance team before using this file.
 */
// format shift with bol errors for shift
export const formatBOLErrorsForShift = (shift) => {
  const bolErrors = shift?.bolErrors?.reduce((acc, curr) => {
    const existingOrder = acc.find((order) => order.orderGroupId === curr.orderGroupId);
    if (existingOrder) {
      existingOrder.volume += curr.volume;
    } else {
      acc.push({ orderGroupId: curr.orderGroupId, volume: curr.volume });
    }
    return acc;
  }, []);

  // get array of shiftStartProducts and sum volume for each product
  const shiftStartProducts = (shift?.shiftCompartmentInstances || []).reduce((acc, curr) => {
    const product = curr.shiftStartInventory.product;
    if (product) {
      const existingProduct = acc.find((eProduct) => eProduct.productId === product.id);
      if (existingProduct) {
        existingProduct.volume += curr.shiftStartInventory.volume;
      } else {
        acc.push({
          productId: product.id,
          productShortName: product.shortName,
          volume: curr.shiftStartInventory.volume,
        });
      }
    }
    return acc;
  }, []);

  // get array of bolRetainProducts and sum volume for each product
  const bolRetainProducts = shift?.bolRetains?.reduce((acc, curr) => {
    const existingProduct = acc.find((eProduct) => eProduct.productId === curr.product.id);

    if (existingProduct) {
      existingProduct.volume += curr.volume;
    } else {
      acc.push({
        productId: curr.product.id,
        productShortName: curr.product.shortName,
        volume: curr.volume,
      });
    }
    return acc;
  }, []);

  // get array of bolErrors and sum volume for each order
  const shiftStartVolumeError = shiftStartProducts?.filter((shiftStartProduct) => {
    const bolProduct = bolRetainProducts.find(
      (bolRetainProduct) => bolRetainProduct.productId === shiftStartProduct.productId
    );
    return !(bolProduct && bolProduct.volume === shiftStartProduct.volume);
  });

  return { id: shift.id, bolErrors, shiftStartVolumeError };
};
