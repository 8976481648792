/* eslint-disable */

import * as actions from '../actions';

import { fetchGraphQLData } from '../../../graphql';
import Helpers from '../../../helpers';
import { ORDER_GROUP_FRAGMENT } from 'javascript/schemas/fragments';
import { handleErrors } from 'error/handleErrors';

/**
 * List minimal information of shifts for listing
 *
 * @returns {function}
 */
export const fetchOrderGroups =
  (filter = {}) =>
  async (dispatch) => {
    try {
      dispatch({
        type: actions.FETCHING_ORDER_GROUPS,
        payload: true,
      });

      const QUERY = `
    {
      orderGroups(filter: ${JSON.stringify(filter).replace(/"([^(")"]+)":/g, '$1:')}) {
        ${ORDER_GROUP_FRAGMENT}
      }
    }
    `;
      const response = await fetchGraphQLData(JSON.stringify({ query: QUERY }));
      if (!response?.data?.orderGroups) {
        console.error('fetchOrderGroups', response);
        throw new Error(`Error fetching order groups with filter ${JSON.stringify(filter)}`);
      }
      const fetchedOrderGroups = response.data?.orderGroups || [];

      dispatch({
        type: actions.FETCH_ORDER_GROUPS,
        payload: Helpers.convertArrayToHash(fetchedOrderGroups, 'orderGroups'),
      });

      dispatch({
        type: actions.FETCHING_ORDER_GROUPS,
        payload: false,
      });
    } catch (error) {
      handleErrors(error);
    }
  };

export const setRecordFilter =
  (filter = {}) =>
  async (dispatch) => {
    dispatch({
      type: actions.RECORD_FILTER,
      payload: filter,
    });
  };

export const fetchDeliveryOrders =
  (filter = {}) =>
  async (dispatch) => {
    try {
      dispatch({
        type: actions.FETCHING_DELIVERY_ORDERS,
        payload: true,
      });
      const QUERY = `
      {
      orderGroups {
        id
        carrierNumber
        poNumber
        startOnTimestamp
        status
        fees {
          id
          code
          price
          description
          name
        }
        deliveryOrders {
          id
          status
          orderNumber
          deliveryInstruction
          customerBranch {
            id
            name
            customer {
              fees {
                id
                name
                price
              }
            }
          }
          tasks {
            id
            status
            driverAcceptance
            startOnTimestamp
            completeOnTimestamp
            startedOnTimestamp
            completedOnTimestamp
            driver {
              name
            }
          }
          lineItems {
            id
            source
            requestedVolume
            volume
            status
            subscription {
              product {
                category
                name
              }
              asset {
                category
                licensePlateNumber
              }
            }
          }
        }
      }
    }
    `;
      const response = await fetchGraphQLData(JSON.stringify({ query: QUERY }));
      if (!response?.data?.orderGroups) {
        console.error('fetchDeliveryOrders', response);
        throw new Error(`Error fetching delivery orders with filter ${JSON.stringify(filter)}`);
      }
      const fetchedDeliveryOrders = response.data?.orderGroups || [];

      dispatch({
        type: actions.FETCH_DELIVERY_ORDERS,
        payload: Helpers.convertArrayToHash(fetchedDeliveryOrders, 'orderGroups'),
      });

      dispatch({
        type: actions.FETCHING_DELIVERY_ORDERS,
        payload: false,
      });
    } catch (error) {
      handleErrors(error);
    }
  };

export const fetchLoadingOrders =
  (filter = {}) =>
  async (dispatch) => {
    try {
      dispatch({
        type: actions.FETCHING_LOADING_ORDERS,
        payload: true,
      });

      const QUERY = `
        {
          orderGroups {
            id
            carrierNumber
            poNumber
            startOnTimestamp
            status
            loadingOrders {
              id
              status
              terminal {
                id
                name
                site {
                  address
                }
              }
              task {
                id
                status
                driverAcceptance
                startOnTimestamp
                completeOnTimestamp
                startedOnTimestamp
                completedOnTimestamp
                customerBranch {
                  id
                  name
                }
                driver {
                  name
                }
              }
              loadingOrderProducts {
                id
                grossQuantity
                product {
                  name
                  code
                }
                preferredSuppliers {
                  priority
                  loadingAccount {
                    loadingNumber
                    supplier {
                      name
                      erpId
                      supplierId

                    }
                  }
                }
                loadingOrderLineItems {
                  id
                  netAmount
                  compartment {
                    id
                    name
                  }
                  compartmentId
                  productId
                }
              }
              loadingReceipts {
                id
                loadingReceiptProducts {
                  id
                  grossVolume
                }
              }
              demurrageNotes {
                content
                commenter
              }
            }
          }
        }
    `;
      const response = await fetchGraphQLData(JSON.stringify({ query: QUERY }));
      if (!response?.data?.orderGroups) {
        console.error('fetchLoadingOrders', response);
        throw new Error(`Error fetching loading groups with filter ${JSON.stringify(filter)}`);
      }
      const fetchedLoadOrders = response.data?.orderGroups || [];

      dispatch({
        type: actions.FETCH_LOADING_ORDERS,
        payload: Helpers.convertArrayToHash(fetchedLoadOrders, 'orderGroups'),
      });

      dispatch({
        type: actions.FETCHING_LOADING_ORDERS,
        payload: false,
      });
    } catch (error) {
      handleErrors(error);
    }
  };

export const fetchCustomers =
  (filter = {}) =>
  async (dispatch) => {
    try {
      dispatch({
        type: actions.FETCHING_CUSTOMERS,
        payload: true,
      });

      const QUERY = `
        {
          customers {
            id
            name
            erpId
            address,
            customerBranches {
              id
              name
              erpId
              address
              site {
                id
                lat
                lng
                radius
              }
            }
          }
        }
    `;
      const response = await fetchGraphQLData(JSON.stringify({ query: QUERY }));
      if (!response?.data?.orderGroups) {
        console.error('fetchCustomers', response);
        throw new Error('Error fetching customers');
      }
      const filteredResponse = response.data?.customers || [];

      dispatch({
        type: actions.FETCH_CUSTOMERS,
        payload: Helpers.convertArrayToHash(filteredResponse),
      });
      dispatch({
        type: actions.FETCHING_CUSTOMERS,
        payload: false,
      });
    } catch (error) {
      handleErrors(error);
    }
  };

export const fetchSingleCustomer = (id) => async (dispatch) => {
  try {
    dispatch({
      type: actions.FETCHING_SINGLE_CUSTOMER,
      payload: true,
    });

    const QUERY = `
        {
          customer(id: ${Number(id)}) {
            id
            name
            erpId
            address
            netsuiteId
            netsuiteName
            preferredDrivers {
              id
              name
            }
            fees {
              id
              name
              price
            }
            addressBooks {
              id
              name
              email
              phone
              address
              note
              jobTitle
              isDeliveryTicketRecipient
              image {
                url
              }
            }
          }
        }
    `;
    const response = await fetchGraphQLData(JSON.stringify({ query: QUERY }));
    if (!response?.data?.customer) {
      console.error('fetchSingleCustomer', response);
      throw new Error(`Error fetching customers with id: ${id}`);
    }
    const filteredResponse = response.data?.customer || {};
    const filteredFees = filteredResponse?.fees?.reduce(
      (prev, curr) => ({ ...prev, [curr.id]: curr }),
      {}
    );
    filteredResponse.fees = Object.values(filteredFees || {});

    dispatch({
      type: actions.FETCH_SINGLE_CUSTOMER,
      payload: filteredResponse,
    });
    dispatch({
      type: actions.FETCHING_SINGLE_CUSTOMER,
      payload: false,
    });
  } catch (error) {
    handleErrors(error);
  }
};

export const fetchCustomerBranches = (id) => async (dispatch) => {
  try {
    dispatch({
      type: actions.FETCHING_BRANCH,
      payload: true,
    });

    const QUERY = `
        {
          customerBranch(id: ${Number(id)}) {
            id
            name
            erpId
            address
            site {
              id
              lat
              lng
              radius
            }
          }
        }
    `;
    const response = await fetchGraphQLData(JSON.stringify({ query: QUERY }));
    if (!response?.data?.customerBranch) {
      console.error('fetchCustomerBranches', response);
      throw new Error(`Error fetching customer branch with id: ${id}`);
    }
    const filteredResponse = response.data?.customerBranch || [];

    dispatch({
      type: actions.FETCH_BRANCH,
      payload: Helpers.convertArrayToHash(filteredResponse),
    });
    dispatch({
      type: actions.FETCHING_BRANCH,
      payload: false,
    });
  } catch (error) {
    handleErrors(error);
  }
};

export const setCustomerBranches = (payload) => async (dispatch) => {
  dispatch({
    type: actions.SET_CUSTOMER_BRANCHES,
    payload: payload,
  });
};

export const setSingleCustomer = (payload) => async (dispatch) => {
  dispatch({
    type: actions.SET_SINGLE_CUSTOMER,
    payload: payload,
  });
};

export const setRecordSearchText = (payload) => (dispatch) => {
  dispatch({
    type: actions.SET_RECORD_SEARCH_TEXT,
    payload: payload,
  });
};

export const setRecordSearchTextChanged = (payload) => (dispatch) => {
  dispatch({
    type: actions.SET_RECORD_SEARCH_TEXT_CHANGED,
    payload: payload,
  });
};

export const setRecordTab = (payload) => (dispatch) => {
  dispatch({
    type: actions.SET_RECORD_TAB,
    payload: payload,
  });
};

export const openAssetDetail = (payload) => (dispatch) => {
  dispatch({
    type: actions.OPEN_ASSET_DETAIL,
    payload: payload,
  });
};

export const openDriverDetail = (payload) => (dispatch) => {
  dispatch({
    type: actions.OPEN_DRIVER_DETAIL,
    payload: payload,
  });
};

export const fetchAssets = (id) => async (dispatch) => {
  try {
    dispatch({
      type: actions.FETCHING_ASSETS,
      payload: true,
    });

    const QUERY = `
      {
        customer(id: ${Number(id)}) {
          id
          assets {
            id
            qrCodeText
            name
            color
            licensePlateNumber
            category
            subscriptions{
              product{
                name
                erpId
                productType
              }
            }
            customerBranch {
              name
            }
            status
          }
        }
      }
    `;
    const response = await fetchGraphQLData(JSON.stringify({ query: QUERY }));
    if (!response?.data?.customer?.assets) {
      console.error('fetchAssets', response);
      throw new Error(`Error fetching assets for customer with id: ${id}`);
    }
    const filteredResponse = response.data?.customer?.assets || [];

    dispatch({
      type: actions.FETCH_ASSETS,
      payload: filteredResponse,
    });

    dispatch({
      type: actions.FETCHING_ASSETS,
      payload: false,
    });
  } catch (error) {
    handleErrors(error);
  }
};

export const fetchUsers = (id) => async (dispatch) => {
  try {
    dispatch({
      type: actions.FETCHING_USERS,
      payload: true,
    });

    const QUERY = `
      {
        customer(id: ${Number(id)}) {
          id
          users {
            id
            name
            phone
            email
            address
            roles
            status
          }
        }
      }
    `;
    const response = await fetchGraphQLData(JSON.stringify({ query: QUERY }));
    if (!response?.data?.customer?.users) {
      console.error('fetchUsers', response);
      throw new Error(`Error fetching user for customer with id: ${id}`);
    }
    const filteredResponse = response.data?.customer?.users || [];

    dispatch({
      type: actions.FETCH_USERS,
      payload: filteredResponse,
    });

    dispatch({
      type: actions.FETCHING_USERS,
      payload: false,
    });
  } catch (error) {
    handleErrors(error);
  }
};

export const fetchShiptos = (id) => async (dispatch) => {
  try {
    dispatch({
      type: actions.FETCHING_SHIPTOS,
      payload: true,
    });

    const QUERY = `
      {
        customer(id: ${Number(id)}) {
          id
          customerBranches {
            id
            name
            erpId
            address
            site {
              id
              lat
              lng
              radius
            }
          }
        }
      }
    `;
    const response = await fetchGraphQLData(JSON.stringify({ query: QUERY }));
    if (!response?.data?.customer?.customerBranches) {
      console.error('fetchShiptos', response);
      throw new Error(`Error fetching customer branch for customer with id: ${id}`);
    }
    const filteredResponse = response.data?.customer?.customerBranches || [];

    dispatch({
      type: actions.FETCH_SHIPTOS,
      payload: filteredResponse,
    });

    dispatch({
      type: actions.FETCHING_SHIPTOS,
      payload: false,
    });
  } catch (error) {
    handleErrors(error);
  }
};
