import { chakra } from '@chakra-ui/react';

import { TopNavbarDropdownItem } from './TopNavbarDropdownItem';
import { TopNavbarItemLinkProps } from './TopNavbarItemLink';

export const TopNavbarDropdownItemLink: React.FC<TopNavbarItemLinkProps> = ({
  isActive,
  ...props
}) => <TopNavbarDropdownItem as={chakra.a} variant={isActive ? 'active' : 'default'} {...props} />;

export type TopNavbarDropdownItemLink = typeof TopNavbarDropdownItemLink;
