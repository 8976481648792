import { Route, Routes } from 'react-router-dom';

import { lazyImport } from 'utils/lazyImport';

const { LoginPage } = lazyImport(() => import('../pages/LoginPage'), 'LoginPage');
const { TwoFactorAuthenticationPage } = lazyImport(
  () => import('../pages/TwoFactorAuthenticationPage'),
  'TwoFactorAuthenticationPage'
);
const { OtpPage } = lazyImport(() => import('../pages/OtpPage'), 'OtpPage');
const { RecoveryCodePage } = lazyImport(
  () => import('../pages/RecoveryCodePage'),
  'RecoveryCodePage'
);

export const AuthRoutes = () => {
  return (
    <Routes>
      <Route path="two_factor_authentication" element={<TwoFactorAuthenticationPage />} />
      <Route path="sign_in/otp" element={<OtpPage />} />
      <Route path="sign_in/recovery_code" element={<RecoveryCodePage />} />
      <Route path="*" element={<LoginPage />} />
    </Routes>
  );
};
