import { chakra, forwardRef } from '@chakra-ui/react';

import { TopNavbarItem, type TopNavbarItemProps } from './TopNavbarItem';

export type TopNavbarItemButtonProps = TopNavbarItemProps<'button'>;

const TopNavbarItemButtonInner: React.ForwardRefRenderFunction<
  HTMLDivElement,
  TopNavbarItemButtonProps
> = (props, ref) => <TopNavbarItem ref={ref} as={chakra.button} {...props} />;

TopNavbarItemButtonInner.displayName = 'TopNavbarItemButton';

export const TopNavbarItemButton = forwardRef(TopNavbarItemButtonInner);

export type TopNavbarItemButton = typeof TopNavbarItemButton;
