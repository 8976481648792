/**
 * DEPRECATED: Please consult with the FE Performance team before using this file.
 */
import {
  SET_FS_APP_RELEASES,
  SET_FS_SETTINGS,
  SET_FS_SYNC_DATA,
  SET_FS_WORKERS,
} from '../actions/types';

const initialState = {
  appReleases: {},
  settings: {},
  syncData: {},
  workers: [],
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case SET_FS_APP_RELEASES:
      return {
        ...state,
        appReleases: Object.assign({}, payload),
      };
    case SET_FS_SETTINGS:
      return {
        ...state,
        settings: payload,
      };
    case SET_FS_SYNC_DATA:
      return {
        ...state,
        syncData: Object.assign({}, payload),
      };
    case SET_FS_WORKERS:
      return {
        ...state,
        workers: [...payload],
      };
    default:
      return state;
  }
}
