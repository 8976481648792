export const columnDefs = [
  { headerName: 'Order Type', field: 'orderType', hide: true },
  { headerName: 'Location', field: 'destinationName', hide: true },
  { headerName: 'Customer', field: 'destinationName' },
  { headerName: 'Driver', field: 'driverName' },
  {
    headerName: 'Arrived At',
    field: 'fenceInTimestamp',
  },
  {
    headerName: 'Departed At',
    field: 'fenceOutTimestamp',
  },
  {
    headerName: 'Time Spent',
    field: 'timeSpent',
  },
];

export const defaultColDef = {
  flex: 1,
  minWidth: 100,
  sortable: true,
  filter: true,
  enableRowGroup: true,
};

export const columnKeys = [
  'destinationName',
  'driverName',
  'fenceInTimestamp',
  'fenceOutTimestamp',
  'timeSpent',
];
