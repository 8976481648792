import { ComponentMultiStyleConfig } from '@chakra-ui/react';

export const theme = {
  TopNavbar: {
    parts: [
      'container',
      'logo',
      'item',
      'item-wrapper',
      'dropdown-button',
      'dropdown-button-label',
      'dropdown-button-icon',
      'dropdown-list',
      'dropdown-item',
    ],
    baseStyle: {
      container: {
        bgColor: 'gray.700',
        h: '3.375rem',
        maxW: 'full',
        px: '1.5',
        // position: 'fixed',
        // w: 'full',
        // zIndex: 100,
      },
      logo: {
        h: 'full',
        p: '2',
        // zIndex: 100,
      },
      item: {
        alignItems: 'center',
        borderRadius: 'md',
        display: 'flex',
        h: '2.75rem',
        justifyContent: 'center',
        letterSpacing: '0.025rem',
        lineHeight: '1',
        px: '3',
        w: 'full',
        whiteSpace: 'nowrap',
        _hover: {
          textDecoration: 'none',
          bgColor: 'gray.550',
        },
        color: 'inherit !important',
      },
      'item-wrapper': {
        borderTopRadius: 'md',
        py: '0.5',
        w: 'full',
      },
      'item-icon': {
        alignItems: 'center',
        display: 'flex',
        fontSize: 'lg',
        lineHeight: '1',
        p: '1.5',
        _hover: {
          textDecoration: 'none',
          color: 'inherit',
        },
      },
      'dropdown-button-label': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      'dropdown-button-icon': {
        ml: '2',
        fontSize: 'xxs',
        'button:hover &': {
          // textColor: 'orange.500',
        },
      },
      'dropdown-list': {
        backgroundColor: 'gray.550',
        border: 'none',
        borderTopLeftRadius: '0',
        boxShadow: '6px 6px 6px rgb(0 0 0 / 25%)',
        minWidth: 'calc(max(185px, 100% + 24px))',
        padding: '12px',
      },
      'dropdown-item': {
        borderRadius: 'md',
        display: 'flex',
        justifyContent: 'space-between',
        lineHeight: '1',
        whiteSpace: 'nowrap',
        p: '3',
        w: 'full',
        color: 'inherit',
        _hover: {
          backgroundColor: 'gray.800',
          textDecoration: 'none',
          color: 'gray.53',
          bgColor: 'gray.380',
        },
      },
    },
    variants: {
      active: {
        item: {
          _hover: {
            bgColor: 'transparent',
          },
        },
        'item-wrapper': {
          bgColor: 'gray.800',
        },
        'dropdown-item': {
          backgroundColor: 'gray.800',
        },
      },
      open: {
        'item-wrapper': {
          bgColor: 'gray.550',
          boxShadow: '6px 6px 6px rgb(0 0 0 / 25%)',
        },
        'dropdown-button-icon': {
          // textColor: 'orange.500',
        },
      },
    },
  } as ComponentMultiStyleConfig,
};
