import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  showRouteSummaryView: false,
};

const routeSummarySlice = createSlice({
  name: 'routeSummary',
  initialState: initialState,
  reducers: {
    setShowRouteSummaryView(state, action) {
      state.showRouteSummaryView = action.payload;
    },
  },
});

export const { setShowRouteSummaryView } = routeSummarySlice.actions;

// eslint-disable-next-line import/no-default-export
export default routeSummarySlice.reducer;
