import { Box } from '@chakra-ui/react';

import { TopNavbarDropdownItemLink } from 'components/TopNavbar/components/TopNavbarDropdownItemLink';

export interface betaTagPropsType {
  redirectUrl: string;
  elemTitle: string;
  isSlanted?: boolean;
}
export const BetaTag = (props: betaTagPropsType) => {
  const { redirectUrl, elemTitle, isSlanted = false } = props;

  return (
    <TopNavbarDropdownItemLink
      href={redirectUrl}
      display={'flex'}
      gap={1}
      alignItems={'center'}
      justifyContent={'space-between'}
      width={'100%'}
    >
      {elemTitle}
      <Box
        bg={'red.500'}
        fontSize={'sm'}
        borderRadius={isSlanted ? '0px' : '4px'}
        padding={'3px 8px'}
        transform={isSlanted ? 'rotate(45deg)' : 'none'}
      >
        BETA
      </Box>
    </TopNavbarDropdownItemLink>
  );
};
