import { Flex } from '@chakra-ui/react';

import { FAIcon } from 'components/FAIcon';
import { usePagination } from 'hooks';

import { PageButton } from './PageButton';

export type PaginationProps = {
  pageCount?: number;
  currentPage?: number;
  maxRange?: number;
  onPageChange?: (page: number) => void;
  btnSize?: 'regular' | 'small';
};

export const Pagination: React.FC<PaginationProps> = ({
  pageCount,
  currentPage = 1,
  onPageChange,
  maxRange,
  btnSize = 'regular',
}) => {
  const pages = usePagination({ pageCount, currentPage, maxRange });

  return (
    <Flex columnGap={'2.5'}>
      <PageButton
        btnSize={btnSize}
        disabled={!!pageCount && currentPage === 1}
        onClick={() => onPageChange?.(currentPage - 1)}
        variant={'bordered'}
        aria-label={'Previous page'}
      >
        <FAIcon icon="angle-left" role="img" />
      </PageButton>
      {pages.map(({ index, number, type, label, isCurrent }) => (
        <PageButton
          btnSize={btnSize}
          key={index}
          variant={isCurrent ? 'active' : 'default'}
          onClick={() => number && onPageChange?.(number)}
        >
          {type === 'ellipsis' ? <FAIcon icon="ellipsis-h" /> : label}
        </PageButton>
      ))}
      <PageButton
        btnSize={btnSize}
        disabled={!!pageCount && currentPage === pageCount}
        onClick={() => onPageChange?.(currentPage + 1)}
        variant={'bordered'}
        aria-label={'Next page'}
      >
        <FAIcon icon="angle-right" role="img" />
      </PageButton>
    </Flex>
  );
};

export type Pagination = typeof Pagination;
