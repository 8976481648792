import Cookies from 'js-cookie';
const DOMAIN = process.env.REACT_APP_DOMAIN;

const COOKIES_FIELDS = [
  'environment',
  'pusher_api_key',
  'firebase_api_key',
  'firebase_auth_domain',
  'firebase_database_url',
  'firebase_project_id',
  'firebase_storage_bucket',
  'firebase_messaging_sender_id',
  'firebase_app_id',
  'app_version',
  'name',
  'phone',
  'tenant_slug',
  'tenant_name',
  'current_customer_id',
  'lat',
  'long',
  'pusher_channel_name',
  'current_user_id',
  'tenant-timezone',
  'fp_access_token',
];

export const removeCookies = () => {
  COOKIES_FIELDS.forEach((indvCookie) => {
    Cookies.remove(indvCookie, {
      domain: DOMAIN,
    });
  });

  sessionStorage.removeItem('current_customer_id');
};
