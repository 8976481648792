import { chakra, forwardRef, HTMLChakraProps } from '@chakra-ui/react';

const TopNavbarDropdownDividerInner: React.ForwardRefRenderFunction<
  HTMLHRElement,
  HTMLChakraProps<'hr'>
> = (props, ref) => {
  return (
    <chakra.hr
      ref={ref}
      __css={{
        borderColor: 'rgba(149,159,174)',
        height: 'px',
        margin: '3',
        opacity: '0.5',
      }}
      {...props}
    />
  );
};

TopNavbarDropdownDividerInner.displayName = 'TopNavbarDropdownDivider';

export const TopNavbarDropdownDivider = forwardRef(TopNavbarDropdownDividerInner);

export type TopNavbarDropdownDivider = typeof TopNavbarDropdownDivider;
