import { Box } from '@chakra-ui/layout';

type LoadingComponentProps = {
  message?: string;
};

export const LoadingComponent = ({
  message = 'Please wait while data are loading...',
}: LoadingComponentProps) => {
  return (
    <Box as={'span'} className={'ag-overlay-loading-center'}>
      {message}
    </Box>
  );
};
