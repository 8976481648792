import { AnyObject } from 'yup';

/** Extension of Error Class for the errors that are generated with graphql api */
class GraphApiErr extends Error {
  code: number;
  isGraphApiErr: boolean;
  args: AnyObject | undefined;

  constructor(err: { code: number; message: string }, additionalInfo?: AnyObject) {
    super(err.message);

    this.code = err.code;
    this.name = 'GraphApiErr';
    this.message = err.message;
    this.isGraphApiErr = true;
    this.args = additionalInfo;
  }
}

export { GraphApiErr };
