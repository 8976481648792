import mixpanel from 'mixpanel-browser';

const prodEnv = process.env.NODE_ENV === 'production';
const mixpanelToken = process.env.REACT_APP_MIXPANEL_TOKEN;
const mixpanelOptions = prodEnv ? { ignore_dnt: true } : { debug: true, ignore_dnt: true };

const mixpanelReady = !!mixpanelToken;

if (mixpanelReady) mixpanel.init(mixpanelToken, mixpanelOptions);

const actions = {
  track: (name, props) => {
    if (mixpanelReady) mixpanel.track(name, props);
  },
  time: (name) => {
    if (mixpanelReady) mixpanel.time_event(name);
  },
  identify: (id) => {
    if (mixpanelReady) mixpanel.identify(id);
  },
  reset: () => {
    if (mixpanelReady) mixpanel.reset();
  },
  register: (props) => {
    if (mixpanelReady) mixpanel.register(props);
  },
  peopleSetOnece: (props) => {
    if (mixpanelReady) mixpanel.people.set_once(props);
  },
};

export const Mixpanel = actions;
