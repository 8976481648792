import * as workers from '../workers';

export const FETCH_ORDER_GROUPS = '@orders/FETCH_ORDER_GROUPS';
export const FETCHING_ORDER_GROUPS = '@orders/FETCHING_ORDER_GROUPS';

export const FETCH_DELIVERY_ORDERS = '@orders/FETCH_DELIVERY_ORDERS';
export const FETCHING_DELIVERY_ORDERS = '@orders/FETCHING_DELIVERY_ORDERS';

export const FETCH_LOADING_ORDERS = '@orders/FETCH_LOADING_ORDERS';
export const FETCHING_LOADING_ORDERS = '@orders/FETCHING_LOADING_ORDERS';

export const FETCH_CUSTOMERS = '@orders/FETCH_CUSTOMERS';
export const FETCHING_CUSTOMERS = '@orders/FETCHING_CUSTOMERS';

export const FETCH_DRIVERS = '@orders/FETCH_DRIVERS';
export const FETCHING_DRIVERS = '@orders/FETCHING_DRIVERS';

export const CREATE_NEW_RECURRENCE = '@orders/CREATE_NEW_RECURRENCE';
export const CREATING_NEW_RECURRENCE = '@orders/CREATING_NEW_RECURRENCE';

export const UPDATE_RECURRENCE = '@orders/UPDATE_RECURRENCE';
export const UPDATING_RECURRENCE = '@orders/UPDATING_RECURRENCE';

export const FETCH_RECURRENCE = '@orders/FETCH_RECURRENCE';

export const FETCH_RECURRENCE_ORDERS = '@orders/FETCH_RECURRENCE_ORDERS';
export const FETCHING_RECURRENCE_ORDERS = '@orders/FETCHING_RECURRENCE_ORDERS';
export const FETCH_FILTERED_RECURRENCE_ORDERS = '@orders/FETCH_FILTERED_RECURRENCE_ORDERS';

export const DELETED_RECURRENCE_ORDERS = '@orders/DELETED_RECURRENCE_ORDERS';
export const DELETING_RECURRENCE_ORDERS = '@orders/DELETING_RECURRENCE_ORDERS';

export const SET_TIMESTAMPS = '@shifts/SET_TIMESTAMPS';

export const OPEN_TRANSFER_ORDER_DETAIL = '@orders/OPEN_TRANSFER_ORDER_DETAIL';

export const ORDER_DATE_CHANGE = '@orders/ORDER_DATE_CHANGE';
export const REFETCH_ORDER_DATA = '@orders/REFETCH_ORDER_DATA';
export const REFETCH_TRANSFER_ORDERS = '@orders/REFETCH_TRANSFER_ORDERS';

export const refetchOrderData = (data) => workers.refetchOrderData(data);

export const fetchOrderGroups = (filter) => workers.fetchOrderGroups(filter);
export const fetchFilteredRecurrenceOrders = (...filter) =>
  workers.fetchFilteredRecurrenceOrders(...filter);
export const openTransferOrder = (data) => workers.openTransferOrder(data);
export const refetchTransferOrders = (data) => workers.refetchTransferOrders(data);
export const orderDateChange = (data) => workers.orderDateChange(data);

export const fetchDeliveryOrders = (filter) => workers.fetchDeliveryOrders(filter);
export const fetchLoadingOrders = (filter) => workers.fetchLoadingOrders(filter);
export const fetchCustomers = (filter) => workers.fetchCustomers(filter);
export const fetchDrivers = (filter) => workers.fetchDrivers(filter);

export const createNewRecurrenceOrder = (attributes, successCb, errorCb) =>
  workers.createNewRecurrenceOrder(attributes, successCb, errorCb);
export const updateRecurrenceOrder = (attributes, successCb, errorCb) =>
  workers.updateRecurrenceOrder(attributes, successCb, errorCb);
export const fetchRecurrenceOrders = (filter) => workers.fetchRecurrenceOrders(filter);
export const deleteRecurrenceOrders = (deliveryOrderIds, successCb, errorCb) =>
  workers.deleteRecurringOrders(deliveryOrderIds, successCb, errorCb);
export const setCurrentTimestamps = (from, to) => workers.setCurrentTimestamps(from, to);
