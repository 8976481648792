import { createSlice } from '@reduxjs/toolkit';

import { RoutesTableRowDataType } from '../types/routesTable';

// fix this type later
export type RoutesTableSliceReducerType = {
  dateFilters: {
    startDate: string;
    endDate: string;
  };
  activeRoutesFilter: boolean;
  routesTableSelectedRoutes: RoutesTableRowDataType[];
  showRouteDetail: string | null;
  updateRouteDetail: string | null;
  routesTableSearchKeyword: string;
  refetchRoutesList: boolean;
};

const initialState: RoutesTableSliceReducerType = {
  dateFilters: {
    startDate: '',
    endDate: '',
  },
  activeRoutesFilter: false,
  routesTableSelectedRoutes: [],
  showRouteDetail: null,
  updateRouteDetail: null,
  routesTableSearchKeyword: '',
  refetchRoutesList: false,
};

const routesTableSlice = createSlice({
  name: 'routesTable',
  initialState,
  reducers: {
    setDateFilters(state, action) {
      state.dateFilters.startDate = action.payload.startDate;
      state.dateFilters.endDate = action.payload.endDate;
    },
    setActiveRoutesFilter(state, action) {
      state.activeRoutesFilter = action.payload;
    },
    setRoutesTableSelectedRoutes(state, action) {
      state.routesTableSelectedRoutes = action.payload;
    },
    setShowRouteDetail(state, action) {
      state.showRouteDetail = action.payload;
    },
    setUpdateRouteDetail(state, action) {
      state.updateRouteDetail = action.payload;
    },
    setRoutesTableSearchKeyword(state, action) {
      state.routesTableSearchKeyword = action.payload;
    },
    setRefetchRoutesList(state, action) {
      state.refetchRoutesList = action.payload;
    },
  },
});

export const {
  setDateFilters,
  setActiveRoutesFilter,
  setRoutesTableSelectedRoutes,
  setShowRouteDetail,
  setUpdateRouteDetail,
  setRoutesTableSearchKeyword,
  setRefetchRoutesList,
} = routesTableSlice.actions;

// eslint-disable-next-line import/no-default-export
export default routesTableSlice.reducer;
