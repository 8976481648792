import { DriverSubRouteConfig } from 'features/drivers/routes/routeConfig';
import { RouteConfig } from 'types/RouteConfig';

export const AppRouteConfig: Record<string, RouteConfig> = {
  DRIVERS_TIME_SPENT: {
    key: 'drivers',
    path: 'drivers/*',
    subRoutes: DriverSubRouteConfig,
  },
};
