import { createSlice } from '@reduxjs/toolkit';

import { OrdersTableRowDataType } from '../types/ordersTable';

export type AssignOrdersToRouteReducerType = {
  assignOrdersToRouteView: boolean;
  ordersToAssignToRoute: OrdersTableRowDataType[];
};

const initialState: AssignOrdersToRouteReducerType = {
  assignOrdersToRouteView: false,
  ordersToAssignToRoute: [],
};

const assignOrdersToRouteSlice = createSlice({
  name: 'assignOrdersToRoute',
  initialState: initialState,
  reducers: {
    setAssignOrdersToRouteView(state, action) {
      state.assignOrdersToRouteView = action.payload;
    },
    setOrdersToAssignToRoute(state, action) {
      state.ordersToAssignToRoute = action.payload;
    },
  },
});

export const { setAssignOrdersToRouteView, setOrdersToAssignToRoute } =
  assignOrdersToRouteSlice.actions;

// eslint-disable-next-line import/no-default-export
export default assignOrdersToRouteSlice.reducer;
