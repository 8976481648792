/**
 * DEPRECATED: Please consult with the FE Performance team before using this file.
 */
export default `
  id
  startOnTimestamp
  endOnTimestamp
  state
  errors
  enableClearingAccountingData
  worker {
    id
    name
    status
    hubs {
      name
    }
  }
  shiftDeliveryUnitsAssets {
    id
    deliveryAsset {
      id
      licensePlateNumber
      name
      category
    }
  }
  orderGroups {
    id
    ccTenant{
      id
      name
    }
    ccTenantId
    hub {
      id
      name
    }
    carrierNumber
    poNumber
    lineOfService {
      id
      name
      shortName
    }
    loadingOrder {
      id
      terminal {
        id
        name
      }
      loadingReceipts {
        id
        receiptNumber
        loadingAccount {
          carrierCustomerCode
        }
      }
    }
    deliveryOrder {
      id
      customerBranch {
        id
        name
      }
    }
  }
`;
