import { DateTime } from 'luxon';

import { DATE_FORMAT } from 'globalConstants';

const { YYYY_MM_DD } = DATE_FORMAT;

export const getDocumentNamesForDateRange = (
  fromDate: string,
  toDate: string,
  tenantSlug: string
) => {
  const documentNamesArray = [];
  let differenceBetweenDates = null;

  do {
    const docName = `${fromDate.replaceAll('-', '')}_${tenantSlug}`;
    documentNamesArray.push(docName);

    fromDate = DateTime.fromISO(fromDate).plus({ days: 1 }).toFormat(YYYY_MM_DD);
    const startDateForComparision = DateTime.fromISO(fromDate);
    const endDateForComparision = DateTime.fromISO(toDate);

    differenceBetweenDates = endDateForComparision.diff(startDateForComparision, 'days').days;
  } while (differenceBetweenDates >= 0);

  return documentNamesArray;
};
