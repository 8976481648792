import { chakra, forwardRef, HTMLChakraProps } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';

export type ChakraRouterLinkProps = Omit<HTMLChakraProps<typeof RouterLink>, 'href'>;

const ChakraRouterLinkInner: React.ForwardRefRenderFunction<
  HTMLAnchorElement,
  ChakraRouterLinkProps
> = (props, ref) => <chakra.a ref={ref} as={RouterLink} {...props} />;

export const ChakraRouterLink = forwardRef(ChakraRouterLinkInner);

export type ChakraRouterLink = typeof ChakraRouterLink;
