import { useMemo } from 'react';

import { useCheckSettings } from 'features/auth';

import { TopNavbarDropdown } from './TopNavbarDropdown';
import { TopNavbarDropdownItemLink } from './TopNavbarDropdownItemLink';

const REDIRECT_URL = process.env.REACT_APP_REDIRECT_URL;

export const TopNavbarRouting = () => {
  const [hasSettings] = useCheckSettings();

  const isMigratedShiftPlanEnabled = hasSettings('migrated_shift_plan_feature');

  const switchBetweenMigratedUrl = useMemo(
    () => ({
      shift_plan: isMigratedShiftPlanEnabled ? '/shift_plans' : `${REDIRECT_URL}main/shifts/plans`,
    }),
    [isMigratedShiftPlanEnabled]
  );

  return (
    <TopNavbarDropdown trigger="Routing">
      {hasSettings('view_shift_plans') && (
        <TopNavbarDropdownItemLink href={switchBetweenMigratedUrl['shift_plan']}>
          Routing Plans
        </TopNavbarDropdownItemLink>
      )}
      {hasSettings('view_routing_report') && (
        <TopNavbarDropdownItemLink href={`${REDIRECT_URL}reports/routing_report`}>
          Report
        </TopNavbarDropdownItemLink>
      )}
    </TopNavbarDropdown>
  );
};
