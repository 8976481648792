import { Avatar, AvatarProps } from '@chakra-ui/react';

const acronym = (name: string) => {
  const words = name.split(' ');

  if (!words.length) {
    return '';
  }

  if (words.length === 1) return name.substring(0, 2).toUpperCase();

  const names = [words[0], words[words.length - 1]];

  return names
    .map((word) => word.substring(0, 1))
    .join('')
    .toUpperCase();
};

export const UserAvatar: React.FC<AvatarProps> = (props) => {
  const {
    name,
    color = 'white',
    background = 'gray.500',
    width = '9',
    height = '9',
    ...rest
  } = props;

  return (
    <Avatar
      name={name}
      color={color}
      background={background}
      size="sm"
      width={width}
      height={height}
      sx={{
        '[role=img]': {
          fontSize: 'sm',
          fontWeight: '600',
        },
      }}
      getInitials={acronym}
      {...rest}
    />
  );
};

export type UserAvatar = typeof UserAvatar;
