import { Box } from '@chakra-ui/react';
import { DateTime } from 'luxon';
import React, { useState, useEffect, useMemo } from 'react';
import DatePicker from 'react-datepicker';

import { compareDates } from 'utils/dateHelpers';

import 'react-datepicker/dist/react-datepicker.css';

import { datePickerWrapperStyle } from './styles';

interface Props {
  toDate: Date;
  fromDate: Date;
  limitMaxDaysSelection: number;
  selectedDate?: Date;
  onValueChange: ([sDate, eDate]: [Date, Date]) => void;
  customInput: JSX.Element;
}

const DateRangePicker: React.FC<Props> = ({
  toDate,
  fromDate,
  selectedDate,
  limitMaxDaysSelection,
  onValueChange,
  ...rest
}) => {
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [dateRange, setDateRange] = useState<[Date | null, Date | null] | []>([]);
  const [partialSelection, setPartialSelection] = useState<Date | null>(null);
  const [startDate, endDate] = dateRange;

  const handleSelect = (date: Date | null) => {
    if (partialSelection) {
      setPartialSelection(null);
    } else {
      setPartialSelection(date);
    }
  };

  const allowedRange = useMemo(() => {
    if (!partialSelection) {
      return undefined;
    } else if (limitMaxDaysSelection > 0) {
      const addedMaxDays = DateTime.fromISO(partialSelection.toISOString()).plus({
        days: limitMaxDaysSelection,
      });

      return (date: Date) => {
        const stringfiedDate = date.toISOString();
        return (
          compareDates(
            partialSelection.toISOString(),
            stringfiedDate,
            'day',
            'GREATERTHANEQUALTO'
          ) && compareDates(addedMaxDays.toString(), stringfiedDate, 'day', 'LESSTHANEQUALTO')
        );
      };
    } else {
      return (date: Date) => {
        const stringfiedDate = date.toISOString();
        return compareDates(
          partialSelection.toISOString(),
          stringfiedDate,
          'day',
          'GREATERTHANEQUALTO'
        );
      };
    }
  }, [partialSelection, limitMaxDaysSelection]);

  useEffect(() => {
    if (fromDate && toDate) {
      setDateRange([fromDate, toDate]);
    }
  }, [fromDate, toDate]);

  const handleChange = ([sDate, eDate]: [Date | null, Date | null]) => {
    setDateRange([sDate, eDate]);

    if (sDate && eDate) {
      onValueChange([sDate, eDate]);
      setOpenDatePicker(false);
    }
  };
  return (
    <Box sx={datePickerWrapperStyle}>
      <DatePicker
        selectsRange
        open={openDatePicker}
        endDate={endDate ?? undefined}
        startDate={startDate ?? undefined}
        showPopperArrow={false}
        selected={selectedDate}
        shouldCloseOnSelect={false}
        dateFormat="yyyy-MM-dd"
        calendarClassName="datepicker-dark"
        onSelect={handleSelect}
        onChange={handleChange}
        filterDate={allowedRange}
        onClickOutside={() => {
          setOpenDatePicker(false);
        }}
        onInputClick={() => {
          setOpenDatePicker(true);
        }}
        {...rest}
      />
    </Box>
  );
};

export { DateRangePicker };
