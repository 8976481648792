import { forwardRef } from '@chakra-ui/react';

import { ChakraRouterLink } from 'components/ChakraRouterLink';

import { TopNavbarDropdownItem } from './TopNavbarDropdownItem';
import { TopNavbarItemRouterLinkProps } from './TopNavbarItemRouterLink';

export type TopNavbarDropdownItemRouterLinkProps = TopNavbarItemRouterLinkProps;

const TopNavbarDropdownItemRouterLinkInner: React.ForwardRefRenderFunction<
  HTMLDivElement,
  TopNavbarDropdownItemRouterLinkProps
> = ({ isActive, ...props }, ref) => (
  <TopNavbarDropdownItem
    ref={ref}
    as={ChakraRouterLink}
    variant={isActive ? 'active' : 'default'}
    {...props}
  />
);

TopNavbarDropdownItemRouterLinkInner.displayName = 'TopNavbarDropdownItemRouterLink';

export const TopNavbarDropdownItemRouterLink = forwardRef(TopNavbarDropdownItemRouterLinkInner);
