/**
 * DEPRECATED: Please consult with the FE Performance team before using this file.
 */
import {
  ACTIVITIES_LIST,
  ACTIVITIES_SET_ACTIVE_PAGE,
  ACTIVITIES_SET_OFFSET,
} from '../actions/types';
import Helpers from '../helpers';

const ACTIVITY_PAGES = Helpers.ACTIVITY_PAGES;

const initialState = {
  list: [],
  activePage: ACTIVITY_PAGES.list,
  limit: ACTIVITY_PAGES.defaultLimit,
  offset: ACTIVITY_PAGES.defaultOffset,
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case ACTIVITIES_LIST:
      return {
        ...state,
        list: payload,
      };
    case ACTIVITIES_SET_ACTIVE_PAGE:
      return {
        ...state,
        activePage: payload,
      };
    case ACTIVITIES_SET_OFFSET:
      return {
        ...state,
        offset: payload,
      };
    default:
      return state;
  }
}
