import { extendTheme, ThemeComponentProps } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';
import type { CSSObject } from '@emotion/react';

import { colors } from './colors';
import { components } from './components';
import { config } from './config';
import { fonts, fontSizes, lineHeights } from './typography';

const sizes = {
  7.5: '1.875rem',
};

const styles = {
  global: (props: ThemeComponentProps) => ({
    body: {
      color: mode(colors.black, colors.white)(props),
      fontSize: 'md',
      WebkitFontSmoothing: 'antialiased',
      fontFamily: '"Montserrat", sans-serif !important',
    } as CSSObject,
    'div, p, li': {
      letterSpacing: '0.025rem',
    },
  }),
};

const theme = extendTheme({
  config,
  colors,
  components,
  fonts,
  fontSizes,
  styles,
  sizes,
  lineHeights,
});

theme.background = { content: colors.white };

export { theme };
