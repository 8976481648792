import { envConfig } from './index';

export const firebaseConfig = {
  apiKey: envConfig.firebaseApiKey,
  appId: envConfig.firebaseAppId,
  authDomain: envConfig.firebaseAuthDomain,
  databaseURL: envConfig.firebaseDatabaseUrl,
  messagingSenderId: envConfig.firebaseMessagingSenderId,
  projectId: envConfig.googleProjectId,
  storageBucket: envConfig.firebaseStorageBucket,
};
