import { chakra, forwardRef } from '@chakra-ui/react';

import { TopNavbarDropdownItem, type TopNavbarDropdownItemProps } from './TopNavbarDropdownItem';

export type TopNavbarDropdownItemButtonProps = TopNavbarDropdownItemProps<'button'>;

const TopNavbarDropdownItemButtonInner: React.ForwardRefRenderFunction<
  HTMLDivElement,
  TopNavbarDropdownItemButtonProps
> = (props, ref) => <TopNavbarDropdownItem ref={ref} as={chakra.button} {...props} />;

TopNavbarDropdownItemButtonInner.displayName = 'TopNavbarDropdownItemButton';

export const TopNavbarDropdownItemButton = forwardRef(TopNavbarDropdownItemButtonInner);

export type TopNavbarDropdownItemButton = typeof TopNavbarDropdownItemButton;
