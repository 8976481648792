import { useMemo } from 'react';

import { BetaTag } from 'components/BetaTag';
import { useCheckSettings } from 'features/auth';
import { FEATURE_FLAG } from 'types/featureFlag';

import { TopNavbarDropdown } from './TopNavbarDropdown';
import { TopNavbarDropdownItemLink } from './TopNavbarDropdownItemLink';
import { TopNavbarDropdownItemRouterLink } from './TopNavbarDropdownItemRouterLink';

const REDIRECT_URL = process.env.REACT_APP_REDIRECT_URL;

export const TopNavbarOrders = () => {
  const [hasSettings] = useCheckSettings();

  const isMigratedShiftListEnabled = hasSettings('migrated_shift_list_feature');
  const isMigratedOrderGroupListEnabled = hasSettings('migrated_order_group_list_feature');
  const isMigratedRecurringListEnabled = hasSettings('migrated_recurring_order_list_feature');
  const isMigratedLoadingOrderListEnabled = hasSettings('migrated_loading_order_list_feature');
  const isMigratedDeliveryOrderListEnabled = hasSettings('migrated_delivery_order_list_feature');
  const isMigratedTransferOrderListEnabled = hasSettings('migrated_transfer_order_list_feature');

  const switchBetweenMigratedUrl = useMemo(
    () => ({
      delivery_order: isMigratedDeliveryOrderListEnabled
        ? '/orders/delivery'
        : `${REDIRECT_URL}main/orders/delivery`,
      shift_list: isMigratedShiftListEnabled
        ? '/orders/shift_list'
        : `${REDIRECT_URL}main/shifts/list`,
      loading_order: isMigratedLoadingOrderListEnabled
        ? '/orders/loading'
        : `${REDIRECT_URL}main/orders/loading`,
      transfer_order: isMigratedTransferOrderListEnabled
        ? '/orders/transfers'
        : `${REDIRECT_URL}main/orders/transfers`,
      recurring_order: isMigratedRecurringListEnabled
        ? '/orders/recurring'
        : `${REDIRECT_URL}main/orders/recurring`,
      order_group: isMigratedOrderGroupListEnabled
        ? '/orders/order_groups'
        : `${REDIRECT_URL}main/orders/order_groups`,
    }),
    [
      isMigratedShiftListEnabled,
      isMigratedRecurringListEnabled,
      isMigratedOrderGroupListEnabled,
      isMigratedLoadingOrderListEnabled,
      isMigratedDeliveryOrderListEnabled,
      isMigratedTransferOrderListEnabled,
    ]
  );

  return (
    <TopNavbarDropdown trigger="Orders">
      <TopNavbarDropdownItemLink href={switchBetweenMigratedUrl['shift_list']}>
        Shift List
      </TopNavbarDropdownItemLink>
      <TopNavbarDropdownItemRouterLink to={'/shift_boards/old/board'}>
        Shift Board
      </TopNavbarDropdownItemRouterLink>
      <BetaTag elemTitle="Weekly Shift Planner" redirectUrl="/orders/weekly-shift-planner" />
      <TopNavbarDropdownItemLink href={switchBetweenMigratedUrl['order_group']}>
        Order Groups
      </TopNavbarDropdownItemLink>
      <TopNavbarDropdownItemLink href={switchBetweenMigratedUrl['delivery_order']}>
        Delivery Orders
      </TopNavbarDropdownItemLink>
      {hasSettings('extraction_order_feature') && (
        <TopNavbarDropdownItemRouterLink to="/orders/extraction">
          Extraction Orders
        </TopNavbarDropdownItemRouterLink>
      )}
      <TopNavbarDropdownItemLink href={switchBetweenMigratedUrl['loading_order']}>
        Loading Orders
      </TopNavbarDropdownItemLink>
      {hasSettings('recurring_order_feature') && (
        <TopNavbarDropdownItemLink href={switchBetweenMigratedUrl['recurring_order']}>
          Recurring Orders
        </TopNavbarDropdownItemLink>
      )}
      {hasSettings('transfer_order_feature') && (
        <TopNavbarDropdownItemLink href={switchBetweenMigratedUrl['transfer_order']}>
          Transfer Orders
        </TopNavbarDropdownItemLink>
      )}
      {hasSettings(FEATURE_FLAG.BULK_PLANT_SHIFT_AUTO_CREATE_FEATURE) && (
        <TopNavbarDropdownItemRouterLink to="/orders/bulk-plant">
          Bulk Plant
        </TopNavbarDropdownItemRouterLink>
      )}
      <BetaTag elemTitle="Driver Notes" redirectUrl="/orders/driver_notes" />
      {hasSettings('shift_builder_feature') && (
        <BetaTag elemTitle="Route Builder" redirectUrl="/orders/route-builder" />
      )}
      {hasSettings(FEATURE_FLAG.CARD_LOCK_TRANSACTION_FEATURE) && (
        <>
          <TopNavbarDropdownItemRouterLink to="/orders/card-lock">
            Card Lock
          </TopNavbarDropdownItemRouterLink>
          <TopNavbarDropdownItemRouterLink to="orders/invoices">
            Invoices
          </TopNavbarDropdownItemRouterLink>
        </>
      )}
    </TopNavbarDropdown>
  );
};
