/**
 * DEPRECATED: Please consult with the FE Performance team before using this file.
 */
import * as actions from '../actions';
import Helpers from '../../../helpers';

export const initialState = {
  fromTimestamp: Helpers.getBeginningOfDayTimestamp(),
  toTimestamp: Helpers.getEndOfDayTimestamp(),
  gapSeconds: 60 * 60 * 24 * 7,
  shiftStates: null,
  shifts: {},
  fetchingShifts: false,
  shiftIdModal: null,
  shiftPagination: { offset: 0, limit: 30 },
  shiftMetaData: {},
  refetchShift: false,
  losData: [],
};

export default function shiftsReducers(state = initialState, action) {
  switch (action.type) {
    case actions.FETCH_SHIFT:
      return {
        ...state,
        shifts: { ...state.shifts, ...action.payload },
      };
    case actions.FETCH_SHIFT_STATES:
      return {
        ...state,
        shiftStates: action.payload,
      };
    case actions.FETCH_SHIFTS:
      return {
        ...state,
        shifts: action.payload,
      };
    case actions.FETCHING_SHIFTS:
      return {
        ...state,
        fetchingShifts: action.payload,
      };
    case actions.SET_TIMESTAMPS:
      return {
        ...state,
        ...action.payload,
      };
    case actions.SET_SHIFT_ID_MODAL:
      return {
        ...state,
        shiftIdModal: action.payload,
      };
    case actions.SET_SHIFT_PAGINATION:
      return {
        ...state,
        shiftPagination: action.payload,
      };
    case actions.SET_SHIFT_METADATA:
      return {
        ...state,
        shiftMetaData: action.payload,
      };
    case actions.REFETCH_SHIFT:
      return {
        ...state,
        refetchShift: action.payload,
      };
    case actions.FETCH_LOS:
      return {
        ...state,
        losData: action.payload,
      };
    default:
      return state;
  }
}
