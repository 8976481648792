/**
 * DEPRECATED: Please consult with the FE Performance team before using this file.
 */

// assets
export const POPULATE_ASSETS = '@assets/POPULATE_ASSETS';

// assets
export const FETCH_CURRENT = 'FETCH_CURRENT';

// settings
export const FETCH_SETTINGS = 'FETCH_SETTINGS';

// reset dispatch view
export const RESET_DISPATCH = 'RESET_DISPATCH';

// filter actions
export const FILTER_DRIVER = 'FILTER_DRIVER';
export const FILTER_CUSTOMER = 'FILTER_CUSTOMER';

// drivers
export const SELECT_DRIVER = 'SELECT_DRIVER';
export const UPDATE_SELECTED_DRIVER_LANE = 'UPDATE_SELECTED_DRIVER_LANE';

// customers
export const FETCH_CUSTOMERS = 'FETCH_CUSTOMERS';

// pusher
export const GET_PUSHER_CHANNEL = 'GET_PUSHER_CHANNEL';
export const NEW_ROUTE = 'NEW_ROUTE';
export const START_WORK_BREAK = 'START_WORK_BREAK';
export const STOP_WORK_BREAK = 'STOP_WORK_BREAK';
export const START_MEAL_BREAK = 'START_MEAL_BREAK';
export const STOP_MEAL_BREAK = 'STOP_MEAL_BREAK';
export const WORK_BREAK_NOT_TAKEN = 'WORK_BREAK_NOT_TAKEN';
export const MEAL_BREAK_NOT_TAKEN = 'MEAL_BREAK_NOT_TAKEN';
export const SELF_REFUEL_TASK = 'SELF_REFUEL_TASK';
export const TASK_UPDATE = 'TASK_UPDATE';
export const TASK_COMPLETED = 'TASK_COMPLETED';
export const TASK_CONFIRMED = 'TASK_CONFIRMED';

// activities
export const ACTIVITIES_LIST = 'ACTIVITIES_LIST';
export const ACTIVITIES_SET_ACTIVE_PAGE = 'ACTIVITIES_SET_ACTIVE_PAGE';
export const ACTIVITIES_SET_OFFSET = 'ACTIVITIES_SET_OFFSET';

// qr codes
export const QR_CODE_LIST = 'QR_CODE_LIST';
export const QR_CODE_SET_OFFSET = 'QR_CODE_SET_OFFSET';
export const QR_CODE_SET_TYPE = 'QR_CODE_SET_TYPE';
export const QR_CODE_SET_LIMIT = 'QR_CODE_SET_LIMIT';
export const QR_CODE_SET_CODE = 'QR_CODE_SET_CODE';
export const QR_CODE_LOADING = 'QR_CODE_LOADING';

// alerts
export const FETCH_ALERTS = 'FETCH_ALERTS';
export const FETCH_ALERT_TYPES = 'FETCH_ALERT_TYPES';
export const SELECTED_FILTERS = 'SELECTED_FILTERS';
export const FILTERED_ALERTS = 'FILTERED_ALERTS';

// forms
export const GET_FORMS = 'GET_FORMS';
export const ADD_FORM = 'ADD_FORM';
export const ADD_SUBMISSION = 'ADD_SUBMISSION';
export const GET_SUBMISSIONS = 'GET_SUBMISSIONS';
export const GET_SUBMISSION = 'GET_SUBMISSION';

// firestore
export const SET_FS_APP_RELEASES = 'SET_FS_APP_RELEASES';
export const SET_FS_SETTINGS = 'SET_FS_SETTINGS';
export const SET_FS_SYNC_DATA = 'SET_FS_SYNC_DATA';
export const SET_FS_WORKERS = 'SET_FS_WORKERS';
