/**
 * DEPRECATED: Please consult with the FE Performance team before using this file.
 */
import * as workers from '../workers';

export const FETCH_SHIFT_STATES = '@shifts/FETCH_SHIFT_STATES';

export const FETCH_SHIFT = '@shifts/FETCH_SHIFT';
export const REFETCH_SHIFT = '@shifts/REFETCH_SHIFT';

export const FETCH_SHIFTS = '@shifts/FETCH_SHIFTS';
export const FETCHING_SHIFTS = '@shifts/FETCHING_SHIFTS';

export const SET_TIMESTAMPS = '@shifts/SET_TIMESTAMPS';

export const SET_SHIFT_PAGINATION = '@shifts/SET_SHIFT_PAGINATION';

export const SET_SHIFT_METADATA = '@shifts/SET_SHIFT_METADATA';

export const SET_SHIFT_ID_MODAL = '@shifts/SET_SHIFT_ID_MODAL';
export const FETCH_LOS = '@shifts/FETCH_LOS';

export const setCurrentTimestamps = (from, to) => workers.setCurrentTimestamps(from, to);
export const setShiftPagination = (data) => workers.setShiftPagination(data);

export const fetchShiftStates = () => workers.fetchShiftStates();

export const fetchShift = (shiftId) => workers.fetchShift(shiftId);
export const refetchShifts = () => workers.refetchShifts();
export const fetchShifts = (filter) => workers.fetchShifts(filter);
export const setShiftIdModal = (shiftId) => workers.setShiftIdModal(shiftId);
export const fetchLos = (shiftId) => workers.fetchLos(shiftId);
