export const pandaBtnTooltip = {
  color: 'gray.50',
  border: '1px solid',
  borderColor: 'gray.800',
  padding: '6px 8px',
  borderRadius: 6,
};

export const pandaBtnStyle = {
  fontSize: 'md',
  fontWeight: 'bold',
  lineHeight: 1,
  padding: '6px 10px',
  minHeight: 'inherit',
  color: 'gray.275',
  bgColor: 'gray.700',
  _hover: {
    bgColor: 'whiteAlpha.300',
  },
  _active: {},
  _disabled: {
    opacity: 0.4,
    cursor: 'not-allowed',
    _hover: { bgColor: 'gray.700' },
  },
};

export const primaryBtnStyle = {
  color: 'white',
  fontWeight: '500',
  bgColor: 'purple.300',
  '&:hover': { bgColor: 'purple.500' },
  textWrap: 'auto',
  _disabled: { opacity: 0.4, _hover: { bgColor: 'purple.300' } },
};
