/**
 * DEPRECATED: Please consult with the FE Performance team before using this file.
 */
import { query } from 'gql-query-builder';
import { fetchGraphQLData } from '../../../../../../graphql';

export const getProducts = (dataDriver) => {
  return dataDriver.activeShift?.shiftCompartmentInstances?.reduce((products, instance) => {
    if (instance?.retainInventory) {
      instance.retainInventory.forEach((retain) => {
        if (retain.product?.id) {
          products[retain.product.id] = products[retain.product?.id] || {
            ...retain.product,
            volume: 0,
            compartments: {},
          };
          products[retain.product.id].volume += Number(retain.volume);
          products[retain.product.id].compartments[instance.compartment.name] =
            (products[retain.product.id].compartments[instance.compartment.name] || 0) +
            Number(retain.volume);
        }
      });
    }

    return products;
  }, {});
};

export const fetchDriverShiftData = async (id) => {
  if (!id) return;

  const response = await fetchGraphQLData(
    JSON.stringify(
      query({
        operation: 'driver',
        variables: { id: { value: String(id), type: 'ID', required: true } },
        fields: [
          'id',
          'name',
          'status',
          {
            activeShift: [
              'id',
              {
                shiftCompartmentInstances: [
                  'id',
                  {
                    retainInventory: [
                      'volume',
                      {
                        product: ['id', 'name', 'code', 'shortName', 'colorCode'],
                      },
                    ],
                  },
                  {
                    compartment: ['id', 'name'],
                  },
                ],
              },
              {
                shiftDeliveryUnitsAssets: [
                  'id',
                  {
                    deliveryAsset: ['id', 'name', 'licensePlateNumber', 'category'],
                  },
                ],
              },
            ],
          },
        ],
      })
    )
  );

  const dataDriver = response.data.driver;

  const driver = {
    ...dataDriver,
    products: getProducts(dataDriver),
  };

  return driver;
};
