import * as actions from '../actions';

/**
 * Sets order Bulk Plant details modal
 *
 * @returns {function}
 */
export const setBulkPlantShiftModalParam = (payload) => (dispatch) => {
  dispatch({
    type: actions.SET_BULK_PLANT_SHIFT_MODAL_PARAM,
    payload: payload,
  });
};
