import { Flex } from '@chakra-ui/react';

import { FAIcon } from 'components/FAIcon';
import { useAuth } from 'features/auth';

import { TopNavbarDropdownDivider } from './TopNavbarDropdownDivider';
import { TopNavbarDropdownItemButton } from './TopNavbarDropdownItemButton';
import { TopNavbarDropdownItemLink } from './TopNavbarDropdownItemLink';
import { UserDropdown } from './UserDropdown';

const REDIRECT_URL = process.env.REACT_APP_REDIRECT_URL;

interface UserProps {
  userName: string;
  customerName: string;
  isCustomerClient?: boolean;
}
export const TopNavbarUser = ({ userName, customerName }: UserProps) => {
  const { logout } = useAuth();

  return (
    <UserDropdown name={userName} tenant={customerName}>
      <TopNavbarDropdownItemLink href={`${REDIRECT_URL}profile`}>
        Edit Profile
      </TopNavbarDropdownItemLink>
      <TopNavbarDropdownItemLink href={`${REDIRECT_URL}users/edit_password`}>
        Change Password
      </TopNavbarDropdownItemLink>
      <TopNavbarDropdownDivider />
      <TopNavbarDropdownItemButton
        onClick={() => {
          logout();
        }}
      >
        <Flex alignItems="center" justifyContent="space-between" w="full">
          Logout
          <FAIcon icon="sign-out-alt" variant="solid" />
        </Flex>
      </TopNavbarDropdownItemButton>
    </UserDropdown>
  );
};
