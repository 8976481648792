/**
 * DEPRECATED: Please consult with the FE Performance team before using this file.
 */
import {
  NEW_ROUTE,
  START_WORK_BREAK,
  STOP_WORK_BREAK,
  START_MEAL_BREAK,
  STOP_MEAL_BREAK,
  WORK_BREAK_NOT_TAKEN,
  MEAL_BREAK_NOT_TAKEN,
  SELF_REFUEL_TASK,
  TASK_UPDATE,
  TASK_COMPLETED,
  TASK_CONFIRMED,
} from '../actions/types';

const initialState = {
  driverData: {},
  driverBreak: [],
  driverNoBreak: [],
  selfRefuel: {},
  taskUpdate: {},
  taskCompleted: {},
  taskConfirmed: {},
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case NEW_ROUTE:
      return {
        ...state,
        driverData: payload,
      };
    case START_WORK_BREAK:
    case STOP_WORK_BREAK:
    case START_MEAL_BREAK:
    case STOP_MEAL_BREAK:
      return {
        ...state,
        driverBreak: payload,
      };
    case WORK_BREAK_NOT_TAKEN:
    case MEAL_BREAK_NOT_TAKEN:
      return {
        ...state,
        driverNoBreak: payload,
      };
    case SELF_REFUEL_TASK:
      return {
        ...state,
        selfRefuel: payload,
      };

    case TASK_UPDATE:
      return {
        ...state,
        taskUpdate: payload,
      };
    case TASK_COMPLETED:
      return {
        ...state,
        taskCompleted: payload,
      };
    case TASK_CONFIRMED:
      return {
        ...state,
        taskConfirmed: payload,
      };
    default:
      return state;
  }
}
