import { useCallback, useMemo } from 'react';

import { FetchCurrentQuery } from 'generated/graphql';

import { useCurrent } from './useCurrent';

export const hasSettings = (
  data: FetchCurrentQuery | undefined,
  ...settings: (string | ['user' | 'customer', string])[]
) => {
  if (!data) {
    return false;
  }

  return settings.reduce((acc, setting) => {
    if (Array.isArray(setting)) {
      const [store, name] = setting;

      return (
        acc &&
        data.current[store === 'user' ? 'userFeatureFlags' : 'customerSettings'].includes(name)
      );
    } else {
      return (
        acc &&
        (data.current.userFeatureFlags.includes(setting) ||
          data.current.customerSettings.includes(setting))
      );
    }
  }, true);
};

export function useHasSettings(
  ...settings: (string | ['user' | 'customer', string])[]
): [boolean, boolean] {
  const { data, loading } = useCurrent();

  const _hasSettings = useMemo(() => hasSettings(data, ...settings), [data, settings]);

  return [_hasSettings, loading] as [boolean, boolean];
}

export function useCheckSettings(): [
  (...settings: (string | ['user' | 'customer', string])[]) => boolean,
  boolean
] {
  const { data, loading } = useCurrent();

  const _hasSettings = useCallback(
    (...settings: (string | ['user' | 'customer', string])[]) => {
      return hasSettings(data, ...settings);
    },
    [data]
  );

  return [_hasSettings, loading];
}
