import { createSlice } from '@reduxjs/toolkit';
import { MapCameraProps } from '@vis.gl/react-google-maps';

import { OrdersTableRowDataType } from '../types/ordersTable';

type MapViewReducerType = {
  selectedOrders: OrdersTableRowDataType[];
  mapViewCameraState?: MapCameraProps;
  deSelectAllOrders: boolean;
};

const initialState: MapViewReducerType = {
  selectedOrders: [],
  deSelectAllOrders: false,
  mapViewCameraState: {
    center: {
      lat: 40.7127753,
      lng: -74.0059728,
    },
    zoom: 10,
    heading: 0,
    tilt: 0,
  },
};

const mapViewSlice = createSlice({
  name: 'mapView',
  initialState,
  reducers: {
    setSelectedOrders(state, action) {
      state.selectedOrders = action.payload;
    },
    deSelectAllOrders(state, action) {
      state.deSelectAllOrders = action.payload;
      state.selectedOrders = [];
    },
    setMapViewCameraState(state, action) {
      state.mapViewCameraState = action.payload;
    },
  },
});

export const { setSelectedOrders, deSelectAllOrders, setMapViewCameraState } = mapViewSlice.actions;

// eslint-disable-next-line import/no-default-export
export default mapViewSlice.reducer;
