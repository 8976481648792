export const SET_LOAD_ORDER_DETAILS_ID = '@orderDetails/SET_LOAD_ORDER_DETAILS_ID';
export const SET_DELIVERY_ORDER_DETAILS_ID = '@orderDetails/SET_DELIVERY_ORDER_DETAILS_ID';
export const SET_EXTRACTION_ORDER_DETAILS_ID = '@orderDetails/SET_EXTRACTION_ORDER_DETAILS_ID';

export const initialState = {
  loadOrderId: null,
  deliveryOrderId: null,
  extractionOrderId: null,
};

export default function orderDetailsReducers(state = initialState, action) {
  switch (action.type) {
    case SET_LOAD_ORDER_DETAILS_ID:
      return {
        ...state,
        loadOrderId: action.payload,
      };
    case SET_DELIVERY_ORDER_DETAILS_ID:
      return {
        ...state,
        deliveryOrderId: action.payload,
      };
    case SET_EXTRACTION_ORDER_DETAILS_ID:
      return {
        ...state,
        extractionOrderId: action.payload,
      };
    default:
      return state;
  }
}
