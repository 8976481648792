import React, { ErrorInfo, Component, ReactNode } from 'react';

import { FullPageError } from 'components/FullPageError';

import { handleErrors } from './handleErrors';
import { WebAppErr } from './WebAppErr';

interface ErrorBoundaryState {
  error: Error | null;
  errorInfo: ErrorInfo | null;
}

class ErrorBoundary extends Component<{ children: ReactNode }, ErrorBoundaryState> {
  constructor(props: { children: ReactNode }) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });

    const webAppErr = new WebAppErr(error.message);

    handleErrors(webAppErr);
  }

  render() {
    if (this.state.errorInfo) {
      return <FullPageError />;
    }

    return this.props.children;
  }
}

export { ErrorBoundary };
