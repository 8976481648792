import { SystemStyleFunction, SystemStyleObject } from '@chakra-ui/theme-tools';

const defaultProps = {
  colorScheme: 'purple',
};

const baseStyle: SystemStyleObject = {
  px: 3,
  py: 2,
};

const variants: Record<string, SystemStyleFunction> = {
  solid({ colorScheme: c, colorMode: mode }) {
    if (c === 'red')
      return {
        bg: 'red.300',
        color: 'white',
        _hover: {
          bg: 'red.400',
        },
      };
    if (c !== 'purple' || mode !== 'dark') return {};

    return {
      bg: 'purple.300',
      color: 'white',
      _hover: {
        bg: 'purple.400',
      },
    };
  },
  actionBlueButton() {
    return {
      backgroundColor: 'blue.500',
      height: '25px',
      fontSize: '11px',
      _hover: {
        bgColor: 'blue.600',
      },
    };
  },
  actionButtonNoBackground() {
    return {
      backgroundColor: 'none',
      color: 'blue.200',
      padding: 0,
      border: 'none',
      _focus: {
        boxShadow: 'none',
        border: 'none',
      },
      _active: {
        boxShadow: 'none',
        border: 'none',
      },
      _hover: {
        color: 'blue.250',
        border: 'none',
      },
    };
  },
  actionDarkButton() {
    return {
      bg: '#262C31',
      fontSize: '10px',
      size: 'sm',
      borderRadius: '4px',
      height: '30px',
      _hover: { bg: '#262C31' },
      _active: {
        bg: '#262C31',
        transform: 'scale(0.98)',
        borderColor: 'none',
      },
      _focus: {
        boxShadow: 'none',
      },
    };
  },
  actionOrangeButton() {
    return {
      bg: 'none',
      color: 'orange.1000',
      height: '30px',
      borderRadius: '0px',
      border: '1px',
      borderColor: 'orange.1000',
      _hover: { bg: 'none', color: 'orange.1000', borderColor: 'orange.1000' },
      _active: {
        bg: 'orange.1000',
        transform: 'scale(0.98)',
        color: 'white',
        borderColor: 'orange.1000',
      },
      _focus: {
        bg: 'orange.1000',
        color: 'white',
        borderColor: 'orange.1000',
        outline: 0,
        boxShadow: 'none',
      },
      fontSize: '12px',
    };
  },
  orangeBorderButton() {
    return {
      bg: 'none',
      color: 'orange.1000',
      height: '30px',
      borderRadius: '5px',
      border: '1px',
      borderColor: 'orange.1000',
      _hover: { bg: 'orange.1000', color: 'white', borderColor: 'orange.1000' },
      _active: {
        bg: 'none',
        transform: 'scale(0.98)',
        color: 'orange.1000',
        borderColor: 'orange.1000',
      },
      _focus: {
        bg: 'none',
        color: 'white',
        borderColor: 'orange.1000',
        outline: 0,
        boxShadow: 'none',
      },
      fontSize: '12px',
    };
  },
};

export const Button = {
  baseStyle,
  defaultProps,
  variants,
};
