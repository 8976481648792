import { createSlice } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';

import assignOrdersToRouteSlice from './assignOrdersToRouteSlice';
import createRouteSlice from './createRouteSlice';
import mapViewSlice from './mapViewSlice';
import ordersTableSlice from './ordersTableSlice';
import routesTableSlice from './routesTableSlice';
import routeSummarySlice from './routeSummarySlice';

export enum RouteBuilderActiveTab {
  TableView = 'tableView',
  MapView = 'mapView',
}

export type RouteBuilderReducerType = {
  viewState: {
    activeTab: string;
  };
};

const initialState: RouteBuilderReducerType = {
  viewState: {
    activeTab: RouteBuilderActiveTab.TableView,
  },
};

const routeBuilder = createSlice({
  name: 'routeBuilder',
  initialState: initialState,
  reducers: {
    setActiveTab(state, action) {
      state.viewState.activeTab = action.payload;
    },
  },
});

export const { setActiveTab } = routeBuilder.actions;

// combine all slices seperated by a view name
const routeBuilderReducer = combineReducers({
  routeBuilder: routeBuilder.reducer, // base slice
  ordersTable: ordersTableSlice,
  routesTable: routesTableSlice,
  routeSummary: routeSummarySlice,
  createRoute: createRouteSlice,
  assignOrdersToRoute: assignOrdersToRouteSlice,
  mapView: mapViewSlice,
});

export type RouteBuilderRootState = ReturnType<typeof routeBuilderReducer>;

// eslint-disable-next-line import/no-default-export
export default routeBuilderReducer;
