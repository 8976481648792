import Helpers from '../../../helpers';
import * as actions from '../actions';

export const initialState = {
  orderGroups: {},
  fetchingOrderGroups: false,
  deliveryOrders: {},
  fetchingDeliveryOrders: false,
  loadingOrders: {},
  fetchingLoadingOrders: false,
  customers: [],
  fetchingCustomers: false,
  drivers: [],
  fetchingDrivers: false,
  creatingNewRecurrenceOrder: false,
  fetchingRecurrenceOrders: false,
  recurrenceOrders: [],
  filteredRecurrenceOrders: [],
  deletedRecurrenceOrders: false,
  deletingRecurrenceOrders: false,
  updatingRecurrenceOrder: false,
  fromTime: Helpers.today('YYYY-MM-DD'),
  toTime: Helpers.today('YYYY-MM-DD'),
  openTransferOrder: null,
  refetchTransferOrders: false,
  fetchRecurrence: false,
  orderDateChange: false,
  refetchOrderData: false,
};

export default function ordersReducers(state = initialState, action) {
  switch (action.type) {
    case actions.FETCH_ORDER_GROUPS:
      return {
        ...state,
        orderGroups: action.payload,
      };
    case actions.FETCHING_ORDER_GROUPS:
      return {
        ...state,
        fetchingOrderGroups: action.payload,
      };
    case actions.FETCH_DELIVERY_ORDERS:
      return {
        ...state,
        deliveryOrders: action.payload,
      };
    case actions.FETCHING_DELIVERY_ORDERS:
      return {
        ...state,
        fetchingDeliveryOrders: action.payload,
      };
    case actions.FETCH_LOADING_ORDERS:
      return {
        ...state,
        loadingOrders: action.payload,
      };
    case actions.FETCHING_LOADING_ORDERS:
      return {
        ...state,
        fetchingLoadingOrders: action.payload,
      };
    case actions.FETCH_DRIVERS:
      return {
        ...state,
        drivers: action.payload,
      };
    case actions.FETCHING_DRIVERS:
      return {
        ...state,
        fetchingDrivers: action.payload,
      };
    case actions.FETCH_CUSTOMERS:
      return {
        ...state,
        customers: action.payload,
      };
    case actions.FETCHING_CUSTOMERS:
      return {
        ...state,
        fetchingCustomers: action.payload,
      };
    case actions.CREATING_NEW_RECURRENCE:
      return {
        ...state,
        creatingNewRecurrenceOrder: action.payload,
      };
    case actions.FETCH_RECURRENCE_ORDERS:
      return {
        ...state,
        recurrenceOrders: action.payload,
      };
    case actions.FETCHING_RECURRENCE_ORDERS:
      return {
        ...state,
        fetchingRecurrenceOrders: action.payload,
      };
    case actions.FETCH_FILTERED_RECURRENCE_ORDERS:
      return {
        ...state,
        filteredRecurrenceOrders: action.payload,
      };
    case actions.DELETING_RECURRENCE_ORDERS:
      return {
        ...state,
        deletingRecurrenceOrders: action.payload,
      };
    case actions.DELETED_RECURRENCE_ORDERS:
      return {
        ...state,
        deletedRecurrenceOrders: action.payload,
      };
    case actions.UPDATING_RECURRENCE:
      return {
        ...state,
        updatingRecurrenceOrder: action.payload,
      };
    case actions.OPEN_TRANSFER_ORDER_DETAIL:
      return {
        ...state,
        openTransferOrder: action.payload,
      };
    case actions.REFETCH_TRANSFER_ORDERS:
      return {
        ...state,
        refetchTransferOrders: action.payload,
      };
    case actions.SET_TIMESTAMPS:
      return {
        ...state,
        ...action.payload,
      };
    case actions.FETCH_RECURRENCE:
      return {
        ...state,
        fetchRecurrence: action.payload,
      };
    case actions.ORDER_DATE_CHANGE:
      return {
        ...state,
        orderDateChange: action.payload,
      };
    case actions.REFETCH_ORDER_DATA:
      return {
        ...state,
        refetchOrderData: action.payload,
      };
    default:
      return state;
  }
}
