import { switchAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  switchAnatomy.keys
);

const panda = definePartsStyle({
  track: {
    bg: 'whiteAlpha.400',
    _checked: {
      bg: 'orange.1000',
    },
  },
});

export const switchTheme = defineMultiStyleConfig({ variants: { panda } });
