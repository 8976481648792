/**
 * DEPRECATED: Please consult with the FE Performance team before using this file.
 */
import {
  GET_FORMS,
  ADD_FORM,
  ADD_SUBMISSION,
  GET_SUBMISSIONS,
  GET_SUBMISSION,
} from '../actions/types';

const initialState = {
  list: [],
  selected: {},
  submissions: [],
  submission: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_FORMS:
      return { ...state, list: action.data };
    case ADD_FORM:
      return state;
    case ADD_SUBMISSION:
      return state.submissions.push(action.data);
    case GET_SUBMISSIONS:
      return { ...state, submissions: action.data };
    case GET_SUBMISSION:
      return { ...state, submission: action.data };
    default:
      return state;
  }
}
