import { RouteConfig } from 'types/RouteConfig';

export const DriverSubRouteConfig: Record<string, RouteConfig> = {
  DELIVERY_TIME_SPENT: {
    key: 'delivery-time-spent',
    path: 'delivery-time-spent',
  },
  LOADING_TIME_SPENT: {
    key: 'loading-time-spent',
    path: 'loading-time-spent',
  },
  DRIVER_APP_DETAILS: {
    key: 'driver-app-details',
    path: 'driver-app-details',
  },
  QR_CODES: {
    key: 'qr-codes',
    path: 'qr-codes',
  },
};
