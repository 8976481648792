import {
  chakra,
  ComponentWithAs,
  Flex,
  forwardRef,
  Menu,
  MenuButton,
  MenuButtonProps,
  MenuList,
  MenuProps,
} from '@chakra-ui/react';
import { runIfFn } from '@chakra-ui/utils';

import { FAIcon } from 'components/FAIcon';

export type TenantDropdownProps = Omit<ComponentWithAs<'button', MenuButtonProps>, 'children'> & {
  children: MenuProps['children'];
  handleMenuClose: () => void;
};

const TenantDropdownInner: React.ForwardRefRenderFunction<
  HTMLButtonElement,
  TenantDropdownProps
> = ({ children, handleMenuClose, ...props }, ref) => {
  return (
    <Menu offset={[0, 20]} onClose={handleMenuClose}>
      {({ isOpen, onClose, forceUpdate }) => (
        <>
          <MenuButton
            as={chakra.button}
            ref={ref}
            paddingX="1.5"
            name="tenant-switch-button"
            {...props}
          >
            <Flex alignItems="center">
              <FAIcon icon="exchange" variant="light" fontWeight={'black'} fontSize={'md'} />
            </Flex>
          </MenuButton>
          {isOpen && (
            <MenuList
              zIndex={6}
              sx={{
                maxHeight: '600px',
                overflow: 'auto',
                backgroundColor: 'gray.550',
                border: 'none',
                boxShadow: '6px 6px 6px rgb(0 0 0 / 25%)',
                minWidth: '0',
                pt: '0',
                inset: '-20px 0px auto auto',
                position: 'relative',
                '&:before': {
                  borderColor: 'transparent',
                  borderBottomColor: 'gray.550',
                  borderStyle: 'solid',
                  borderWidth: '0 12px 10px 12px',
                  bottom: '100%',
                  content: '""',
                  height: '0',
                  position: 'absolute',
                  right: '24px',
                  width: '0',
                },
                '&::-webkit-scrollbar': {
                  width: '4px',
                },
                '&::-webkit-scrollbar-track': {
                  width: '6px',
                  backgroundColor: 'gray.350',
                },
                '&::-webkit-scrollbar-thumb': {
                  background: 'gray.780',
                  borderRadius: '24px',
                },
              }}
            >
              {runIfFn(children, { isOpen, onClose, forceUpdate })}
            </MenuList>
          )}
        </>
      )}
    </Menu>
  );
};

export const TenantDropdown = forwardRef(TenantDropdownInner);

export type TenantDropdown = typeof TenantDropdown;
