export const errorCodes = {
  MISSING_AUTH_HEADER: 1001,
  INVALID_AUTH_HEADER: 1002,
  UNDECODABLE_ACCESS_TOKEN: 1003, // Expired Access token
  INSUFFICIENT_INFO_ON_ACCESS_TOKEN: 1004,
  NO_USER_FOUND_FOR_ACCESS_TOKEN: 1005,
  INCORRECT_PASSWORD: 1006,
  FAILED_TO_CREATE_AUTH_TOKEN: 1007,
  MISSING_LEGACY_INFO: 1008, // phonenumber, role and organization
  MISSING_PHONENUBMER_ON_VERIFICATION: 1009,
  FAILED_RESET_PASSWORD: 1010,
  FAILED_OTP_VERIFICATION: 1011,
  FAILED_UPDATE_PASWWORD: 1012,
  TRIED_TO_USE_TOKEN_AFTER_LOGOUT: 1013,
  ACCESS_TOKEN_NOT_FOUND_IN_DB: 1014, // Multiple device login. Should logout from the app.
  REFRESH_TOKEN_NOT_FOUND_IN_DB: 1015, // Multiple device login. Should logout from the app.
  INVALID_INFO_ON_REFRESH_TOKEN: 1016,
  UNDEFINED_FIELD: 'undefinedField',
  UNSUPPORTED_ARGS: 'argumentNotAccepted',
  INCOMPATIBLE_LITERAL: 'argumentLiteralsIncompatible',
};
