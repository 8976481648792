import { Flex, HTMLChakraProps, Input } from '@chakra-ui/react';

import { FAIcon } from './FAIcon';

interface SearchInputProps extends HTMLChakraProps<'input'> {
  backgroundColor?: string;
}
export const SearchInput = (props: SearchInputProps) => {
  const { backgroundColor, ...inputProps } = props;

  return (
    <Flex
      px={'2.5'}
      height={'7'}
      borderRadius={'base'}
      alignItems={'center'}
      justifyContent={'center'}
      backgroundColor={backgroundColor}
    >
      <FAIcon icon="search" variant="solid" color={'gray.100'} />
      <Input
        title={inputProps.placeholder}
        {...inputProps}
        size={'md'}
        height={'7'}
        paddingX={'2'}
        borderWidth={0}
        fontWeight={600}
        _focus={{ borderWidth: 0 }}
        _focusVisible={{ borderWidth: 0 }}
      />
    </Flex>
  );
};

export type SearchInput = typeof SearchInput;
