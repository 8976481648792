/**
 * DEPRECATED: Please consult with the FE Performance team before using this file.
 */
import { SELECT_DRIVER, UPDATE_SELECTED_DRIVER_LANE } from '../actions/types';

const initialState = {
  data: [],
  selectedDriverId: null,
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case SELECT_DRIVER:
      return {
        ...state,
        selectedDriverId: payload,
      };
    case UPDATE_SELECTED_DRIVER_LANE:
      return {
        ...state,
        selectedDriverId: { ...state.selectedDriverId, tasks: payload },
      };
    default:
      return state;
  }
}
