import { useMemo, useEffect, useCallback } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import toastr from 'toastr';

import { useDateHelpers } from 'hooks/useDateHelpers';
import { store } from 'lib/store';
import { RootStateType } from 'types/store';

export interface QueryParamsType {
  collection: string;
  doc: string;
}

const getQueryParamsForFirebase = (docNameList: string[]) => {
  const tempQueryArray = docNameList.map((indvDocName) => {
    return {
      collection: 'events',
      doc: indvDocName,
    };
  });
  return tempQueryArray;
};

const getQueryParamsForFirebaseUnset = (docNameList: string[], fireStoreEvents: string) => {
  if (!fireStoreEvents) return [];
  const tempQueryParamsArray = Object.keys(fireStoreEvents)?.reduce(
    (acc: QueryParamsType[], currEvent: string) => {
      if (!docNameList.includes(currEvent)) {
        return [
          ...acc,
          {
            collection: 'events',
            doc: currEvent,
          },
        ];
      }
      return acc;
    },
    []
  );

  return tempQueryParamsArray;
};

export const useFirebaseForGeofence = (fromDate?: string, toDate?: string) => {
  const { getDatesDiff, operationInDate } = useDateHelpers();

  const firestoreData = useSelector(
    (state: RootStateType) => state?.firestore?.data?.events,
    shallowEqual
  );
  const tenantSlug = useSelector(
    (state: RootStateType) => state.current.customer?.tenantSlug,
    shallowEqual
  );

  const getDocumentNamesForDateRange = (fromDate: string, toDate: string, tenantSlug: string) => {
    if (!fromDate || !toDate) return [];
    const daysDiff = (getDatesDiff(fromDate, toDate)?.days || 0) + 1;
    if (daysDiff < 0) {
      toastr.warning('planned time and completed time are invalid');
      return [];
    }

    const tempDocNameArray = new Array(daysDiff).fill(0)?.map((_, dateIndex) => {
      const tempNewDate = operationInDate({ dateTime: fromDate, duration: dateIndex });
      return `${tempNewDate.replaceAll('-', '')}_${tenantSlug}`;
    });
    return tempDocNameArray;
  };

  const docNamesFromDates = useMemo(
    () => getDocumentNamesForDateRange(fromDate || '', toDate || '', tenantSlug),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fromDate, tenantSlug, toDate]
  );

  const setUnsetFirestore = useCallback(
    (dateList: string[]) => {
      const { firestore } = store;

      const queryParamsArrayForFirebase = getQueryParamsForFirebase(dateList);
      firestore.setListeners(queryParamsArrayForFirebase);

      // unset all documents that are not in date range
      const queryParamsArrayForFirebaseUnset = getQueryParamsForFirebaseUnset(
        dateList,
        firestoreData
      );
      if (queryParamsArrayForFirebaseUnset) {
        firestore.unsetListeners(queryParamsArrayForFirebaseUnset);
      }
    },
    [firestoreData]
  );

  useEffect(() => {
    if (!fromDate || !toDate) return;
    setUnsetFirestore(docNamesFromDates);
  }, [fromDate, toDate, docNamesFromDates, setUnsetFirestore]);

  const getDocNameList = (fromDate: string, toDate: string) => {
    if (!fromDate || !toDate)
      return {
        events: {},
        documentNamesArray: [],
      };

    const tempDocNames = getDocumentNamesForDateRange(fromDate || '', toDate || '', tenantSlug);
    setUnsetFirestore(tempDocNames);
    return {
      events: firestoreData,
      documentNamesArray: tempDocNames,
    };
  };

  return {
    events: firestoreData,
    documentNamesArray: docNamesFromDates,
    getDocNameList,
  };
};
