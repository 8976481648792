import { createApi } from '@reduxjs/toolkit/query/react';
import { graphqlRequestBaseQuery } from '@rtk-query/graphql-request-base-query';

import { getAuthToken } from 'features/auth';

/**
 * Api parameters for fetching orders
 * API_URL : app-domain/graphql
 * authToken : auth token for the user
 */
const API_URL = process.env.REACT_APP_REMOTE_HOST + 'graphql';
const authToken = getAuthToken();

export const routeBuilderApis = createApi({
  reducerPath: 'routeBuilderApis',
  baseQuery: graphqlRequestBaseQuery({
    url: API_URL,
    prepareHeaders: (headers) => {
      // Auth token is needed for all requests
      const token = authToken;
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: () => ({
    // getFilteredOrderedWell: builder.query({
    //   query: ({ limit, offset, filter }) => ({
    //     document: RouteBuilderOrdersDocument,
    //     variables: { limit, offset, filter },
    //   }),
    // }),
    // getRoutes: builder.query({
    //   query: () => ({
    //     document: FetchRawRoutesDocument,
    //   }),
    // }),
    // getRouteSummary: builder.query({
    //   query: ({ id }) => ({
    //     document: FetchRawRouteDocument,
    //     variables: { id },
    //   }),
    // }),
  }),
});

// export const { useGetFilteredOrderedWellQuery, useGetRoutesQuery, useGetRouteSummaryQuery } =
//   routeBuilderApis;
