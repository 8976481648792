import { createSlice } from '@reduxjs/toolkit';

import { OrdersTableRowDataType } from '../types/ordersTable';

/**
 * Slice for Component: OrdersTable
 * ..components/OrdersTable.tsx
 */

export type OrdersTableFilterType = {
  manualOrders: boolean;
  deliveryOrders: boolean;
  loadOrders: boolean;
  urgentOrders: boolean;
  recurringOrders: boolean;
  tankMonitorOrders: boolean;
  unassignedOrders: boolean;
  hub: string;
  city: string;
  zipCode: string;
  radiusFilter: {
    lat: string;
    lng: string;
    radius: string;
  };
  dueDate: Date;
  startDateOfDateRange: Date;
  endDateOfDateRange: Date;
};

export type OrdersTableSliceReducerType = {
  orderFilters: OrdersTableFilterType;
  ordersTableSelectedOrders: OrdersTableRowDataType[];
  deselectAllRows: boolean;
  ordersTableSearchKeyword: string;
  refetchOrdersTableData: boolean;
};

const orderFiltersIntialState: OrdersTableFilterType = {
  manualOrders: false,
  deliveryOrders: false,
  loadOrders: false,
  urgentOrders: false,
  recurringOrders: false,
  tankMonitorOrders: false,
  unassignedOrders: true,
  hub: '',
  city: '',
  zipCode: '',
  radiusFilter: {
    lat: '',
    lng: '',
    radius: '',
  },
  dueDate: new Date(),
  startDateOfDateRange: new Date(),
  endDateOfDateRange: new Date(),
};

const initialState: OrdersTableSliceReducerType = {
  orderFilters: orderFiltersIntialState, // all filters
  ordersTableSelectedOrders: [], // all selected rows of the order table
  deselectAllRows: false, // deselect all rows of the order table
  ordersTableSearchKeyword: '', // search keyword for the order table
  refetchOrdersTableData: false, // refetch order table data
};

const ordersTableSlice = createSlice({
  name: 'ordersTable',
  initialState: initialState,
  reducers: {
    setOrderFilters(state, action) {
      // set all filters
      state.orderFilters = action.payload;
    },
    resetOrderFilters(state) {
      // reset all filters
      state.orderFilters = orderFiltersIntialState;
    },
    setOrdersTableSelectedOrders(state, action) {
      // set selected orders
      state.ordersTableSelectedOrders = action.payload;
    },
    setOrdersTableDeselectAllRows(state, action) {
      // set deselect all rows
      state.deselectAllRows = action.payload;
    },
    setOrdersTableSearchKeyword(state, action) {
      // set search keyword
      state.ordersTableSearchKeyword = action.payload;
    },
    setRefetchOrdersTableData(state, action) {
      // set refetch order table data
      state.refetchOrdersTableData = action.payload;
    },
  },
});

export const {
  setOrderFilters,
  resetOrderFilters,
  setOrdersTableSelectedOrders,
  setOrdersTableDeselectAllRows,
  setOrdersTableSearchKeyword,
  setRefetchOrdersTableData,
} = ordersTableSlice.actions;

// eslint-disable-next-line import/no-default-export
export default ordersTableSlice.reducer;
