import { Box, BoxProps, useTheme } from '@chakra-ui/react';
import { AgGridReact } from 'ag-grid-react';
import { forwardRef } from 'react';

import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-balham.css';
import './style.css';

type GridProps = React.ComponentProps<typeof AgGridReact>;

type PandaGridProps = GridProps & {
  boxProps?: BoxProps;
};

const PandaGridInner: React.ForwardRefRenderFunction<AgGridReact, PandaGridProps> = (
  { boxProps, ...props },
  ref
) => {
  const theme = useTheme();

  return (
    <Box
      className="ag-theme-balham-dark"
      {...boxProps}
      sx={{
        '--ag-background-color': theme.colors.gray[475],
        '--ag-row-background-color': theme.colors.gray[700],
        '--ag-odd-row-background-color': theme.colors.gray[700],
        '--ag-row-hover-color': theme.colors.gray[450],
        '--ag-border-color': theme.colors.gray[450],
        '--ag-row-border-color': theme.colors.gray[450],
        '--ag-header-background-color': theme.colors.gray[850],
        '&&': {
          '.ag-root-wrapper': {
            border: 'none',
            cursor: 'pointer',
          },
          '.ag-body-vertical-scroll, .ag-body-vertical-scroll-viewport': {
            width: '10px !important',
            maxWidth: '10px !important',
            minWidth: '10px !important',
          },
          '.ag-body-horizontal-scroll, .ag-body-horizontal-scroll-viewport': {
            height: '10px !important',
            maxHeight: '10px !important',
            minHeight: '10px !important',
          },
          '.ag-row': {
            borderTop: 'none',
            borderX: 'none',
            backgroundColor: theme.colors.gray[700],
            '&-hover': {
              backgroundColor: theme.colors.gray[450],
            },
          },
        },
        ...boxProps?.sx,
      }}
    >
      <AgGridReact {...props} ref={ref} />
    </Box>
  );
};

PandaGridInner.displayName = 'PandaGrid';

export const PandaGrid = forwardRef(PandaGridInner);

export type PandaGrid = typeof PandaGrid;
