/**
 * DEPRECATED: Please consult with the FE Performance team before using this file.
 */
import { POPULATE_ASSETS } from '../actions/types';

const initialState = {
  list: [],
};

export default function assetReducer(state = initialState, { type, payload }) {
  switch (type) {
    case POPULATE_ASSETS: {
      return {
        ...state,
        list: payload,
      };
    }
    default:
      return state;
  }
}
