/**
 * DEPRECATED: Please consult with the FE Performance team before using this file.
 */
import { combineReducers } from 'redux';
import { firestoreReducer } from 'redux-firestore';

import orderDetailsReducers from '../components/OrderDetails/store/reducers';
import dashboardReducers from '../containers/Dashboard/reducers/index';
import ordersReducers from '../containers/Orders/reducers/index';
import shiftsReducers from '../containers/Shifts/reducers/index';
import shiftBoardReducer from '../containers/Shifts/reducers/shiftboard_reducer';
import activityReducer from './activityReducer';
import alertReducer from './alertReducer';
import assetReducer from './assetReducer';
import customerReducer from './customerReducer';
import driverReducer from './driverReducer';
import filterReducer from './filterReducer';
import firestoreLocalReducer from './firestoreLocalReducer';
import formReducer from './formReducer';
import pusherReducer from './pusherReducer';
import qrCodeReducer from './qrCodeReducer';
import resetDispatchReducer from './resetDispatchReducer';
import recordsReducer from '../containers/Records/reducers/index';
import settingReducer from './settingReducer';
import currentReducer from './currentReducer';

import { routeBuilderApis } from '../../features/routeBuilder/store/services/routeBuilderApis';
import bulkPlantInventoryDetailsReducers from 'features/orders/components/BulkPlantInventoryDetails/store/reducers';
import routeBuilderReducer from '../../features/routeBuilder/store/routeBuilderSlice';

const appReducer = combineReducers({
  activityReducer,
  alerts: alertReducer,
  assets: assetReducer,
  customers: customerReducer,
  current: currentReducer,
  dashboard: dashboardReducers,
  drivers: driverReducer,
  filterReducer,
  firestore: firestoreReducer,
  firestoreLocal: firestoreLocalReducer,
  formReducer,
  orderDetails: orderDetailsReducers,
  ordersReducer: ordersReducers,
  recordsReducer,
  pusher: pusherReducer,
  qrCodeReducer,
  resetDispatch: resetDispatchReducer,
  settings: settingReducer,
  shiftsReducer: shiftsReducers,
  bulkPlantReducer: bulkPlantInventoryDetailsReducers,
  shiftBoard: shiftBoardReducer,
  routeBuilder: routeBuilderReducer,
  [routeBuilderApis.reducerPath]: routeBuilderApis.reducer,
});

const rootReducer = (state, action) => {
  // Clear all data in redux store to initial.
  if (action.type === 'DESTROY_REDUX_SESSION') state = {};

  return appReducer(state, action);
};
export default rootReducer;
