/**
 * DEPRECATED: Please consult with the FE Performance team before using this file.
 */
export default `
  id
  name
  appData {
    appBuild
    appOta
    appVersion
    batteryLevel
    brand
    ip
    isLocationEnabled
    isLocationPermissionEnabled
    lowPowerMode
    mac
    model
    os
    osVersion
  }
`;
