/**
 * DEPRECATED: Please consult with the FE Performance team before using this file.
 */
import * as workers from '../workers/shiftboard_workers';

export const SET_BOARD_LOADING = '@shiftBoard/SET_BOARD_LOADING';

export const SET_BOARD_ERRORS = '@shiftBoard/SET_BOARD_ERRORS';

export const SET_TOTAL_SHIFTS = '@shiftBoard/SET_TOTAL_SHIFTS';
export const SET_BOL_ERRORS = '@shifts/SET_BOL_ERRORS';

export const SET_FILTERED_SHIFTS = '@shiftBoard/SET_FILTERED_SHIFTS';

export const SET_BOARD_FILTERING = '@shiftBoard/SET_BOARD_FILTERING';
export const SET_BOARD_UPDATING = '@shiftBoard/SET_BOARD_UPDATING';

export const SET_SEARCH_KEYWORD = '@shiftBoard/SET_SEARCH_KEYWORD';
export const SET_SELECTED_LOS_LIST = '@shiftBoard/SET_SELECTED_LOS_LIST';
export const SET_TOTAL_LOS_LIST = '@shiftBoard/SET_TOTAL_LOS_LIST';

export const HIGHLIGHT_CARD_ID = '@shiftBoard/HIGHLIGHT_CARD_ID';

export const FETCH_STATE_SHIFTS = '@shiftBoard/FETCH_STATE_SHIFTS';
export const SET_ALL_STATE_SHIFTS_LOADING = '@shiftBoard/SET_ALL_STATE_SHIFTS_LOADING';
export const ADD_SHIFT_TO_SHIFT_STATE = '@shiftBoard/ADD_SHIFT_TO_SHIFT_STATE';
export const SET_SELECTED_FILTER_LIST = '@shiftBoard/SET_SELECTED_FILTER_LIST';

export const fetchShiftStates = (state) => workers.fetchStateShifts(state);
export const filterAllShifts = (searchText, filterParam, commonCarrierFilter) =>
  workers.filterAllShifts(searchText, filterParam, commonCarrierFilter);
export const fetchBOLErrors = (status) => workers.fetchBOLErrors(status);
export const updateShiftBoardColumnTitle = (columnId, newTitle) =>
  workers.updateShiftBoardColumnTitle(columnId, newTitle);
export const updateShiftStatus = (payload) => workers.updateShiftStatus(payload);
export const clearShiftReconciliationData = (payload) =>
  workers.clearShiftReconciliationData(payload);

export const setSearchKeyword = (payload) => workers.setSearchKeyword(payload);
export const setTotalLosList = (payload) => workers.setTotalLosList(payload);
export const setSelectedLosList = (payload) => workers.setSelectedLosList(payload);

export const fetchShiftById = (id) => workers.fetchShiftById(id);
export const setSelectedFilterList = (payload) => workers.setSelectedFilterList(payload);

export const pusherShiftUpdate = (payload) => workers.pusherShiftUpdate(payload);
export const bulkUpdatePricingsOfShfts = (status) => workers.bulkUpdatePricingsOfShfts(status);
