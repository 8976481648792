import { Box } from '@chakra-ui/layout';

type NoRowOverlayComponentProps = {
  message?: string;
};

const style = {
  padding: '10px',
  border: '2px solid',
  borderColor: 'black.950',
  backgroundColor: 'black.900',
  color: 'gray.65',
};

export const NoRowOverlayComponent = ({
  message = 'No Data Found.',
}: NoRowOverlayComponentProps) => {
  return (
    <Box as={'span'} sx={style}>
      {message}
    </Box>
  );
};
