import { Box, Tooltip, useToast } from '@chakra-ui/react';
// import { captureException } from '@sentry/react';
import { useCallback, useMemo, useState } from 'react';

import { SearchInput } from 'components/SearchInput';
import { setAuthToken, useCurrent } from 'features/auth';
import { useSwitchTenantMutation } from 'generated/graphql';
import { COMMONS } from 'globalConstants';
import { apolloClient } from 'lib/apollo';

import { TenantDropdown } from './TenantDropdown';
import { TopNavbarDropdownItemLink } from './TopNavbarDropdownItemLink';

const { SUCCESS, ERROR } = COMMONS;

interface TenantProps {
  customers: { id: string; name?: string | null }[];
}

export const TopNavbarTenant = ({ customers }: TenantProps) => {
  const { data } = useCurrent();

  const toast = useToast();

  const [loading, setLoading] = useState(false);
  const [searchInput, setSearchInput] = useState('');

  const [switchTenant] = useSwitchTenantMutation();

  const handleTenantSwitch = useCallback(
    async (customerId: string) => {
      try {
        setLoading(true);

        const response = await switchTenant({
          variables: { input: { toCustomerId: customerId } },
        });

        const accessToken = response.data?.switchTenant?.accessToken;

        if (!accessToken) {
          throw new Error('No access token');
        } else {
          toast({
            title: 'Tenant Switch',
            description: 'You have successfuly switched tenants',
            status: SUCCESS,
            position: 'top-right',
            isClosable: true,
          });
        }

        setAuthToken(accessToken);

        try {
          if (
            window.location.pathname.includes('shift_boards') ||
            window.location.pathname.includes('dashboard') ||
            window.location.pathname === '/'
          )
            window.location.reload();
          await apolloClient.resetStore();
        } catch (err) {
          // Let each query handle its own error
        }
      } catch (err) {
        toast({
          title: 'Error switching tenant',
          description: 'Please try again later',
          status: ERROR,
          position: 'top-right',
          isClosable: true,
        });
      } finally {
        setLoading(false);
      }
    },
    [switchTenant, toast]
  );

  const handleSearchInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInput(event.target.value);
  };

  const filteredCustomers = useMemo(() => {
    return customers.filter(
      (customer) =>
        customer.id !== data?.current?.customer?.id &&
        customer?.name?.match(new RegExp(searchInput, 'i'))
    );
  }, [customers, data, searchInput]);

  if (customers.length > 1 && !loading) {
    return (
      <TenantDropdown
        handleMenuClose={() => {
          setSearchInput('');
        }}
      >
        <Box
          position="sticky"
          p="8px"
          top="0px"
          backgroundColor="gray.550"
          borderBottom="1px solid"
          borderBottomColor="gray.300"
        >
          <SearchInput
            value={searchInput}
            onChange={handleSearchInput}
            backgroundColor={'gray.750'}
            placeholder="Search tenant"
            _placeholder={{ color: 'gray.100' }}
            maxW={'8rem'}
          />
        </Box>
        {filteredCustomers.map((customer) => (
          <TopNavbarDropdownItemLink
            key={customer.id}
            href=""
            onClick={(e) => {
              e.preventDefault();
              setSearchInput('');
              handleTenantSwitch(customer.id);
            }}
          >
            <Tooltip label={customer.name} placement="right" openDelay={500}>
              <Box w="28">{customer.name?.substring(0, 3).toUpperCase()}</Box>
            </Tooltip>
          </TopNavbarDropdownItemLink>
        ))}
        {!filteredCustomers.length && (
          <Box p={'0.75rem'} color="gray.400">
            No Tenant Found
          </Box>
        )}
      </TenantDropdown>
    );
  }
  return null;
};
