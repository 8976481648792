/* eslint-disable */
/**
 * DEPRECATED: Please consult with the FE Performance team before using this file.
 */

import * as actions from '../actions';

import { fetchGraphQLData } from '../../../graphql';
import Helpers from '../../../helpers';

import { SHIFT_FRAGMENT } from 'javascript/schemas/fragments';
import { appendAllowedState } from './utils/appendAllowedState';
import { handleErrors } from 'error/handleErrors';
import { SENTRY_FEATURE } from 'types/sentryFeatures';

/**
 * Sets Current Timestamp
 *
 * @returns {function}
 */
export const setCurrentTimestamps =
  (fromTimestamp, toTimestamp, limit = null) =>
  (dispatch, getState) => {
    dispatch({
      type: actions.SET_TIMESTAMPS,
      payload: { fromTimestamp, toTimestamp },
    });
  };

export const setShiftPagination =
  (data, shouldFetch = true) =>
  (dispatch) => {
    dispatch({
      type: actions.SET_SHIFT_PAGINATION,
      payload: data,
    });

    if (shouldFetch) {
      dispatch(fetchShifts());
    }
  };

export const fetchShiftStates = () => async (dispatch) => {
  try {
    const response = await fetchGraphQLData(JSON.stringify({ query: '{shiftStates}' }));
    if (!response?.data?.shiftStates) {
      throw new Error('Error fetching shift states');
    }

    const fetchedshiftStates = response.data?.shiftStates;

    dispatch({
      type: actions.FETCH_SHIFT_STATES,
      payload: appendAllowedState(fetchedshiftStates),
    });
  } catch (error) {
    handleErrors(error, {
      fallbackMsg: 'Error fetching shift states',
      tagName: SENTRY_FEATURE.SHIFT,
    });
  }
};

export const refetchShifts = () => (dispatch) => {
  dispatch({
    type: actions.REFETCH_SHIFT,
    payload: true,
  });
};

/**
 * List minimal information of shifts for listing
 *
 * @returns {function}
 */
export const fetchShifts =
  (filter = {}) =>
  async (dispatch, getState) => {
    const fromTimestamp = getState().shiftsReducer.fromTimestamp;
    const toTimestamp = getState().shiftsReducer.toTimestamp;
    const pagination = getState().shiftsReducer?.shiftPagination;

    dispatch({
      type: actions.FETCHING_SHIFTS,
      payload: true,
    });

    try {
      const QUERY = `
      {
        filteredShifts(limit: ${pagination?.limit || 30},offset: ${
        pagination?.offset || 0
      }, filter: { fromTimestamp: ${fromTimestamp},toTimestamp: ${toTimestamp}})
        {
          collection {
            ${SHIFT_FRAGMENT}
          }
          metadata {
            hasMore
            totalCount
            total_entries
          }
        }
      }
    `;
      const response = await fetchGraphQLData(JSON.stringify({ query: QUERY }));
      if (!response?.data?.filteredShifts) {
        throw new Error(`Error fetching shift from time range ${fromTimestamp} to ${toTimestamp}`);
      }
      const fetchedShifts = response.data?.filteredShifts;

      dispatch({
        type: actions.FETCH_SHIFTS,
        payload: Helpers.convertArrayToHash(fetchedShifts?.collection || [], 'shifts'),
      });

      dispatch({
        type: actions.SET_SHIFT_METADATA,
        payload: fetchedShifts?.metadata,
      });

      dispatch({
        type: actions.FETCHING_SHIFTS,
        payload: false,
      });
    } catch (error) {
      handleErrors(error, {
        fallbackMsg: `Error fetching shift from time range ${fromTimestamp} to ${toTimestamp}`,
        tagName: SENTRY_FEATURE.SHIFT,
        additionalArgs: {
          shift: { fromTimestamp, toTimestamp },
        },
      });
    }
  };

/**
 * List minimal information of a shift for refining/adding records in listing
 *
 * @returns {function}
 */
export const fetchShift = (id) => async (dispatch) => {
  try {
    const QUERY = `
      {
        shift(id: ${id}) {
          ${SHIFT_FRAGMENT}
        }
      }
    `;
    const response = await fetchGraphQLData(JSON.stringify({ query: QUERY }));
    if (!response?.data?.shift) {
      throw new Error(`Error fetching shift with id: ${id}`);
    }
    const fetchedShift = response.data?.shift;
    if (!fetchedShift) return;

    dispatch({
      type: actions.FETCH_SHIFT,
      payload: Helpers.convertArrayToHash([fetchedShift], 'shifts'),
    });
  } catch (error) {
    handleErrors(error, {
      fallbackMsg: `Error fetching shift with id: ${id}`,
      tagName: SENTRY_FEATURE.SHIFT,
      additionalArgs: { shiftId: id },
    });
  }
};

/**
 * Sets order details modal
 *
 * @returns {function}
 */
export const setShiftIdModal = (shiftId) => (dispatch) => {
  dispatch({
    type: actions.SET_SHIFT_ID_MODAL,
    payload: shiftId,
  });
};

/**
 * fetch LOS list
 *
 * @returns {function}
 */
export const fetchLos = () => async (dispatch) => {
  try {
    const QUERY = `
    {
      lineOfServices {
        id
        name
        shortName
      }
    }`;
    const response = await fetchGraphQLData(JSON.stringify({ query: QUERY }));
    if (!response?.data?.lineOfServices) {
      throw new Error('Error fetching LOS');
    }
    dispatch({
      type: actions.FETCH_LOS,
      payload: response?.data?.lineOfServices || [],
    });
    return response.data.lineOfServices;
  } catch (error) {
    handleErrors(error, {
      fallbackMsg: 'Error fetching LOS',
      tagName: SENTRY_FEATURE.SHIFT,
    });
  }
};
