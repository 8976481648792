import {
  forwardRef,
  Link,
  useMultiStyleConfig,
  type As,
  type HTMLChakraProps,
} from '@chakra-ui/react';

import { FAIcon } from 'components/FAIcon';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type TopNavbarItemLinkIconProps<T extends As<any>> = HTMLChakraProps<T> & {
  variant?: 'solid' | 'regular' | 'light' | 'duotone';
  icon: string;
  alt?: string;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const TopNavbarItemLinkIconInner = <T extends As<any>>(
  { variant = 'regular', icon, alt, as = 'a', sx, ...props }: TopNavbarItemLinkIconProps<T>,
  ref: React.Ref<HTMLAnchorElement>
) => {
  const { 'item-icon': styles } = useMultiStyleConfig('TopNavbar', {
    variant,
  });

  return (
    <Link as={as} ref={ref} data-testid="top-navbar-item" sx={{ ...styles, ...sx }} {...props}>
      <FAIcon icon={icon} variant={variant} alt={alt} />
    </Link>
  );
};

TopNavbarItemLinkIconInner.displayName = 'TopNavbarItemLinkIcon';

export const TopNavbarItemLinkIcon = forwardRef(TopNavbarItemLinkIconInner) as <
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  T extends As<any>
>(
  props: React.PropsWithoutRef<TopNavbarItemLinkIconProps<T>> & React.RefAttributes<HTMLDivElement>
) => JSX.Element;

export type TopNavbarItemLinkIcon = typeof TopNavbarItemLinkIcon;
