/**
 * DEPRECATED: Please consult with the FE Performance team before using this file.
 */
import { RESET_DISPATCH } from '../actions/types';

const initialState = {
  status: false,
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case RESET_DISPATCH:
      return {
        ...state,
        status: payload,
      };
    default:
      return state;
  }
}
