/**
 * DEPRECATED: Please consult with the FE Performance team before using this file.
 */
import { FETCH_CUSTOMERS } from '../actions/types';

const initialState = {
  data: [],
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case FETCH_CUSTOMERS:
      return {
        ...state,
        data: payload,
      };
    default:
      return state;
  }
}
