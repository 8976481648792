import * as Sentry from '@sentry/react';
import { AnyObject } from 'yup';

export const logException = (
  err: AnyObject,
  contextMsg = { msg: 'No context provided' },
  additionalInfo: AnyObject = {},
  tagName?: string
) => {
  const scope = new Sentry.Scope();

  if (tagName) {
    scope.setTag('feature', tagName);
  }

  scope.setContext('contextMsg', contextMsg);
  scope.setContext('additionalInfo', additionalInfo);

  Sentry.captureException(err, scope);
};

export const flattenObject = (obj: AnyObject, prefix = '') => {
  const result: AnyObject = {};

  for (const key in obj) {
    const value = obj[key];
    const newKey = prefix ? `${prefix}.${key}` : key;

    if (typeof value === 'object' && value !== null) {
      Object.assign(result, flattenObject(value, newKey));
    } else {
      result[newKey] = value;
    }
  }

  return result;
};
