/**
 * DEPRECATED: Please consult with the FE Performance team before using this file.
 */
import { mutation } from 'gql-query-builder';
import toastr from 'toastr';
import mutate from 'dot-prop-immutable';
import { cloneDeep } from 'lodash';
import { merge } from 'lodash-es';

import * as actions from '../actions/shiftboard_actions';
import { fetchShiftStates } from '../workers/index';
import { fetchGraphQLData, fetchGraphQLDataMutation } from '../../../graphql';
import Helpers from '../../../helpers';
import {
  SHIFT_BOARD_BOL_ERRORS_FRAGMENT,
  SHIFT_BOARD_FRAGMENT_WITH_BOL_ERROR,
} from './const/fragments';
import { FILTERED_SHIFTS_BOL_ERRORS_LIMIT, FILTERED_SHIFTS_LIMIT } from './const';
import { formatBOLErrorsForShift } from './utils/formatBOLErrorsForShift';
import { DATETIME_CONSTANTS } from '../../../utils/datetimeConstants';
import { CommonCarrierDriverName, CommonCarrierFilterEnum } from '../const/shift';
import { SHIFT_BOARD_FRAGMENT } from 'javascript/schemas/fragments';
import { handleErrors } from 'error/handleErrors';
import { SENTRY_FEATURE } from 'types/sentryFeatures';

const { MMM_DD_HH_MM } = DATETIME_CONSTANTS;

export const bulkUpdatePricingsOfShfts = (status) => async (dispatch, getState) => {
  if (!status) return;
  // Allow Bulk Update of pricing for completed orders only for now
  // later we can do for other order statuses as well
  if (!['completed'].includes(status)) return;

  const statusShift = getState().shiftBoard?.totalShiftData?.find((item) => item.name === status);
  const isShiftColumnLoading = statusShift?.isFetching;
  if (isShiftColumnLoading) {
    toastr.error('Shifts are loading, Please try again after all shifts are loaded');
    return;
  }
  const statusShiftIds = statusShift?.shiftIds;
  if (!statusShiftIds) {
    toastr.error('No Shifts found for selected status');
    return;
  }

  const response = await fetchGraphQLDataMutation(
    JSON.stringify(
      mutation({
        operation: 'bulkUpdatePricings',
        variables: {
          input: {
            type: 'BulkUpdatePricingsInput',
            required: true,
            value: {
              shiftIds: statusShiftIds,
            },
          },
        },
        fields: ['success'],
      })
    )
  );
  if (response?.data?.bulkUpdatePricings?.success) {
    toastr.success('Update Pricing For shifts queue started successfully');
  } else {
    toastr.error('Failed to start queue for pricing update of selected shifts');
  }
};

export const fetchShiftById = (id) => async (dispatch, getState) => {
  try {
    const QUERY = `
      {
        shift(id: ${id}){
          ${SHIFT_BOARD_FRAGMENT_WITH_BOL_ERROR}
        }
      }
      `;
    const response = await fetchGraphQLData(JSON.stringify({ query: QUERY }));
    if (!response?.data?.shift) {
      console.error('fetchShiftById', response);
      throw new Error(`Error fetching shift with id: ${id}`);
    }
    const shiftObj = formatShift(response?.data?.shift);

    if (shiftObj) {
      const oldTotalShiftData = getState().shiftBoard.totalShiftData;
      const tempOldTotalShiftData = [...JSON.parse(JSON.stringify(oldTotalShiftData))];

      const hasShiftDataById = tempOldTotalShiftData?.filter((column, colIndex) => {
        if (column?.shifts?.length) {
          return !!column?.shifts?.filter((shiftCard) => {
            return parseInt(shiftObj?.id) === parseInt(shiftCard?.id);
          }).length;
        } else {
          return false;
        }
      });

      if (!hasShiftDataById.length) {
        const tempTotalShiftData = tempOldTotalShiftData?.map((indvShiftData) =>
          indvShiftData.state === shiftObj.state
            ? { ...indvShiftData, shifts: [...indvShiftData.shifts, shiftObj] }
            : indvShiftData
        );
        dispatch({
          type: actions.SET_TOTAL_SHIFTS,
          payload: tempTotalShiftData,
        });
      }
      dispatch(updateShiftStatusOfCard(shiftObj.id, shiftObj.state, shiftObj));
    }
  } catch (error) {
    handleErrors(error, {
      fallbackMsg: `Error fetching shift with id: ${id}`,
      tagName: SENTRY_FEATURE.SHIFT_BOARD,
      additionalArgs: {
        shiftId: id,
      },
    });
  }
};

export const fetchStateShifts = (status) => async (dispatch, getState) => {
  let response;
  let offset = 0;

  try {
    const shiftStates = getState().shiftsReducer.shiftStates;
    const searchKeyword = getState().shiftBoard.searchKeyword;
    const selectedFilterItems = getState().shiftBoard.selectedFilterItems;

    const fromTimestamp = getState().shiftsReducer.fromTimestamp;
    const toTimestamp = getState().shiftsReducer.toTimestamp;

    const shiftState = shiftStates[status];
    if (shiftState) {
      dispatch({
        type: actions.SET_ALL_STATE_SHIFTS_LOADING,
        payload: {
          ...shiftState,
          title: Helpers.humanize(status),
          state: status,
          isFetching: true,
          shifts: [],
        },
      });

      const fetchPaginatedShifts = async () => {
        const QUERY = `
        {
          filteredShifts (
            limit: ${FILTERED_SHIFTS_LIMIT},
            offset: ${offset},
            filter: {
              states:["${status}"],
              fromTimestamp: ${fromTimestamp},
              toTimestamp: ${toTimestamp}
            }
          ){
            collection {
              ${SHIFT_BOARD_FRAGMENT}
            },
            metadata {
              hasMore
              totalCount
              total_entries
            }
          }
        }
      `;

        response = await fetchGraphQLData(JSON.stringify({ query: QUERY }));

        const filteredShifts = response?.data?.filteredShifts;

        if (!filteredShifts) {
          throw new Error(`Error fetching shift states: ${status}`);
        }
        offset += FILTERED_SHIFTS_LIMIT;

        const formattedShifts = await formatShifts(filteredShifts.collection);

        dispatch({
          type: actions.ADD_SHIFT_TO_SHIFT_STATE,
          payload: {
            shifts: formattedShifts,
            state: status,
            fromTimestamp,
            toTimestamp,
            metadata: filteredShifts.metadata,
          },
        });
        // if there is search keyword or filter items then again dispatch a filter method
        // this will enable filtering/searching even if dates change
        if (searchKeyword || selectedFilterItems) {
          dispatch(filterAllShifts(searchKeyword, selectedFilterItems));
        }

        if (response?.data?.filteredShifts?.metadata?.hasMore) {
          await fetchPaginatedShifts();
        }
      };

      fetchPaginatedShifts();
    }
  } catch (error) {
    handleErrors(error, {
      fallbackMsg: `Error fetching shift states: ${status}`,
      tagName: SENTRY_FEATURE.SHIFT_BOARD,
      additionalArgs: {
        states: status,
        fromTimestamp: getState().shiftsReducer.fromTimestamp,
        toTimestamp: getState().shiftsReducer.toTimestamp,
      },
    });
  }
};

export const fetchBOLErrors = (status) => async (dispatch, getState) => {
  let offset = 0;

  try {
    if (status) {
      const fetchPaginatedShiftsBOLErrors = async () => {
        const fromTimestamp = getState().shiftsReducer.fromTimestamp;
        const toTimestamp = getState().shiftsReducer.toTimestamp;
        const QUERY = `
        {
          filteredShifts (
            limit: ${FILTERED_SHIFTS_BOL_ERRORS_LIMIT},
            offset: ${offset},
            filter: {
              states:["${status}"],
              fromTimestamp: ${fromTimestamp},
              toTimestamp: ${toTimestamp}
            }
          ){
            collection {
              ${SHIFT_BOARD_BOL_ERRORS_FRAGMENT}
            },
            metadata {
              hasMore
              totalCount
              total_entries
            }
          }
        }
      `;

        const response = await fetchGraphQLData(JSON.stringify({ query: QUERY }));
        const filteredShifts = response?.data?.filteredShifts;

        const formattedShiftsBOLErrors = filteredShifts.collection.map((shift) =>
          formatBOLErrorsForShift(shift)
        );

        if (!filteredShifts) {
          throw new Error(`Error fetching shift states: ${status}`);
        }

        dispatch({
          type: actions.SET_BOL_ERRORS,
          payload: {
            shifts: formattedShiftsBOLErrors,
            state: status,
            fromTimestamp,
            toTimestamp,
            metadata: filteredShifts.metadata,
          },
        });

        if (
          response?.data?.filteredShifts?.metadata?.hasMore &&
          fromTimestamp === getState().shiftsReducer.fromTimestamp &&
          toTimestamp === getState().shiftsReducer.toTimestamp
        ) {
          offset += FILTERED_SHIFTS_BOL_ERRORS_LIMIT;
          await fetchPaginatedShiftsBOLErrors();
        }
      };
      fetchPaginatedShiftsBOLErrors();
    }
  } catch (error) {
    handleErrors(error, {
      tagName: SENTRY_FEATURE.SHIFT_BOARD,
      additionalArgs: {},
    });
  }
};

export const formatShifts = async (shiftsArr) => {
  const formattedShifts = [];

  shiftsArr.forEach((shift) => {
    const shiftObj = formatShift(shift);
    formattedShifts.push(shiftObj);
  });
  return formattedShifts;
};

export const formatShift = (shift, dataSource = 'graphql') => {
  const assets = {};
  if (shift?.shiftDeliveryUnitsAssets) {
    const shiftDeliveryUnitsAssets = shift.shiftDeliveryUnitsAssets || [];
    shiftDeliveryUnitsAssets.forEach((deliveryUnit) => {
      if (deliveryUnit.deliveryAsset) {
        assets[deliveryUnit.id] = deliveryUnit.deliveryAsset;
      }
    });
  }

  let poNumber = {};
  if (shift?.orderGroups?.length) {
    shift?.orderGroups?.forEach((orderGroup) => {
      poNumber = { ...poNumber, [orderGroup.id]: orderGroup.poNumber };
    });
  }

  let carrierNumber = {};
  if (shift?.orderGroups?.length) {
    shift?.orderGroups?.forEach((orderGroup) => {
      carrierNumber = { ...carrierNumber, [orderGroup?.id]: orderGroup?.carrierNumber };

      if (orderGroup?.loadingOrder) {
        orderGroup?.loadingOrder?.loadingReceipts?.forEach((loadingReceipt) => {
          if (loadingReceipt?.loadingAccount) {
            // orderGroup => loadingOrder => multiple loadingReceipt
            // combo of orderId and receiptId is used to store in object
            const mergedId = `${orderGroup?.id}_${loadingReceipt.id}`;
            carrierNumber = {
              ...carrierNumber,
              [mergedId]: loadingReceipt.loadingAccount.carrierCustomerCode,
            };
          }
        });
      }
    });
  }

  let customers = {};
  if (shift?.orderGroups?.length) {
    shift?.orderGroups?.forEach((o) => {
      if (o.deliveryOrder) {
        const customer = o.deliveryOrder?.customerBranch || {};
        customer.type = 'Customer';
        customer.orderGroupId = o.id;
        if (customer) {
          customers = { ...customers, [o.id]: customer };
        }
      }
      if (o.loadingOrder) {
        const customer = o.loadingOrder?.terminal || {};
        customer.type = 'Terminal';
        customer.orderGroupId = o.id;
        if (customer) {
          customers = { ...customers, [o.id]: customer };
        }
      }
    });
  }

  let carriers = {};
  if (shift?.orderGroups?.length) {
    shift.orderGroups?.forEach((indvOrderGroup) => {
      if (indvOrderGroup?.ccTenantId && indvOrderGroup?.ccTenant?.name) {
        carriers = {
          ...carriers,
          [indvOrderGroup.ccTenantId]: indvOrderGroup?.ccTenant,
        };
      }
    });
  }

  const deliveryOrders = [];
  if (shift?.orderGroups?.length) {
    shift.orderGroups?.forEach((o) => {
      if (o.deliveryOrder) {
        deliveryOrders.push({ id: o.deliveryOrder?.id });
      }
    });
  }

  let tempBolNumbersList = {};

  if (shift?.orderGroups?.length) {
    shift.orderGroups?.forEach((o) => {
      if (o.loadingOrder) {
        if (o.loadingOrder.loadingReceipts?.length) {
          o.loadingOrder.loadingReceipts.forEach((receipt) => {
            // orderGroup => loadingOrder => multiple loadingReceipt
            // receipt id is used to store in object
            tempBolNumbersList = { ...tempBolNumbersList, [receipt.id]: receipt.receiptNumber };
          });
        }
      }
    });
  }

  let lineOfServices = {};
  if (shift?.orderGroups?.length) {
    shift.orderGroups?.forEach((indvOrderGrp) => {
      const reqLOS = indvOrderGrp.lineOfService?.shortName || indvOrderGrp.lineOfService?.name;

      lineOfServices = { ...lineOfServices, [indvOrderGrp.id]: reqLOS };
    });
  }

  let hubs = '';
  if (shift?.orderGroups?.length) {
    hubs = shift?.orderGroups
      ?.reduce((prev, curr) => {
        if (curr?.hub?.name) {
          return [...prev, curr.hub.name];
        } else {
          return [...prev];
        }
      }, [])
      .join(', ');
  }

  //  bol errors
  const { bolErrors, shiftStartVolumeError } = formatBOLErrorsForShift(shift);

  // final object that holds all data
  let shiftObj = {};

  if (dataSource === 'pusher') {
    shiftObj = {
      ...(shift?.state ? { state: shift.state } : {}),
      ...(shift?.id ? { id: shift.id.toString() } : {}),
      ...(shift?.errors ? { errors: shift.errors } : {}),
      ...(shift?.startOnTimestamp
        ? { startTime: Helpers.formatTimeStamp(shift.startOnTimestamp, MMM_DD_HH_MM) }
        : {}),
      ...(shift?.endOnTimestamp
        ? { endTime: Helpers.formatTimeStamp(shift.endOnTimestamp, MMM_DD_HH_MM) }
        : {}),
      ...(deliveryOrders?.length ? { deliveryOrders } : {}),
      ...(shift?.worker ? { driver: shift.worker } : {}),
      ...(Object.values(assets)?.length ? { assets } : {}),
      ...(Object.values(customers)?.length ? { customers } : {}),
      ...(Object.values(carriers)?.length ? { carriers } : {}),
      ...(Object.keys(poNumber)?.length ? { poNumber } : {}),
      ...(Object.keys(carrierNumber)?.length ? { carrierNumber } : {}),
      ...(Object.keys(tempBolNumbersList)?.length ? { bolNumbers: tempBolNumbersList } : {}),
      ...(bolErrors ? { bolErrors } : {}),
      ...(shiftStartVolumeError ? { shiftStartVolumeError } : {}),
      ...(Object.keys(lineOfServices)?.length ? { lineOfServices } : {}),
      ...(shift?.enableClearingAccountingData
        ? { enableClearingAccountingData: shift?.enableClearingAccountingData }
        : {}),
      hubs,
    };
  } else {
    shiftObj = {
      index: Helpers.createGuid(),
      state: shift.state,
      id: shift.id,
      errors: shift.errors,
      startTime: Helpers.formatTimeStamp(shift.startOnTimestamp, MMM_DD_HH_MM),
      endTime: Helpers.formatTimeStamp(shift.endOnTimestamp, MMM_DD_HH_MM),
      tags: [],
      deliveryOrders,
      driver: shift.worker,
      assets,
      customers,
      carriers,
      poNumber,
      carrierNumber,
      bolNumbers: tempBolNumbersList,
      bolErrors,
      shiftStartVolumeError,
      lineOfServices,
      enableClearingAccountingData: shift?.enableClearingAccountingData,
      hubs,
    };
  }

  return shiftObj;
};

export const checkIfCardMatchesFilterItem = (cardItem, filterParam) => {
  const reqData = filterParam.find((fparam) => {
    const hubCase = cardItem?.hubs?.toLowerCase()?.includes(fparam?.name?.toLowerCase());
    const driverHubCase = !!cardItem?.driver?.hubs?.filter(
      (indvHub) => indvHub?.name?.toLowerCase() === fparam?.name?.toLowerCase()
    )?.length;
    const driverCase = cardItem?.driver?.name?.toLowerCase()?.includes(fparam?.name?.toLowerCase());
    const losCase1 = Object.values(cardItem?.lineOfServices)
      ?.join(' ')
      ?.toLowerCase()
      ?.includes(fparam?.shortName?.toLowerCase());
    const losCase2 = Object.values(cardItem?.lineOfServices)
      ?.join(' ')
      ?.toLowerCase()
      ?.includes(fparam?.name?.toLowerCase());
    return hubCase || driverCase || losCase1 || losCase2 || driverHubCase;
  });

  return !!reqData;
};

// filtering workers
export const filterAllShifts =
  (searchKeyWord, filterParam, commonCarrierFilter) => async (dispatch, getState) => {
    const searchText = searchKeyWord?.toLowerCase() || '';
    const totalShiftData = getState().shiftBoard.totalShiftData;
    const tempTotalList = [...totalShiftData];

    if (
      searchText === '' &&
      !filterParam.length &&
      commonCarrierFilter?.value === CommonCarrierFilterEnum.ALL &&
      commonCarrierFilter === undefined
    ) {
      if (commonCarrierFilter === undefined) {
        return;
      }
      dispatch({
        type: actions.SET_FILTERED_SHIFTS,
        payload: tempTotalList,
      });

      return;
    }

    dispatch({
      type: actions.SET_BOARD_FILTERING,
      payload: true,
    });

    const tempFilteredList = filterAllShiftsList(
      tempTotalList,
      searchText,
      filterParam,
      commonCarrierFilter
    );
    dispatch({
      type: actions.SET_FILTERED_SHIFTS,
      payload: tempFilteredList,
    });
    dispatch({
      type: actions.SET_BOARD_FILTERING,
      payload: false,
    });
  };

const filterAllShiftsList = (mainList, searchKeyWord, filterParam, commonCarrierFilter) => {
  if (
    searchKeyWord === '' &&
    filterParam.length === 0 &&
    commonCarrierFilter?.value === CommonCarrierFilterEnum.ALL
  ) {
    return mainList;
  }

  const searchText = searchKeyWord?.toLowerCase() || '';
  const tempFilteredList = mainList.map((item) => {
    const tempColItem = { ...item };
    if (tempColItem.shifts.length > 0) {
      const result = tempColItem.shifts.filter((shift) => {
        const customerNames = Object.values(shift?.customers)?.map(
          (customerItem) => customerItem.name
        );

        const carrierNames =
          shift?.carriers &&
          Object.values(shift?.carriers)?.map((indvCarrier) => indvCarrier?.name);

        const case1 = Object.values(shift?.bolNumbers)
          ?.join(' ')
          .toLowerCase()
          .includes(searchKeyWord);
        const case2 = shift?.driver?.name?.toLowerCase().includes(searchText);
        const case3 = shift?.id?.includes(searchKeyWord);
        const case4 = customerNames?.join(' ').toLowerCase().includes(searchText);

        const assetNames = Object.values(shift?.assets)?.length
          ? Object.values(shift?.assets).map((asset) => asset.name)
          : [];
        let case5 = false;
        if (assetNames.length) {
          case5 = assetNames?.join(' ').toLowerCase().includes(searchText);
        }

        const case6 = Object.values(shift?.poNumber)?.join(' ').toLowerCase().includes(searchText);
        const case7 = Object.values(shift?.carrierNumber)
          ?.join(' ')
          .toLowerCase()
          .includes(searchText);

        const case8 = shift?.hubs?.toLowerCase()?.includes(searchText);
        const case9 = carrierNames?.join(' ').toLowerCase().includes(searchText);

        return !!(case1 || case2 || case3 || case4 || case5 || case6 || case7 || case8 || case9);
      });

      // Apply common carrier filter
      const shiftWithCommonCarrier = commonCarrierFilter
        ? result.filter((shift) =>
            shouldFilterCommonCarrier(shift?.driver?.name?.toLowerCase(), commonCarrierFilter)
          )
        : result;

      const filteredShift = filterParam?.length
        ? shiftWithCommonCarrier.filter((shift) => {
            const filterItemCase = !!checkIfCardMatchesFilterItem(shift, filterParam);

            return filterItemCase;
          })
        : shiftWithCommonCarrier;

      tempColItem.shifts = [...filteredShift];
    }
    return tempColItem;
  });

  return tempFilteredList;
};

/**
 *  Filter out common carrier shifts
 *
 * @param {string} driverName Driver name to filter
 * @param {object} commonCarrierFilter selected filter for shift filter out
 * @returns boolean
 */
const shouldFilterCommonCarrier = (driverName, commonCarrierFilter) => {
  if (commonCarrierFilter.value === CommonCarrierFilterEnum.COMMON_CARRIER_SHIFTS) {
    return driverName === CommonCarrierDriverName;
  } else if (commonCarrierFilter.value === CommonCarrierFilterEnum.NORMAL_SHIFTS) {
    return driverName !== CommonCarrierDriverName;
  } else {
    return true;
  }
};

// Update shift board column title
export const updateShiftBoardColumnTitle = (columnId, newTitle) => async (dispatch, getState) => {
  try {
    const totalShiftData = getState().shiftBoard.totalShiftData;
    const filteredShiftData = getState().shiftBoard.filteredShiftData;

    dispatch({
      type: actions.SET_BOARD_UPDATING,
      payload: true,
    });

    const mutation = `
        mutation updateColumnTitle($title: String!) {
          updateSettings(
            input: {
              data: {
                shift_status_id_${columnId}: $title
              }
            }
          )
          {
            settings{
              id
              name
              value
            }
          }
        }
      `;

    const response = await fetchGraphQLDataMutation(
      JSON.stringify({ query: mutation, variables: { title: newTitle } })
    );

    if (response.error?.message || (response.errors && response.errors.length)) {
      toastr.error(response.error?.message || response.errors[0].message);
    } else {
      const updatedTotalShiftData = totalShiftData.map((col) => {
        if (col.id === columnId) {
          return {
            ...col,
            title: newTitle,
          };
        }
        return col;
      });
      const updatedFilteredShiftData = filteredShiftData.map((col) => {
        if (col.id === columnId) {
          return {
            ...col,
            title: newTitle,
          };
        }
        return col;
      });
      dispatch({
        type: actions.SET_TOTAL_SHIFTS,
        payload: updatedTotalShiftData,
      });
      dispatch({
        type: actions.SET_FILTERED_SHIFTS,
        payload: updatedFilteredShiftData,
      });
    }
    dispatch(fetchShiftStates());
    dispatch({
      type: actions.SET_BOARD_UPDATING,
      payload: false,
    });
  } catch (error) {
    handleErrors(error, {
      fallbackMsg: 'Could not update shift column title',
      tagName: SENTRY_FEATURE.SHIFT_BOARD,
      additionalArgs: {
        [`shift_status_id_${columnId}`]: newTitle,
      },
    });
  }
};

// drop action
export const updateShiftStatus =
  ({ state, setState, destination, source, draggableId }) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: actions.SET_BOARD_UPDATING,
        payload: true,
      });

      const currentState = { ...state };
      Object.freeze(currentState);

      const destinationCol = currentState?.entities.columns[destination.droppableId];

      let nextState = mutate.delete(
        currentState,
        `entities.columns.${source.droppableId}.shifts.${source.index}`
      );
      const destinationShifts = mutate.get(
        nextState,
        `entities.columns.${destination.droppableId}.shifts`
      );
      destinationShifts.splice(destination.index, 0, draggableId);
      nextState = mutate.set(
        nextState,
        `entities.columns.${destination.droppableId}.shifts`,
        destinationShifts
      );
      setState(nextState);

      const mutation = `
        mutation {
          updateShift(input: {
            shiftAttributes: {
              id: ${Number(draggableId)},
              state: ${destinationCol.state.toUpperCase()}
            }
          })
          {
            shift {
              id
            }
          }
        }
      `;

      const response = await fetchGraphQLDataMutation(JSON.stringify({ query: mutation }));
      if (response.error?.message || (response.errors && response.errors.length)) {
        let revertState = mutate.delete(
          nextState,
          `entities.columns.${destination.droppableId}.shifts.${destination.index}`
        );
        const sourceShifts = mutate.get(
          revertState,
          `entities.columns.${source.droppableId}.shifts`
        );
        sourceShifts.splice(source.index, 0, draggableId);
        revertState = mutate.set(
          revertState,
          `entities.columns.${source.droppableId}.shifts`,
          sourceShifts
        );
        setState(revertState);

        const errorMessage = response.error?.message || response.errors[0].message;
        toastr.error(errorMessage);
      } else {
        toastr.success(`Shift ID #${draggableId} updated`);
      }
      dispatch({
        type: actions.SET_BOARD_UPDATING,
        payload: false,
      });
    } catch (error) {
      handleErrors(error, {
        fallbackMsg: 'Could not update shift status',
        tagName: SENTRY_FEATURE.SHIFT_BOARD,
        additionalArgs: {
          shiftAttributes: {
            id: Number(draggableId),
          },
        },
      });
    }
  };

// update worker , internally works to update everything
export const updateShiftStatusOfCard =
  (cardItemId, destination, shift) => async (dispatch, getState) => {
    let totalShiftData = [];

    const originalList = getState().shiftBoard.totalShiftData;
    const selectedFilterItems = getState().shiftBoard.selectedFilterItems;
    const searchKeyword = getState().shiftBoard.searchKeyword;

    totalShiftData = [...originalList];

    let srcCol = {};
    let srcColIndex = 0;
    let destCol = {};
    let destColIndex = 0;

    totalShiftData?.forEach((column, colIndex) => {
      column?.shifts?.length &&
        column?.shifts?.forEach((shiftCard) => {
          if (parseInt(shiftCard?.id) === parseInt(cardItemId)) {
            srcCol = { ...column };
            srcColIndex = colIndex;
          }
        });
      if (column?.state === destination) {
        destCol = { ...column };
        destColIndex = colIndex;
      }
    });

    let movedCardIndex = 0;
    const cardItem = srcCol?.shifts?.find((shiftCard, index) => {
      if (parseInt(shiftCard?.id) === parseInt(cardItemId)) {
        movedCardIndex = index;
        return true;
      }
    });
    totalShiftData[srcColIndex]?.shifts?.splice(movedCardIndex, 1);
    if (shift || cardItem) {
      totalShiftData[destColIndex]?.shifts?.unshift(shift || cardItem);
    }

    if (cardItem) {
      dispatch({
        type: actions.HIGHLIGHT_CARD_ID,
        payload: cardItem.id,
      });
      dispatch({
        type: actions.SET_TOTAL_SHIFTS,
        payload: totalShiftData,
      });
      dispatch(filterAllShifts(searchKeyword, selectedFilterItems));
    }
  };

// filter workers
export const setTotalLosList = (losList) => (dispatch, getState) => {
  dispatch({
    type: actions.SET_TOTAL_LOS_LIST,
    payload: losList,
  });
};

export const setSelectedLosList = (losList) => (dispatch, getState) => {
  dispatch({
    type: actions.SET_SELECTED_LOS_LIST,
    payload: losList,
  });
};

export const setSearchKeyword = (keyword) => (dispatch, getState) => {
  dispatch({
    type: actions.SET_SEARCH_KEYWORD,
    payload: keyword,
  });
};

export const setSelectedFilterList = (filterList) => (dispatch, getState) => {
  dispatch({
    type: actions.SET_SELECTED_FILTER_LIST,
    payload: filterList,
  });
};

export const clearShiftReconciliationData = (shiftId) => async (dispatch) => {
  try {
    const response = await fetchGraphQLDataMutation(
      JSON.stringify(
        mutation({
          operation: 'clearDataShift',
          variables: {
            input: {
              type: 'ClearDataShiftInput',
              required: true,
              value: {
                id: `${shiftId}`,
              },
            },
          },
          fields: [
            {
              shift: ['hasData'],
            },
          ],
        })
      )
    );

    if (!response?.data?.clearDataShift?.shift?.hasData) {
      toastr.success('Shift data cleared successfully');
    } else {
      toastr.error('Failed to clear shift data');
    }
  } catch (error) {
    handleErrors(error, {
      fallbackMsg: 'Failed to clear shift data',
      additionalArgs: { shiftId: shiftId },
      tagName: SENTRY_FEATURE.SHIFT_BOARD,
    });
  }
};

export const formatAndMergePusherShiftData = (pusherShift) => async (dispatch, getState) => {
  const formattedShiftFromPusher = formatShift(pusherShift?.shift, 'pusher');
  let mergedShift = {};
  if (formattedShiftFromPusher) {
    const oldTotalShiftData = getState().shiftBoard.totalShiftData;
    const tempOldTotalShiftData = cloneDeep(oldTotalShiftData);

    tempOldTotalShiftData?.forEach((column, colIndex) => {
      if (column?.shifts?.length) {
        const tempShifts = cloneDeep(column?.shifts);

        const reqShiftIndex = tempShifts.findIndex(
          (indvShit) => Number(indvShit.id) === Number(formattedShiftFromPusher.id)
        );

        if (reqShiftIndex > -1) {
          mergedShift = merge(
            cloneDeep(tempShifts[reqShiftIndex]),
            cloneDeep(formattedShiftFromPusher)
          );
        }
      }
    });
    if (mergedShift?.id) {
      dispatch(updateShiftStatusOfCard(mergedShift.id, mergedShift.state, mergedShift));
    }
  }
};

export const pusherShiftUpdate = (payload) => (dispatch) => {
  dispatch(formatAndMergePusherShiftData(payload));
};
