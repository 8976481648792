import {
  As,
  Box,
  ComponentStyleConfig,
  forwardRef,
  HTMLChakraProps,
  useMultiStyleConfig,
} from '@chakra-ui/react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type TopNavbarDropdownItemProps<T extends As<any>> = HTMLChakraProps<T> & {
  variant?: 'default' | 'active';
  wrapperSx?: ComponentStyleConfig;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const TopNavbarDropdownItemInner = <T extends As<any>>(
  { variant = 'default', as = 'div', sx, ...props }: TopNavbarDropdownItemProps<T>,
  ref: React.Ref<HTMLDivElement>
) => {
  const { 'dropdown-item': styles } = useMultiStyleConfig('TopNavbar', {
    variant,
  });

  return (
    <Box as={as} ref={ref} data-testid="top-navbar-item" sx={{ ...styles, ...sx }} {...props} />
  );
};

TopNavbarDropdownItemInner.displayName = 'TopNavbarDropdownItem';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const TopNavbarDropdownItem = forwardRef(TopNavbarDropdownItemInner) as <T extends As<any>>(
  props: React.PropsWithoutRef<TopNavbarDropdownItemProps<T>> & React.RefAttributes<HTMLDivElement>
) => JSX.Element;

export type TopNavbarDropdownItem = typeof TopNavbarDropdownItem;
