/**
 * DEPRECATED: Please consult with the FE Performance team before using this file.
 */
import {
  QR_CODE_LIST,
  QR_CODE_SET_OFFSET,
  QR_CODE_SET_TYPE,
  QR_CODE_SET_LIMIT,
  QR_CODE_SET_CODE,
  QR_CODE_LOADING,
} from '../actions/types';
import Helpers from '../helpers';

const QR_CODE_PAGES = Helpers.QR_CODE_PAGES;

const initialState = {
  list: [],
  limit: QR_CODE_PAGES.defaultLimit,
  offset: QR_CODE_PAGES.defaultOffset,
  type: QR_CODE_PAGES.defaultType,
  currentPage: 1,
  totalCount: 0,
  code: '',

  isLoading: false,
  loadingSuccess: false,
  loadingFailed: false,
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case QR_CODE_LIST:
      return {
        ...state,
        list: payload.data,
        totalCount: Number(payload.total_count),
        limit: Number(payload.limit),
        offset: Number(payload.offset),

        isLoading: false,
        loadingSuccess: true,
        loadingFailed: false,
      };
    case QR_CODE_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case QR_CODE_SET_OFFSET:
      return {
        ...state,
        offset: payload,
      };
    case QR_CODE_SET_TYPE:
      return {
        ...state,
        type: payload,
      };
    case QR_CODE_SET_LIMIT:
      return {
        ...state,
        limit: Number(payload),
      };
    case QR_CODE_SET_CODE:
      return {
        ...state,
        code: payload,
      };
    default:
      return state;
  }
}
