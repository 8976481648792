import {
  InputGroup,
  InputLeftElement,
  Input,
  Box,
  Flex,
  Heading,
  HStack,
  InputProps,
  HeadingProps,
} from '@chakra-ui/react';
import { ReactNode, useMemo } from 'react';

import {
  DateRangePickerWithToday,
  DateRangePickerWithTodayPropsType,
} from 'components/DateRangePickerWithToday';
import { FAIcon } from 'components/FAIcon';

interface TopBarPropsType {
  title: string;
  orderType?: string;
  titleProps?: HeadingProps;
  isSearchVisible?: boolean;
  isDateTimeVisible?: boolean;
  elementPlacement?: number[];
  renderElements?: ReactNode[];
  searchFieldProps?: InputProps;
  dateRangeProps?: DateRangePickerWithTodayPropsType;
}

function sortElementsByPosition(nodeElement: ReactNode[], positions: number[]) {
  // Create an array of objects containing both nodeElement and position
  const combinedArray = nodeElement.map((indvElem, index) => ({
    elem: indvElem,
    position: positions[index],
  }));

  // Sort the combined array based on positions
  combinedArray.sort((a, b) => a.position - b.position);

  // Extract only the nodeElements after sorting
  const sortedNodeElement = combinedArray.map((indvELem) => indvELem.elem)?.filter(Boolean);

  return sortedNodeElement;
}

const TopBar = (props: TopBarPropsType) => {
  const {
    title,
    titleProps = {},
    renderElements = [],
    elementPlacement = [],
    searchFieldProps = {},
    dateRangeProps = {},
    isSearchVisible = true,
    isDateTimeVisible = true,
  } = props;

  const customChildrenRender = useMemo(() => {
    return sortElementsByPosition(
      [
        isSearchVisible && (
          <Box key="search-input">
            <InputGroup>
              <InputLeftElement w={8} pointerEvents="none">
                <FAIcon variant="solid" icon="search" color="gray.300" />
              </InputLeftElement>
              <Input
                id={searchFieldProps?.placeholder || 'filter-text-box'}
                variant={'filled'}
                color={'gray'}
                fontWeight={600}
                pr={2}
                pl={8}
                {...searchFieldProps}
              />
            </InputGroup>
          </Box>
        ),
        ...renderElements,
        isDateTimeVisible && (
          <DateRangePickerWithToday key="date-range-picker" {...dateRangeProps} />
        ),
      ],
      elementPlacement
    );
  }, [
    isSearchVisible,
    searchFieldProps,
    renderElements,
    isDateTimeVisible,
    dateRangeProps,
    elementPlacement,
  ]);

  return (
    <Flex
      justifyContent="space-between"
      alignItems="center"
      className="main-top-bar"
      sx={{ padding: '0 24px', minHeight: '70px' }}
      boxShadow="lg"
    >
      <Box>
        <Heading size="md" {...titleProps}>
          {title}
        </Heading>
      </Box>
      <HStack spacing="10px">{customChildrenRender}</HStack>
    </Flex>
  );
};
export { TopBar };
