import * as workers from '../workers';

export const FETCH_ORDER_GROUPS = '@orders/FETCH_ORDER_GROUPS';
export const FETCHING_ORDER_GROUPS = '@orders/FETCHING_ORDER_GROUPS';

export const FETCH_CUSTOMERS = '@records/FETCH_CUSTOMERS';
export const FETCHING_CUSTOMERS = '@records/FETCHING_CUSTOMERS';

export const FETCH_SINGLE_CUSTOMER = '@records/FETCH_SINGLE_CUSTOMER';
export const FETCHING_SINGLE_CUSTOMER = '@records/FETCHING_SINGLE_CUSTOMER';

export const FETCH_BRANCH = '@records/FETCH_BRANCH';
export const FETCHING_BRANCH = '@records/FETCHING_BRANCH';

export const FETCH_ASSETS = '@records/FETCH_ASSETS';
export const FETCHING_ASSETS = '@records/FETCHING_ASSETS';

export const FETCH_USERS = '@records/FETCH_USERS';
export const FETCHING_USERS = '@records/FETCHING_USERS';

export const FETCH_SHIPTOS = '@records/FETCH_SHIPTOS';
export const FETCHING_SHIPTOS = '@records/FETCHING_SHIPTOS';

export const SET_CUSTOMER_BRANCHES = '@records/SET_CUSTOMER_BRANCHES';

export const SET_SINGLE_CUSTOMER = '@records/SET_SINGLE_CUSTOMER';

export const SET_RECORD_TAB = '@records/SET_RECORD_TAB';

export const FETCH_DELIVERY_ORDERS = '@orders/FETCH_DELIVERY_ORDERS';
export const FETCHING_DELIVERY_ORDERS = '@orders/FETCHING_DELIVERY_ORDERS';

export const FETCH_LOADING_ORDERS = '@orders/FETCH_LOADING_ORDERS';
export const FETCHING_LOADING_ORDERS = '@orders/FETCHING_LOADING_ORDERS';

export const SET_RECORD_SEARCH_TEXT = '@records/SET_RECORD_SEARCH_TEXT';
export const SET_RECORD_SEARCH_TEXT_CHANGED = '@records/SET_RECORD_SEARCH_TEXT_CHANGED';

export const OPEN_ASSET_DETAIL = '@records/OPEN_ASSET_DETAIL';
export const OPEN_DRIVER_DETAIL = '@records/OPEN_DRIVER_DETAIL';

export const RECORD_FILTER = '@records/RECORD_FILTER';

export const setRecordFilter = (filter) => workers.setRecordFilter(filter);

export const fetchOrderGroups = (filter) => workers.fetchOrderGroups(filter);
export const fetchDeliveryOrders = (filter) => workers.fetchDeliveryOrders(filter);
export const fetchLoadingOrders = (filter) => workers.fetchLoadingOrders(filter);
export const fetchCustomers = (filter) => workers.fetchCustomers(filter);
export const fetchSingleCustomer = (id) => workers.fetchSingleCustomer(id);

export const fetchCustomerBranches = (id) => workers.fetchCustomerBranches(id);
export const setCustomerBranches = (payload) => workers.setCustomerBranches(payload);
export const setSingleCustomer = (payload) => workers.setSingleCustomer(payload);
export const setRecordSearchText = (payload) => workers.setRecordSearchText(payload);
export const setRecordSearchTextChanged = (payload) => workers.setRecordSearchTextChanged(payload);
export const setRecordTab = (payload) => workers.setRecordTab(payload);

export const openAssetDetail = (id) => workers.openAssetDetail(id);
export const openDriverDetail = (id) => workers.openDriverDetail(id);

export const fetchAssets = (id) => workers.fetchAssets(id);
export const fetchShiptos = (id) => workers.fetchShiptos(id);
export const fetchUsers = (id) => workers.fetchUsers(id);
