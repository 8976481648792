import { Navigate, Route, Routes } from 'react-router-dom';

import { lazyImport } from 'utils/lazyImport';

import { DeliveryTime } from '../pages/DeliveryTime';
import { LoadingTime } from '../pages/LoadingTime';

import { DriverSubRouteConfig } from './routeConfig';

const { DriverAppDetails } = lazyImport(
  () => import('javascript/containers/Drivers/List'),
  'DriverAppDetails'
);
const { QrCodesPage } = lazyImport(() => import('../pages/QrCodesPage'), 'QrCodesPage');

export const DriverRoutes = () => {
  return (
    <Routes>
      <Route path={DriverSubRouteConfig.DELIVERY_TIME_SPENT.path} element={<DeliveryTime />} />
      <Route path={DriverSubRouteConfig.LOADING_TIME_SPENT.path} element={<LoadingTime />} />
      <Route path={DriverSubRouteConfig.DRIVER_APP_DETAILS.path} element={<DriverAppDetails />} />
      <Route path={DriverSubRouteConfig.QR_CODES.path}>
        <Route index element={<QrCodesPage />} />
        <Route path=":codeId/edit" element={<QrCodesPage />} />
      </Route>
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
};
