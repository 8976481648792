import * as Sentry from '@sentry/react';
import { Integration } from '@sentry/types';

const dsn = process.env.REACT_APP_SENTRY_DSN;

if (dsn) {
  const environment =
    process.env.REACT_APP_SENTRY_ENV || process.env.REACT_APP_ENV || process.env.NODE_ENV;

  const integrations: Integration[] =
    environment === 'production'
      ? [
          new Sentry.BrowserTracing(),
          new Sentry.Replay({
            maskAllText: false,
            blockAllMedia: false,
          }),
          new Sentry.Feedback({
            autoInject: false,
          }),
        ]
      : [
          new Sentry.Feedback({
            // For test in development phase
            autoInject: false,
          }),
        ];

  Sentry.init({
    dsn,
    tracesSampleRate: 0.1,

    // This sets the sample rate to be 10%. You may want this to be 100%
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,

    integrations: integrations,

    environment: environment,
  });

  const scope = Sentry.getCurrentScope();
  scope.setTag('app', 'standalone-app');
}
