import React from 'react';
import { createRoot } from 'react-dom/client';
import toastr from 'toastr';

import { App } from './App';
import { reportWebVitals } from './reportWebVitals';

import './index.scss';

import 'lib/sentryInit';
import 'toastr/build/toastr.min.css';

toastr.options = {
  positionClass: 'toast-top-right',
  progressBar: true,
  closeButton: true,
};

global.toastr = toastr;

// const container = document.getElementById('root');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(document.getElementById('root')!);
root.render(
  // <React.StrictMode>
  <App />
  // </React.StrictMode>
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
