/**
 * DEPRECATED: Please consult with the FE Performance team before using this file.
 */
import orderStatuses from '../../../../components/OrderDetails/common/OrderStatusSelector/helpers/order-statuses';

/**
 *
 * This function appends the allowed states to the shift states fetched from the server.
 * @param {*} fetchedshiftStates this is the object of all the shift states fetched from the server. Here is an example of what it looks like:
 * {
 *  "active": {
 *  "id": 1,
 *  "name": "active",
 *  "color": "#00bfff",
 *  }
 * }
 * @returns {object} this is the object of all the shift states fetched from the server with the allowed states appended to it. Here is an example of what it looks like:
 * {
 *  "active": {
 *  "id": 1,
 *  "name": "active",
 *  "color": "#00bfff",
 *  "allowedStates": ["pending", "cancelled", "completed"]
 *  }
 * }
 */

export const appendAllowedState = (fetchedshiftStates) => {
  // shiftStatuses store the statuses object with allowed states for each status
  const shiftStatuses = orderStatuses.shift_status;

  const allStateKeys = Object.keys(fetchedshiftStates);
  const nonActiveStatuses = allStateKeys.filter((state) => state !== 'active');

  const appendedState = allStateKeys.reduce((acc, key) => {
    // allowedStates store the allowed states for each status
    // if allowedStates is not defined in local veriable shiftStatuses, then it is assumed that all the states are allowed except active
    const allowedStates = shiftStatuses[key]?.allowedStates || nonActiveStatuses;
    acc[key] = { ...fetchedshiftStates[key], allowedStates };
    return acc;
  }, {});

  return appendedState;
};
