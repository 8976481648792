export const COMMONS = Object.freeze({
  ADD: 'ADD',
  PDF: 'pdf',
  HUB: 'hub',
  EDIT: 'EDIT',
  PREV: 'prev',
  NONE: 'None',
  TEXT: 'text',
  IMAGE: 'image',
  TRUCK: 'truck',
  GROUP: 'group',
  ERROR: 'error',
  FILTER: 'filter',
  DRIVER: 'driver',
  CREATE: 'create',
  DELETE: 'delete',
  STATUS: 'status',
  SUCCESS: 'success',
  TRAILER: 'trailer',
  REGULAR: 'REGULAR',
  SKIPPED: 'skipped',
  WARNING: 'warning',
  CUSTOMER: 'customer',
  PRIORITY: 'priority',
  END_DATE: 'end_date',
  FOR_FORM: 'FOR_FORM',
  SUBTRACT: 'SUBTRACT',
  DOCUMENT: 'document',
  NOT_AVAILABLE: 'N/A',
  ICON_ONLY: 'ICON_ONLY',
  FOR_TABLE: 'FOR_TABLE',
  START_DATE: 'start_date',
  NORMAL_PRIORITY: 'normal',
  URGENT_PRIORITY: 'urgent',
  DELETE_ITEM: 'DELETE_ITEM',
  COMMON_CARRIER: 'common_carrier',
});

export const INCH = 'inch';

export const EDITABLE_ICONS = Object.freeze({
  UNDO_ICON: 'undo',
  SAVE_ICON: 'save',
  EDIT_ICON: 'pencil',
  CANCEL_ICON: 'times',
});

export const SCHEDULE = Object.freeze({
  EARLIEST: 'earliest',
  LATEST: 'latest',
  WORK: 'work',
  BREAK: 'break',
});

export const TRANSFER_TYPE = Object.freeze({
  ALL_TO_RIGHT: 'ALL_TO_RIGHT',
  ALL_TO_LEFT: 'ALL_TO_LEFT',
  TO_RIGHT: 'TO_RIGHT',
  TO_LEFT: 'TO_LEFT',
});

export const DATE_FORMAT = Object.freeze({
  T: 'T',
  DD: 'DD',
  HH_mm: 'HH:mm',
  HH_MM_A: 'hh:mm a',
  YYYY_MM_DD: 'yyyy-MM-dd',
  LLL_d_yyyy: 'LLL d, yyyy',
  MMM_DD_YYYY: 'MMM dd, yyyy',
  MMM_DD: 'MMM dd',
  MMM_DD_HH_MM: 'MMM dd, HH:mm',
  ccc_LLL_d_HH_mm: 'ccc LLL d, HH:mm',
  MM_dd_yyyy_HH_mm: 'MM/dd/yyyy HH:mm',
  MM_dd_yyyy_00_01: 'MM/dd/yyyy 00:01',
  YYYY_MM_DD_HH_MM: 'yyyy-MM-dd HH:mm',
  MMM_dd_yyyy_HH_MM: 'MMM dd yyyy, HH:mm',
  YYYY_MM_DD_HH_MM_A: 'yyyy-MM-dd hh:mm a',
});

export const DATE_TIME_DURATION_KEYS = Object.freeze({
  DAYS: 'days',
  HOURS: 'hours',
  YEARS: 'years',
  WEEKS: 'weeks',
  MONTHS: 'months',
  MINUTES: 'minutes',
  SECONDS: 'seconds',
  QUARTERS: 'quarters',
  MILLISECONDS: 'milliseconds',
});

export const PAST_DAYS_TO_SHOW_LOD = 7;

export const MAX_NUMBER_LIMIT_REGEX = /^[0-9]{1,5}(\.[0-9]{1,2}|\.)?$|^$/;

// Old version keys
// dashboardGrid_17062020
export const DASHBOARD_LAYOUT_CONFIG_VERSION_KEY = 'dashboardGrid_25102020';
export const DASHBOARD_CUSTOMER_PORTAL_LAYOUT_KEY = 'dashboard_grid_customer_portal_25102020';

export const PAGE_SIZE = 30;
export const API_LIMIT = 10;

export const PAGINATION_STATE = Object.freeze({
  PAGE_SIZE: 'pageSize',
  CURRENT_PAGE: 'currentPage',
});

export const PAGE_LIMIT = 30;

export const LINE_ITEMS_SOURCES = {
  manual: 'Manual',
  code_scan: 'Code Scan',
  lcr_meter: 'LCR Meter',
  box: 'Box',
};

export enum HTTP_STATUS_CODE {
  FAILED = 0,
  OK = 200,
  CREATED = 201,
  NO_CONTENT = 204,
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  ENTITY_NOT_FOUND = 422,
  INTERNAL_SERVER_ERROR = 500,
}

export enum ORDER_TYPE {
  DELIVERY = 'delivery',
  EXTRACTION = 'extraction',
  LOADING = 'loading',
  TRANSFER = 'transfer',
}
