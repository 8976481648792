import { useCheckSettings, useCurrent } from 'features/auth';

import { TopNavbarDropdown } from './TopNavbarDropdown';
import { TopNavbarDropdownItemLink } from './TopNavbarDropdownItemLink';
import { TopNavbarDropdownItemRouterLink } from './TopNavbarDropdownItemRouterLink';

const REDIRECT_URL = process.env.REACT_APP_REDIRECT_URL;

export const TopNavbarOperations = () => {
  const [hasSettings] = useCheckSettings();
  const { data } = useCurrent();

  return (
    <TopNavbarDropdown trigger="Operations">
      {hasSettings('document_management') && (
        <TopNavbarDropdownItemLink href={`${REDIRECT_URL}panda_documents`}>
          Documents
        </TopNavbarDropdownItemLink>
      )}
      <TopNavbarDropdownItemRouterLink to="/self_customer">
        Self Customer
      </TopNavbarDropdownItemRouterLink>

      {data?.current.customerSettings.includes('enable_routing_feature') &&
        data?.current.userFeatureFlags.includes('routing_allow_configuration') && (
          <TopNavbarDropdownItemRouterLink to="/route_config">
            Route configuration
          </TopNavbarDropdownItemRouterLink>
        )}
    </TopNavbarDropdown>
  );
};
