import { useMemo, Suspense } from 'react';
import { Navigate } from 'react-router-dom';

import { FullContainerSpinner } from 'components/ContainerSpinner';
import { useCurrent } from 'features/auth';

interface AdminRouteGuardProps {
  children: React.ReactNode;
}

export const AdminRouteGuard = ({ children }: AdminRouteGuardProps) => {
  const { data, loading } = useCurrent();

  const isSuperAdmin = useMemo(() => data?.current?.user?.isSuperAdmin ?? false, [data]);

  if (loading) {
    return (
      <Suspense fallback={<FullContainerSpinner />}>
        <FullContainerSpinner />
      </Suspense>
    );
  }

  if (!isSuperAdmin) {
    return <Navigate to="/" replace />;
  }

  return <>{children}</>;
};
