import { chakra, CSSObject, HTMLChakraProps, useMultiStyleConfig } from '@chakra-ui/react';
import { SerializedStyles } from '@emotion/react';
import { forwardRef, useMemo } from 'react';

export interface PageButtonProps
  extends Omit<HTMLChakraProps<'button'>, 'css' | '__css' | 'variant'> {
  css?: SerializedStyles;
  variant?: 'default' | 'active' | 'bordered';
  btnSize?: 'regular' | 'small';
}

const PageButtonInner: React.ForwardRefRenderFunction<HTMLButtonElement, PageButtonProps> = (
  { variant = 'default', btnSize = 'regular', ...props },
  ref
) => {
  const { PageButton: styles } = useMultiStyleConfig('Pagination', props);

  /**
   * NOTE: btnSizeNumber is used to set the height,width,lineHeight of the button
   * for small button height,width,lineHeight will be 6
   * for regular button height,width,lineHeight will be 8
   */
  const btnSizeNumber = useMemo(() => (btnSize === 'small' ? '6' : '8'), [btnSize]);

  const css = useMemo<CSSObject>(
    () =>
      Object.assign(
        {
          border: variant === 'bordered' ? '1px solid' : 'none',
          borderColor: 'whiteAlpha.700',
          borderRadius: 'full',
          w: btnSizeNumber,
          h: btnSizeNumber,
          lineHeight: btnSizeNumber,
          fontSize: 'sm',
          bgColor: variant === 'active' ? 'whiteAlpha.700' : undefined,
          color: variant === 'active' ? 'black' : 'white',
          _hover: {
            color: variant === 'bordered' ? 'black' : undefined,
            bgColor:
              variant === 'bordered'
                ? 'whiteAlpha.700'
                : variant === 'active'
                ? undefined
                : 'gray.750',
          },
          _disabled: {
            opacity: 0.5,
            pointerEvents: 'none',
          },
        } as CSSObject,
        styles
      ),
    [btnSizeNumber, styles, variant]
  );

  return <chakra.button __css={css} ref={ref} {...props} />;
};

PageButtonInner.displayName = 'PageButton';

export const PageButton = forwardRef<HTMLButtonElement, PageButtonProps>(PageButtonInner);
export type PageButton = typeof PageButton;
