import * as actions from '../actions';

export const initialState = {
  orderGroups: {},
  fetchingOrderGroups: false,
  deliveryOrders: {},
  fetchingDeliveryOrders: false,
  loadingOrders: {},
  fetchingLoadingOrders: false,
  customers: {},
  fetchingCustomers: false,
  singleCustomer: {},
  fetchingSingleCustomer: false,
  singleBranch: {},
  fetchingSingleBranch: false,
  customerBranches: {},
  getSingleCustomer: {},
  recordSearchText: '',
  recordSearchTextChanged: false,
  assets: {},
  fetchingAssets: false,
  fetchingCustomerBranches: false,
  openAssetWithId: null,
  openDriverWithId: null,
  recordTab: 0,
  recordFilter: {},
};

export default function recordsReducer(state = initialState, action) {
  switch (action.type) {
    case actions.FETCH_CUSTOMERS:
      return {
        ...state,
        customers: action.payload,
      };
    case actions.FETCHING_CUSTOMERS:
      return {
        ...state,
        fetchingCustomers: action.payload,
      };
    case actions.FETCH_SINGLE_CUSTOMER:
      return {
        ...state,
        singleCustomer: action.payload,
      };
    case actions.FETCHING_SINGLE_CUSTOMER:
      return {
        ...state,
        fetchingSingleCustomer: action.payload,
      };
    case actions.FETCH_BRANCH:
      return {
        ...state,
        singleBranch: action.payload,
      };
    case actions.SET_CUSTOMER_BRANCHES:
      return {
        ...state,
        customerBranches: action.payload,
      };
    case actions.SET_SINGLE_CUSTOMER:
      return {
        ...state,
        getSingleCustomer: action.payload,
      };
    case actions.SET_RECORD_SEARCH_TEXT:
      return {
        ...state,
        recordSearchText: action.payload,
      };
    case actions.SET_RECORD_SEARCH_TEXT_CHANGED:
      return {
        ...state,
        recordSearchTextChanged: action.payload,
      };
    case actions.FETCHING_ASSETS:
      return {
        ...state,
        fetchingAssets: action.payload,
      };
    case actions.FETCH_ASSETS:
      return {
        ...state,
        assets: action.payload,
      };
    case actions.FETCH_SHIPTOS:
      return {
        ...state,
        customerBranches: action.payload,
      };
    case actions.FETCHING_SHIPTOS:
      return {
        ...state,
        fetchingCustomerBranches: action.payload,
      };
    case actions.OPEN_ASSET_DETAIL:
      return {
        ...state,
        openAssetWithId: action.payload,
      };
    case actions.OPEN_DRIVER_DETAIL:
      return {
        ...state,
        openDriverWithId: action.payload,
      };
    case actions.SET_RECORD_TAB:
      return {
        ...state,
        recordTab: action.payload,
      };
    case actions.RECORD_FILTER:
      return {
        ...state,
        recordFilter:
          typeof action.payload === 'function'
            ? action.payload(state.recordFilter)
            : action.payload,
      };
    default:
      return state;
  }
}
