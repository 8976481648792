/**
 * DEPRECATED: Please consult with the FE Performance team before using this file.
 */
import { FETCH_CURRENT } from '../actions/types';

const initialState = {
  user: null,
  customer: null,
  features: [],
  settingFeatures: [],
};

export default function currentReducer(state = initialState, { type, payload }) {
  switch (type) {
    case FETCH_CURRENT:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
}
