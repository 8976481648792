import { Box, Button, Heading, Text } from '@chakra-ui/react';

export const FullPageError: React.FC = () => {
  return (
    <Box textAlign="center" mt="10vh" py="10" px="6">
      <Heading
        display="inline-block"
        as="h2"
        size="2xl"
        bgGradient="linear(to-r, orange.400, orange.600)"
        backgroundClip="text"
      >
        :(
      </Heading>
      <Text fontSize="xl" mt="3" mb="2">
        An unexpected error has occurred
      </Text>
      <Text color="gray.100" mb="6">
        Our engineers have been notified and will be fixing it as soon as possible.
      </Text>

      <Button
        colorScheme="orange"
        bgColor="orange.500"
        color="white"
        variant="solid"
        onClick={() => window.location.reload()}
      >
        Reload page
      </Button>
    </Box>
  );
};

export type FullPageError = typeof FullPageError;
