/**
 * DEPRECATED: Please consult with the FE Performance team before using this file.
 */
import {
  FETCH_ALERTS,
  FETCH_ALERT_TYPES,
  SELECTED_FILTERS,
  FILTERED_ALERTS,
} from '../actions/types';

const initialState = {
  data: {},
  types: [],
  selectedFilter: [],
  filteredAlerts: {
    critical: [],
    warning: [],
    info: [],
  },
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case FETCH_ALERTS:
      return {
        ...state,
        data: payload,
      };
    case FETCH_ALERT_TYPES:
      return {
        ...state,
        types: payload,
      };
    case SELECTED_FILTERS:
      return {
        ...state,
        selectedFilter: [...payload],
      };
    case FILTERED_ALERTS:
      return {
        ...state,
        filteredAlerts: {
          critical: payload.critical,
          warning: payload.warning,
          info: payload.info,
        },
      };
    default:
      return state;
  }
}
