import { chakra, ChakraComponent, forwardRef } from '@chakra-ui/react';
import { useMemo } from 'react';

export type FAIconProps = Omit<ChakraComponent<'span'>, 'variant'> & {
  className?: string;
  variant?: 'solid' | 'regular' | 'light' | 'duotone';
  icon: string;
  alt?: string;
};

const variantMapper = {
  solid: 'fas',
  regular: 'far',
  light: 'fal',
  duotone: 'fad',
};

const FAIconInner: React.ForwardRefRenderFunction<HTMLSpanElement, FAIconProps> = (
  { icon, variant = 'regular', alt, className, ...props },
  ref
) => {
  const iconClass = useMemo(
    () => [variantMapper[variant], `fa-${icon}`, className].join(' '),
    [variant, icon, className]
  );

  return (
    <chakra.span
      ref={ref}
      role={alt ? 'img' : 'none'}
      aria-label={alt}
      {...props}
      className={iconClass}
    />
  );
};

FAIconInner.displayName = 'FAIcon';

export const FAIcon = forwardRef(FAIconInner);
