import { chakra, forwardRef } from '@chakra-ui/react';

import { type TopNavbarItemProps, TopNavbarItem } from './TopNavbarItem';

export type TopNavbarItemLinkProps = Omit<TopNavbarItemProps<'a'>, 'variant' | 'href'> & {
  isActive?: boolean;
  href: string;
};

const TopNavbarItemLinkInner: React.ForwardRefRenderFunction<
  HTMLDivElement,
  TopNavbarItemLinkProps
> = ({ isActive, ...props }, ref) => (
  <TopNavbarItem ref={ref} as={chakra.a} variant={isActive ? 'active' : 'default'} {...props} />
);

TopNavbarItemLinkInner.displayName = 'TopNavbarItemLink';

export const TopNavbarItemLink = forwardRef(TopNavbarItemLinkInner);

export type TopNavbarItemLink = typeof TopNavbarItemLink;
