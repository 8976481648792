import { AnyObject } from 'yup';

/** Extension of Error Class for the errors that are generated with in the app */
class WebAppErr extends Error {
  args: AnyObject | undefined;
  isWebAppErr: boolean;

  constructor(message: string, additionalInfo?: AnyObject) {
    super(message);

    this.message = message;
    this.name = 'WebAppErr';
    this.isWebAppErr = true;
    this.args = additionalInfo;
  }
}

export { WebAppErr };
