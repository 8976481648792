export const prevNextBtnStyle = {
  margin: 0,
  padding: 0,
  width: '33px',
  color: '#ccc',
  height: '100%',
  border: 'none',
  borderRadius: 0,
  minWidth: '33px',
  background: 'transparent',
  _hover: {
    color: 'black.905',
    background: 'gray.215',
  },
  _disabled: {
    pointerEvents: 'none',
  },
};

export const dateRangeContainerStyle = {
  color: 'white',
  height: '40px',
  display: 'flex',
  overflow: 'hidden',
  borderRadius: '5px',
  alignItems: 'center',
  border: '1px solid var(--fp-colors-gray-215)',
};

export const dateRangeValueStyle = {
  width: '205px',
  height: '38px',
  display: 'flex',
  fontSize: '0.9em',
  alignItems: 'center',
  fontWeight: 'normal',
  justifyContent: 'center',
  cursor: 'pointer !important',
  borderLeft: '1px solid var(--fp-colors-gray-215)',
  borderRight: '1px solid var(--fp-colors-gray-215)',
};
