import { Box } from '@chakra-ui/react';
import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import { FullContainerSpinner } from 'components/ContainerSpinner';
import { TopNavbar } from 'components/TopNavbar';

export const MainLayout: React.FC = () => {
  return (
    <Box maxW="full" minH="100vh">
      <TopNavbar />
      <Box className="main-content" h="calc(100vh - 3.375rem)" overflow="auto">
        <Suspense fallback={<FullContainerSpinner />}>
          <Outlet />
        </Suspense>
      </Box>
    </Box>
  );
};
