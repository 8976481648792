import { Mixpanel } from './initializeMixpanel';

export const triggerMixPanelEvent = (name, attributes) => Mixpanel.track(name, attributes);

export const mixpanelTimeEvent = (name) => Mixpanel.time(name);

export const mixpanelIdentify = (id) => Mixpanel.identify(id);

export const mixpanelReset = () => Mixpanel.reset();

export const mixpanelRegister = (data) => Mixpanel.register(data);

export const mixpanelPeopleSetOnce = (data) => Mixpanel.peopleSetOnece(data);
