import { useMemo } from 'react';
import { To, useLocation, useResolvedPath } from 'react-router-dom';

export type UseNavLinkProps = {
  to: To;
  caseSensitive?: boolean;
  end?: boolean;
};

/**
 * An extract of the `isActive` logic from `react-router-dom`'s `NavLink` component.
 */
export function useNavLink({ to, caseSensitive = false, end = false }: UseNavLinkProps) {
  const location = useLocation();
  const path = useResolvedPath(to);

  const locationPathname = useMemo(
    () => (caseSensitive ? location.pathname : location.pathname.toLowerCase()),
    [location, caseSensitive]
  );

  const toPathname = useMemo(
    () => (caseSensitive ? path.pathname : path.pathname.toLowerCase()),
    [path, caseSensitive]
  );

  const isActive = useMemo(
    () =>
      locationPathname === toPathname ||
      (!end &&
        locationPathname.startsWith(toPathname) &&
        locationPathname.charAt(toPathname.length) === '/'),
    [locationPathname, toPathname, end]
  );

  return isActive;
}
