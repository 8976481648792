import { createSlice } from '@reduxjs/toolkit';

type CreateRouteReducerType = {
  showUpdateRouteView: boolean;
  showCreateRouteView: boolean;
  selectedRouteToUpdate: string;
};

const initialState: CreateRouteReducerType = {
  showCreateRouteView: false,
  showUpdateRouteView: false,
  selectedRouteToUpdate: '',
};

const createRouteSlice = createSlice({
  name: 'createRoute',
  initialState,
  reducers: {
    setShowUpdateRouteView(state, action) {
      state.showUpdateRouteView = action.payload;
    },
    setShowCreateRouteView(state, action) {
      state.showCreateRouteView = action.payload;
    },
    setSelectedRouteToUpdate(state, action) {
      state.selectedRouteToUpdate = action.payload;
    },
  },
});

export const { setShowUpdateRouteView, setShowCreateRouteView, setSelectedRouteToUpdate } =
  createRouteSlice.actions;

// eslint-disable-next-line import/no-default-export
export default createRouteSlice.reducer;
