import Cookies from 'js-cookie';

import { FetchCurrentQuery } from 'generated/graphql';

const DOMAIN = process.env.REACT_APP_DOMAIN;

export const setCustomerInfoCookies = (currentData: FetchCurrentQuery, timezone: string) => {
  const tenantSlug = currentData?.current?.tenant?.slug || '';
  const tenantName = currentData?.current?.tenant?.name || '';
  const currentCustomerId = currentData?.current?.customer?.id || '';
  const currentUserId = currentData?.current?.user?.id || '';
  const lat = currentData?.current?.customer?.site?.lat || '';
  const lng = currentData?.current?.customer?.site?.lng || '';
  const pusherChannelName = currentData?.current?.pusherChannelName || '';

  // SET TENANT COOKIES
  Cookies.set('tenant_slug', tenantSlug, {
    domain: DOMAIN,
  });
  Cookies.set('tenant_name', tenantName, {
    domain: DOMAIN,
  });

  Cookies.set('current_customer_id', currentCustomerId, {
    domain: DOMAIN,
  });
  Cookies.set('lat', lat, {
    domain: DOMAIN,
  });

  Cookies.set('long', lng, {
    domain: DOMAIN,
  });
  Cookies.set('pusher_channel_name', pusherChannelName, {
    domain: DOMAIN,
  });
  Cookies.set('current_user_id', currentUserId, {
    domain: DOMAIN,
  });
  Cookies.set('tenant-timezone', timezone);

  sessionStorage.setItem('current_customer_id', currentCustomerId);
};
