import { Suspense } from 'react';
import { Routes } from 'react-router-dom';

import { FullContainerSpinner } from '../components/ContainerSpinner';

import { privateRoutes } from './private';
import { publicRoutes } from './public';

export const AppRoutes = () => {
  return (
    <Suspense fallback={<FullContainerSpinner />}>
      <Routes>
        {publicRoutes}
        {privateRoutes}
      </Routes>
    </Suspense>
  );
};
