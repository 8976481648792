/**
 * DEPRECATED: Please consult with the FE Performance team before using this file.
 */
import { SET_TIMESTAMPS } from '../actions';
import * as actions from '../actions/shiftboard_actions';

export const initialState = {
  totalLosList: [],
  searchKeyword: '',
  totalShiftData: [],
  filteredShiftData: [],
  isLoading: false,
  isLoadingSuccess: false,
  isFiltering: false,
  isUpdating: false,
  errors: null,
  highlightCardId: null,
  selectedFilterItems: [],
  fromTimestamp: null,
  toTimestamp: null,
};

export default function shiftBoardReducer(state = initialState, action) {
  switch (action.type) {
    case SET_TIMESTAMPS: {
      return {
        ...state,
        ...action.payload,
      };
    }

    case actions.SET_ALL_STATE_SHIFTS_LOADING:
      return {
        ...state,
        fetchedColumnCount: 0,
        totalShiftData: [
          ...state.totalShiftData.filter((item) => item.state !== action.payload.state),
          action.payload,
        ],
      };
    case actions.ADD_SHIFT_TO_SHIFT_STATE: {
      const count = !action.payload.metadata?.hasMore ? 1 : 0;
      const isSameTimestamp =
        state.toTimestamp === action.payload.toTimestamp &&
        state.fromTimestamp === action.payload.fromTimestamp;

      return {
        ...state,
        fetchedColumnCount: isSameTimestamp
          ? state.fetchedColumnCount + count
          : state.fetchedColumnCount,
        totalShiftData: state.totalShiftData.map((shiftData) => {
          if (isSameTimestamp) {
            const shifts = {};
            shiftData.shifts.forEach((shift) => {
              shifts[shift.id] = shift;
            });
            action.payload.shifts.forEach((shift) => {
              shifts[shift.id] = shift;
            });

            if (shiftData.state === action.payload.state) {
              return {
                ...shiftData,
                isFetching: false,
                metaData: action.payload.metadata,
                shifts: Object.values(shifts),
                shiftIds: Object.keys(shifts),
              };
            }
          }
          return shiftData;
        }),
      };
    }
    case actions.SET_BOL_ERRORS:
      return {
        ...state,
        totalShiftData: state.totalShiftData.map((shiftData) => {
          if (shiftData.state === action.payload.state) {
            if (
              state.toTimestamp === action.payload.toTimestamp &&
              state.fromTimestamp === action.payload.fromTimestamp
            ) {
              const shifts = {};
              shiftData.shifts.forEach((shift) => {
                shifts[shift.id] = shift;
              });
              action.payload.shifts.forEach((shift) => {
                shifts[shift.id] = {
                  ...shifts[shift.id],
                  bolErrors: shift.bolErrors,
                  shiftStartVolumeError: shift.shiftStartVolumeError,
                };
              });
              return {
                ...shiftData,
                shifts: Object.values(shifts),
                bolErrorsMetaData: action.payload.metadata,
              };
            }
          }
          return shiftData;
        }),
      };
    case actions.SET_TOTAL_SHIFTS:
      return {
        ...state,
        totalShiftData: action.payload,
      };
    case actions.SET_FILTERED_SHIFTS:
      return {
        ...state,
        filteredShiftData: action.payload,
        isFiltering: false,
      };
    case actions.SET_BOARD_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case actions.SET_BOARD_FILTERING:
      return {
        ...state,
        isFiltering: action.payload,
      };
    case actions.SET_BOARD_UPDATING:
      console.log('board - ', action.payload);
      return {
        ...state,
        isUpdating: action.payload,
      };
    case actions.SET_BOARD_ERRORS:
      return {
        ...state,
        errors: action.payload,
      };
    case actions.SET_TOTAL_LOS_LIST:
      return {
        ...state,
        totalLosList: action.payload,
      };
    case actions.SET_SELECTED_LOS_LIST:
      return {
        ...state,
      };
    case actions.SET_SEARCH_KEYWORD:
      return {
        ...state,
        searchKeyword: action.payload,
      };
    case actions.HIGHLIGHT_CARD_ID:
      return {
        ...state,
        highlightCardId: action.payload,
      };
    case actions.SET_SELECTED_FILTER_LIST:
      return {
        ...state,
        selectedFilterItems: action.payload,
      };
    default:
      return state;
  }
}
