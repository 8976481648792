import { Box, Spinner } from '@chakra-ui/react';

export const FullPageSpinner: React.FC = () => {
  return (
    <Box
      bgColor="gray.700"
      position="fixed"
      top="0"
      left="0"
      right="0"
      bottom="0"
      zIndex="9999"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Spinner
        thickness="4px"
        speed="0.65s"
        emptyColor="transparent"
        color="orange.100"
        size="xl"
      />
    </Box>
  );
};

export type FullPageSpinner = typeof FullPageSpinner;
