import { useMemo } from 'react';

export type UsePaginationOptions = {
  /** The current page number */
  currentPage?: number;
  /** The maximum number of pages to be displayed */
  maxRange?: number;
  /** The amount of pages */
  pageCount?: number;
};

export type PageObject = {
  type: 'page' | 'ellipsis';
  index?: number;
  number?: number;
  label: 'prev' | 'next' | `${number}`;
  isCurrent: boolean;
};

export function usePagination(options: UsePaginationOptions = {}): PageObject[] {
  const { currentPage = 1, maxRange = 5, pageCount = 0 } = options;

  const pageRange = useMemo(() => {
    const range: number[] = [];

    if (pageCount === 0) {
      return range;
    }

    const half = Math.ceil(maxRange / 2);

    const start = Math.max(0, Math.min(currentPage - half, pageCount - maxRange));
    const end = Math.min(pageCount - 1, start + maxRange - 1);

    for (let i = start; i <= end; i++) {
      range.push(i);
    }

    return range;
  }, [currentPage, pageCount, maxRange]);

  const pages = useMemo(() => {
    const pages: PageObject[] = [];

    if (pageCount === 0) {
      return pages;
    }

    if (pageRange[0] > 0) {
      pages.push({
        type: 'page',
        index: 0,
        number: 1,
        label: '1',
        isCurrent: false,
      });

      if (pageRange[0] > 1) {
        pages.push({
          index: 1,
          type: 'ellipsis',
          label: 'prev',
          isCurrent: false,
        });
      }
    }

    pages.push(
      ...pageRange.map(
        (index): PageObject => ({
          type: 'page',
          index,
          number: index + 1,
          label: `${index + 1}`,
          isCurrent: index === currentPage - 1,
        })
      )
    );

    if (pageRange[pageRange.length - 1] < pageCount - 1) {
      if (pageRange[pageRange.length - 1] < pageCount - 2) {
        pages.push({
          index: pageCount - 2,
          type: 'ellipsis',
          label: 'next',
          isCurrent: false,
        });
      }

      pages.push({
        type: 'page',
        index: pageCount - 1,
        number: pageCount,
        label: `${pageCount}`,
        isCurrent: false,
      });
    }

    return pages;
  }, [currentPage, pageCount, pageRange]);

  return pages;
}
