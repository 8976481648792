/**
 * DEPRECATED: Please consult with the FE Performance team before using this file.
 */
import { FILTER_DRIVER, FILTER_CUSTOMER } from '../actions/types';
import Helpers from '../helpers';

/**
 *  filter Status
 * 1 => all
 * 2 => active
 **/
const initialState = {
  filterDriverStatus: Helpers.DEFAULT_DRIVER_FILTER_STAT,
  filterCustomerStatus: Helpers.DEFAULT_DRIVER_FILTER_STAT,
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case FILTER_DRIVER:
      return {
        ...state,
        filterDriverStatus: payload,
      };
    case FILTER_CUSTOMER:
      return {
        ...state,
        filterCustomerStatus: payload,
      };
    default:
      return state;
  }
}
