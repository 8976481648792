export const datePickerWrapperStyle = {
  '& .datepicker-dark': {
    backgroundColor: 'gray.650',
    borderColor: 'var(--fp-colors-black-910)',
    transform: 'translate3d(0, 0, 0)',
    '& .react-datepicker ': {
      '&__header': {
        borderBottom: '1px solid var(--fp-colors-gray-420)',
        backgroundColor: 'gray.875',
        '& h2': {
          lineHeight: '24px',
        },
      },
      '&__navigation': {
        backgroundColor: ' transparent !important',
        '&--previous': {
          borderRightColor: 'var(--fp-colors-gray-120)',
          '&:hover': {
            borderRightColor: 'var(--fp-colors-gray-210)',
          },
        },
        '&--next': {
          borderLeftColor: 'var(--fp-colors-gray-120)',
          '&:hover': {
            borderLeftColor: 'var(--fp-colors-gray-210)',
          },
        },
      },
      '&__current-month,&-time__header,&-year-header ': {
        color: '#eee',
      },

      ' &__day-name': {
        color: '#eee',
      },
      ' &__day,&__time-name': {
        color: '#ddd',
        fontWeight: 'normal',
      },
      '&__day': {
        '&--today': {
          color: 'white',
          fontWeight: 'bold',
        },
        '&:hover, &--keyboard-selected': {
          backgroundColor: 'gray.875',
        },
        '&--selected': {
          color: 'white',
          fontWeight: 'bold',
          backgroundColor: 'blue.475',

          '&:hover': {
            backgroundColor: 'blue.960',
          },
        },
        '&--outside-month': {
          color: 'gray.115',
        },
        '&--disabled': {
          color: 'gray.120',
        },
      },
    },
  },
};
